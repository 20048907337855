import React, { memo } from 'react';

import UploadMedia from '../../../SidePropertiesContainer/components/UploadMedia/UploadMedia';
import ToggleSelector from '../../../SidePropertiesContainer/components/ToggleSelector/ToggleSelector';
import Action from '../../../SidePropertiesContainer/components/Action/Action';
import RichTextAction from '../../../SidePropertiesContainer/components/RichTextAction/RichTextAction';
import Icon from '../../../../../Icon/Icon';

import isActionAllowed from '../../../../../../helpers/isActionAllowed/isActionAllowed';

import {
  QUESTION_TYPES,
  QUESTION_DESIGNER_ACTION,
  SURVEY_BUILDER_POPUP_TYPES,
  DECISION_FRAMEWORK_TYPES
} from '../../../../../../helpers/constants';

import styles from './SideProperties.module.css';

const questionTypesWithCoverImage = [
  QUESTION_TYPES.MESSAGE,
  QUESTION_TYPES.OPEN_QUESTION,
  QUESTION_TYPES.MULTIPLE_CHOICE,
  QUESTION_TYPES.RATING_SCALE
];

export default memo(
  ({
    isActiveQuestion,
    setAddDraftEmoji,
    setToggleDraftButton,
    questionType,
    actions,
    indexInSet,
    setIndex,
    questionId,
    question,
    surveyLists,
    surveyStructure,
    isAdmin,
    isProfilingTagsManager,
    surveyLanguage,
    notAllowedTextContentEdit
  }) => {
    const onToggleDraftButton = style => {
      setToggleDraftButton(style);
    };

    const textActions = [
      {
        label: 'B',
        name: 'toggle-bold-text',
        type: 'message-text-style',
        style: 'BOLD',
        action: onToggleDraftButton
      },
      {
        label: 'I',
        name: 'toggle-italic-text',
        type: 'message-text-style',
        style: 'ITALIC',
        action: onToggleDraftButton
      },
      {
        label: 'U',
        name: 'toggle-underline-text',
        type: 'message-text-style',
        style: 'UNDERLINE',
        action: onToggleDraftButton
      },
      {
        label: 'E',
        name: 'toggle-underline-text',
        type: 'rich-text-emoji',
        emoji: '',
        action: e => setAddDraftEmoji(e)
      }
    ];

    const onDeleteClick = () => {
      const questionDesignerConstraintsPopup = localStorage.getItem(
        'qd_cpopup_delete_question_dontshow'
      );

      const confirmationPopup = {
        type: SURVEY_BUILDER_POPUP_TYPES.CONFIRMATION,
        onClick: () => {
          actions.deleteQuestion(questionId);
        },
        onClose: () => actions.toggleSurveyBuilderPopup(),
        onDontShowAnymoreChecked: () => {
          if (!questionDesignerConstraintsPopup) {
            localStorage.setItem('qd_cpopup_delete_question_dontshow', true);
          }
        },
        title: 'Please confirm',
        description: 'Are you sure you want to delete this question?',
        warning: null
      };

      if (!questionDesignerConstraintsPopup) {
        actions.toggleSurveyBuilderPopup(confirmationPopup);
      } else {
        actions.deleteQuestion(questionId);
      }
    };

    const onTaggingClick = () => {
      // Show popup to assign profiling question to the category
      const confirmationPopup = {
        type: SURVEY_BUILDER_POPUP_TYPES.PROFILE_QUESTION_CATEGORY,
        question,
        surveyLists,
        surveyLanguage,
        surveyStructure
        /*
        onClick: () => {},
        onClose: () => actions.toggleSurveyBuilderPopup(),
        onDontShowAnymoreChecked: () => {
          if (!questionDesignerConstraintsPopup) {
            localStorage.setItem('qd_cpopup_delete_group_dontshow', true);
          }
        },
        title: 'Please confirm',
        description:
          'Are you sure you want to delete this group? \nAll questions within this group will be removed.'
        */
      };

      actions.toggleSurveyBuilderPopup(confirmationPopup);
    };

    const onEditMatrixQuestionScaleClick = () => {
      // Show popup to edit custom scale for matrix questions
      const confirmationPopup = {
        type: SURVEY_BUILDER_POPUP_TYPES.MATRIX_QUESTION_EDIT_SCALE,
        question,
        surveyLanguage
      };

      actions.toggleSurveyBuilderPopup(confirmationPopup);
    };

    const surveyListQuestion = surveyLists.find(sL => sL.id === question.id);

    let randomizeLabel = 'Randomize answers order';
    if (
      questionType === QUESTION_TYPES.MATRIX &&
      question.matrix &&
      question.matrix.decisionFrameworkType
    ) {
      switch (question.matrix.decisionFrameworkType) {
        case DECISION_FRAMEWORK_TYPES.OPPORTUNITY_GRAPH:
          randomizeLabel = 'Randomize problems';
          break;
        case DECISION_FRAMEWORK_TYPES.IDEA_SELECTION_GRAPH:
          randomizeLabel = 'Randomize ideas';
          break;
        case DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH:
          randomizeLabel = 'Randomize concepts';
          break;
        case DECISION_FRAMEWORK_TYPES.CONCEPT_TEST_GRAPH:
          randomizeLabel = 'Randomize concepts';
          break;
        default:
          randomizeLabel = 'Randomize rows';
      }
    }

    return (
      <>
        <div className={styles.blockTitle}>General</div>
        <div className={styles.blockContent}>
          {questionTypesWithCoverImage.indexOf(questionType) > -1 && (
            <UploadMedia
              label="Question image"
              allowedFileTypes=".gif,.jpg,.jpeg,.png,.bmp"
              value={question.media}
              actions={actions}
            />
          )}
          {questionType === QUESTION_TYPES.PLAY_A_VIDEO && (
            <UploadMedia
              label="Upload a video"
              allowedFileTypes=".mp4"
              value={question.media}
              actions={actions}
            />
          )}
          {(questionType === QUESTION_TYPES.MULTIPLE_CHOICE ||
            questionType === QUESTION_TYPES.MATRIX) && [
            <ToggleSelector
              key="toogle-selector-mc-randomize"
              label={randomizeLabel}
              value={question.randomOrder}
              onChange={() =>
                actions.updateQuestionProperty(
                  'randomOrder',
                  !question.randomOrder
                )
              }
            />
          ]}
          {questionType === QUESTION_TYPES.RATING_SCALE && (
            <ToggleSelector
              label={'Enable "Non applicable'}
              value={question.allowSkip}
              onChange={() =>
                actions.updateQuestionProperty('allowSkip', !question.allowSkip)
              }
            />
          )}
          {questionType === QUESTION_TYPES.DISTRIBUTOR && (
            <ToggleSelector
              label="Distribute evenly"
              value={question.distributeEvenly}
              onChange={() =>
                actions.updateQuestionProperty(
                  'distributeEvenly',
                  !question.distributeEvenly
                )
              }
            />
          )}
          {isAdmin && questionType !== QUESTION_TYPES.DISTRIBUTOR ? (
            <ToggleSelector
              label="Only visible for admins"
              value={question.onlyVisibleForAdmins}
              onChange={() =>
                actions.updateQuestionProperty(
                  'onlyVisibleForAdmins',
                  !question.onlyVisibleForAdmins
                )
              }
            />
          ) : null}
          {questionType === QUESTION_TYPES.IFRAME && [
            <ToggleSelector
              label="Share additional info with third party (e.g.: respondent's age)"
              value={question.appendAdditionalDataToIframe}
              onChange={() =>
                actions.updateQuestionProperty(
                  'appendAdditionalDataToIframe',
                  !question.appendAdditionalDataToIframe
                )
              }
            />,
            <ToggleSelector
              label="Results have to be confirmed by third party in order to be counted"
              value={question.confirmResultWithApi}
              onChange={() =>
                actions.updateQuestionProperty(
                  'confirmResultWithApi',
                  !question.confirmResultWithApi
                )
              }
            />
          ]}
        </div>
        {!notAllowedTextContentEdit ? (
          <>
            <div className={styles.blockTitle}>Text</div>
            <div className={`${styles.blockContent} ${styles.textContainer}`}>
              {textActions.map((action, index) => (
                <RichTextAction
                  key={`active-block-properties-action-${index.toString()}`}
                  action={action}
                  isActiveQuestion={isActiveQuestion}
                />
              ))}
            </div>
          </>
        ) : null}
        <div className={styles.blockTitle}>Actions</div>
        <div className={styles.blockContent}>
          {isProfilingTagsManager &&
          questionType === QUESTION_TYPES.MULTIPLE_CHOICE &&
          !question.isCommunityAssignQuestion ? (
            <Action
              isActionAllowed={{ allowed: true }}
              onActionClick={() => onTaggingClick()}
              icon={<Icon type="tagging" className={styles.icon} />}
              label={
                question &&
                question.profiling &&
                question.profiling.isProfilingQuestion
                  ? 'View tags'
                  : 'Enable tagging'
              }
            />
          ) : null}
          {questionType === QUESTION_TYPES.MATRIX ? (
            <Action
              isActionAllowed={{ allowed: true }}
              onActionClick={() => onEditMatrixQuestionScaleClick()}
              icon={<Icon type="edit" className={styles.icon} />}
              label="Edit scale"
            />
          ) : null}
          <Action
            isActionAllowed={isActionAllowed(
              QUESTION_DESIGNER_ACTION.QUESTION_DUPLICATE,
              surveyListQuestion,
              null,
              surveyLists,
              surveyStructure
            )}
            isActionAllowedChecker={(destinationFlow, destinationLocation) =>
              isActionAllowed(
                QUESTION_DESIGNER_ACTION.QUESTION_DUPLICATE,
                surveyListQuestion,
                destinationFlow.id,
                surveyLists,
                surveyStructure,
                destinationLocation
              )
            }
            onActionClick={() => {}}
            icon={<Icon type="duplicate" className={styles.icon} />}
            propertiesComponent="BlockDestinationPosition"
            onDoneButtonPress={(location, locationQuestion) => {
              actions.duplicateQuestion(question, location, locationQuestion);
            }}
            label="Duplicate"
          />
          <Action
            isActionAllowed={isActionAllowed(
              QUESTION_DESIGNER_ACTION.QUESTION_MOVE,
              surveyListQuestion,
              null,
              surveyLists,
              surveyStructure
            )}
            isActionAllowedChecker={(destinationFlow, destinationLocation) =>
              isActionAllowed(
                QUESTION_DESIGNER_ACTION.QUESTION_MOVE,
                surveyListQuestion,
                destinationFlow.id,
                surveyLists,
                surveyStructure,
                destinationLocation
              )
            }
            onActionClick={() => {}}
            icon={<Icon type="move" className={styles.icon} />}
            propertiesComponent="BlockDestinationPosition"
            onDoneButtonPress={(
              location,
              locationQuestion,
              setBlockDestinationDropdown
            ) => {
              if (locationQuestion && locationQuestion !== questionId) {
                actions.moveQuestion(
                  null,
                  setIndex,
                  indexInSet,
                  null,
                  null,
                  location,
                  locationQuestion
                );
              }
              setBlockDestinationDropdown(null);
            }}
            label="Move To"
          />
          <Action
            isActionAllowed={isActionAllowed(
              QUESTION_DESIGNER_ACTION.QUESTION_DELETE,
              surveyListQuestion,
              null,
              surveyLists,
              surveyStructure,
              surveyStructure
            )}
            onActionClick={() => onDeleteClick()}
            icon={<Icon type="trash" className={styles.icon} />}
            label="Delete"
          />
        </div>
      </>
    );
  }
);
