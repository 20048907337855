import React from 'react';

import styles from './CommunityAutomationsRecordLoader.module.css';

export default ({ heigth = 60 }) => {
  const style = { height: `${heigth}px`, lineHeight: `${heigth}px` };

  return (
    <div className="loader-container">
      <div style={style} className={`item ${styles.placeholder}`} />
      <div style={style} className={`item ${styles.placeholder}`} />
      <div style={style} className={`item ${styles.placeholder}`} />
      <div style={style} className={`item ${styles.placeholder}`} />
      <div style={style} className={`item ${styles.placeholder}`} />
      <div style={style} className={`item ${styles.placeholder}`} />
    </div>
  );
};
