import { connect } from 'react-redux';
import * as actions from '../actions';
import { logout, setSelectedAccountManagementRecord } from '../../auth/actions';
import CampaignResultsPage from '../components/CampaignResults/CampaignResultsPage';

const deepFind = (obj, path) => {
  const paths = path.split('.');
  let current = obj;
  let i = null;

  for (i = 0; i < paths.length; i += 1) {
    if (current[paths[i]] === undefined) {
      return undefined;
    }
    if (current[paths[i]] !== undefined) {
      current = current[paths[i]];
    }
  }
  return current;
};

const calculateTimeTaken = (started, finished) => {
  const startedTime = new Date(started);
  const finishedTime = new Date(finished);
  const difference = finishedTime.getTime() - startedTime.getTime();
  const seconds = Math.floor(difference / 1000);
  return seconds;
};

const calculateHeaderStats = (
  totalResponses,
  responses,
  responseTarget,
  totalTimeTaken
) => ({
  totalResponses,
  responses,
  responseTarget,
  completion: ((responses / responseTarget) * 100).toFixed(2),
  averageCompletionTime: totalTimeTaken / responses
});

let checkboxBlocksProcessed = [];
/* eslint-disable */
const prepareResultStats = (resultStats, block, question, calculateStats) => {
  const stats = resultStats;

  const calculateAnswersCounter = storedAnswersCounter => {
    const answersCounter = {};

    if (
      question &&
      (question.type === 'Checkboxes' ||
        (question.type === 'Multiple Choice Question' &&
          question.selectAtMost &&
          question.selectAtMost > 1)) &&
      question.show_selected_answers_order
    ) {
      if (block.answers) {
        block.answers.forEach((blockAnswer, index) => {
          answersCounter[blockAnswer] = { [index]: 1 };
        });
      } else {
        answersCounter[block.answer] = { [0]: 1 };
      }

      Object.keys(answersCounter).map(answersCounterItem => {
        if (storedAnswersCounter && storedAnswersCounter[answersCounterItem]) {
          Object.keys(answersCounter[answersCounterItem]).map(
            answersChildCounterItem => {
              if (
                storedAnswersCounter &&
                storedAnswersCounter[answersCounterItem] &&
                storedAnswersCounter[answersCounterItem][
                  answersChildCounterItem
                ]
              ) {
                storedAnswersCounter[answersCounterItem][
                  answersChildCounterItem
                ] += 1;
              } else {
                storedAnswersCounter[answersCounterItem][
                  answersChildCounterItem
                ] = 1;
              }

              return storedAnswersCounter[answersCounterItem][
                answersChildCounterItem
              ];
            }
          );
        } else {
          if (storedAnswersCounter) {
            storedAnswersCounter[answersCounterItem] =
              answersCounter[answersCounterItem];
          } else {
            storedAnswersCounter = {
              [answersCounterItem]: answersCounter[answersCounterItem]
            };
          }
        }

        return answersCounterItem;
      });

      return storedAnswersCounter;
    }
    return null;
  };

  const calculateMatrixResultStats = (result, matrixAnswers) => {
    matrixAnswers.forEach(matrixAnswer => {
      matrixAnswer.choices.forEach(choice => {
        if (choice.answers && choice.answers.length) {
          const existingQuestionChoiceIndex = result.findIndex(
            value =>
              value.questionId === matrixAnswer.questionId &&
              value.choiceId === choice.choiceId
          );
          if (existingQuestionChoiceIndex > -1) {
            choice.answers.forEach(answer => {
              result[existingQuestionChoiceIndex].values = calculateResultStats(
                result[existingQuestionChoiceIndex].values,
                answer,
                true
              );
            });
          } else {
            const newQuestionChoice = {
              questionId: matrixAnswer.questionId,
              choiceId: choice.choiceId,
              values: [{ x: choice.answers[0], y: 1 }]
            };
            result.push(newQuestionChoice);
          }
        }
      });
    });
    return result;
  };

  const calculateResultStats = (result, value, checkboxes) => {
    const selectedTimeTakenResult = result.filter(
      resultTimeTaken => resultTimeTaken.x === value
    );
    const storedAnswersCounter =
      result && result.length ? result[0].answersCounter : null;

    let answersCounter = null;

    if (
      checkboxes &&
      checkboxBlocksProcessed.indexOf(block) === -1 &&
      calculateStats
    ) {
      answersCounter = calculateAnswersCounter(
        storedAnswersCounter,
        value,
        true
      );

      checkboxBlocksProcessed.push(block);
    }

    if (selectedTimeTakenResult.length) {
      selectedTimeTakenResult[0].y += 1;
      selectedTimeTakenResult.answersCounter = answersCounter;
    } else {
      const pushValue = { x: value, y: 1 };

      if (
        question &&
        (question.type === 'Checkboxes' ||
          (question.type === 'Multiple Choice Question' &&
            question.selectAtMost &&
            question.selectAtMost > 1)) &&
        question.show_selected_answers_order
      ) {
        // console.log("Yet i'm here");
        pushValue.answersCounter = answersCounter;
      }

      result.push(pushValue);
    }

    return result;
  };

  stats.timeTaken = calculateResultStats(
    resultStats.timeTaken,
    block.timeTaken
  );

  if (question.type === 'Slider') {
    stats.values = calculateResultStats(resultStats.values, block.answer);
  }

  if (question.type === 'Checkboxes') {
    block.answers.forEach(answer => {
      stats.values = calculateResultStats(resultStats.values, answer, true);
    });
  }

  if (
    question.type === 'Multiple Choice Question' &&
    block.answers &&
    block.answers.length
  ) {
    block.answers.forEach(answer => {
      stats.values = calculateResultStats(resultStats.values, answer, true);
    });
    return resultStats;
  }

  if (
    question.type === 'Multiple Choice Question' &&
    (!block.selectAtMost || block.selectAtMost === 1)
  ) {
    stats.values = calculateResultStats(resultStats.values, block.answer, true);
  }
  if (
    question.type === 'Matrix' &&
    block.matrixAnswers &&
    block.matrixAnswers.length
  ) {
    stats.values = calculateMatrixResultStats(
      resultStats.values,
      block.matrixAnswers
    );
  }

  return resultStats;
};
/* eslint-enable */

const calculateBlockResults = (
  resultBlocks,
  resultAnswer,
  resultField,
  calculateStats
) => {
  const calculateBlock = resultBlocks;
  const blockResults = resultAnswer.attributes.block_results;
  Object.values(blockResults).map(block => {
    const modifiedBlock = block;
    const timeTaken = calculateTimeTaken(block.started_at, block.finished_at);
    modifiedBlock.timeTaken = timeTaken;
    modifiedBlock.resultId = resultAnswer.attributes.id;
    modifiedBlock.rewardDetails = resultAnswer.attributes.rewardDetails;

    if (resultBlocks[block.block]) {
      resultBlocks[block.block][resultField].push(modifiedBlock);

      // Prepare result stats
      if (calculateStats) {
        calculateBlock[block.block].resultStats = prepareResultStats(
          resultBlocks[block.block].resultStats,
          modifiedBlock,
          resultBlocks[block.block],
          calculateStats
        );
      } else {
        calculateBlock[block.block].unfilteredResultStats = prepareResultStats(
          resultBlocks[block.block].unfilteredResultStats,
          modifiedBlock,
          resultBlocks[block.block],
          calculateStats
        );
      }
    }

    return modifiedBlock;
  });

  return resultBlocks;
};

const calculateDropOutResults = (block, state) => {
  const blockDropOutResults = [];
  if (state.campaigns.portal.campaignResults.dropOutResults) {
    if (state.campaigns.portal.campaignResults.dropOutResults) {
      state.campaigns.portal.campaignResults.dropOutResults.forEach(result => {
        if (result.attributes.block_results) {
          const blockResultLength = result.attributes.block_results.length;
          const checkLastFailedBlock =
            result.attributes.block_results[blockResultLength - 1];
          if (checkLastFailedBlock) {
            if (block.id === checkLastFailedBlock.block) {
              blockDropOutResults.push(checkLastFailedBlock);
            }
          }
        }
      });
    }
  }
  return blockDropOutResults;
};

const calculateSideBlockResults = (
  collectData,
  resultAnswer,
  collectedData
) => {
  collectData.forEach(data => {
    const collectStatsData = collectedData;
    const value = deepFind(resultAnswer, data.valuePath);

    if (!collectStatsData[data.name]) {
      collectStatsData[data.name] = {};
    }

    let valueGroupName = data.transformator ? data.transformator(value) : value;

    if (!valueGroupName) {
      valueGroupName = data.nullField ? data.nullField : value;
    }

    if (!collectStatsData[data.name][valueGroupName]) {
      collectStatsData[data.name][valueGroupName] = { value: 1 };
    } else {
      collectStatsData[data.name][valueGroupName] = {
        value: (collectStatsData[data.name][valueGroupName].value += 1)
      };
    }
  });

  return collectedData;
};

const matchActiveFilters = (activeFilters, resultAnswer, filtersRelation) => {
  let matched = 0;

  activeFilters.forEach(filter => {
    if (filter.resultsPath) {
      const results = deepFind(resultAnswer, filter.resultsPath);
      const singleAnswer = results.filter(
        result => result.block === filter.filterName
      );
      if (filter.transformator) {
        // MC question
        matched = filter.transformator(resultAnswer) ? (matched += 1) : matched;
      } else {
        matched =
          singleAnswer &&
          singleAnswer[0] &&
          deepFind(singleAnswer[0], filter.valuePath) === filter.value
            ? (matched += 1)
            : matched;
      }
    } else {
      let valueCheck = null;
      if (filter.transformator) {
        if (filter.valuePath) {
          valueCheck =
            filter.transformator(deepFind(resultAnswer, filter.valuePath)) ===
            filter.value;
        }
        if (!filter.valuePath) {
          valueCheck = filter.transformator(resultAnswer);
        }
      } else {
        valueCheck = deepFind(resultAnswer, filter.valuePath) === filter.value;
      }
      matched = valueCheck ? (matched += 1) : matched;
    }
  });
  // Filters don't match
  if (
    filtersRelation === 'and' &&
    activeFilters.length &&
    matched !== activeFilters.length
  ) {
    return null;
  }
  if (filtersRelation === 'or' && activeFilters.length && matched === 0) {
    return null;
  }

  return resultAnswer;
};

const filterResults = (
  activeFilters,
  resultAnswer,
  filtersRelation,
  nestedFilters
) => {
  // Apply nested filtering when there are multiple filters and global filter relation is AND
  if (filtersRelation === 'and' && nestedFilters && nestedFilters.length) {
    const subFilters = activeFilters.reduce((acc, curr) => {
      if (acc[curr.filterName]) {
        acc[curr.filterName] = [...acc[curr.filterName], curr];
      } else {
        acc[curr.filterName] = [curr];
      }
      return acc;
    }, {});

    if (Object.keys(subFilters).length > 1) {
      return Object.values(subFilters).reduce((resultA, subFilter) => {
        if (resultA) {
          return matchActiveFilters(subFilter, resultAnswer, 'or');
        }
        return resultA;
      }, true);
    }
  }

  return matchActiveFilters(activeFilters, resultAnswer, filtersRelation);
};

const calculateResponseTimes = responses => {
  const responseTimes = {};
  responses.forEach(response => {
    const time = response.attributes.createdAt.substring(0, 13);
    // const time = response.attributes.createdAt.substring(0, 16);
    if (time) {
      if (responseTimes[time]) {
        responseTimes[time] += 1;
      } else {
        responseTimes[time] = 1;
      }
    }
    return null;
  });

  const responseTimesChartData = Object.entries(responseTimes).map(data => ({
    x: data[0],
    y: data[1]
  }));
  return responseTimesChartData;
};

const prepareResultsForCharts = (
  collectData,
  outputResults,
  collectedData,
  filteredResults
) => {
  const collectOutputResults = outputResults;
  collectData.forEach(data => {
    collectOutputResults[data.name] = [];
    switch (data.type) {
      case 'gauge':
        Object.keys(collectedData[data.name]).forEach(name => {
          collectOutputResults[data.name].push({
            name,
            label: name,
            valueCount: collectedData[data.name][name].value,
            value: (
              collectedData[data.name][name].value / filteredResults.length
            ).toFixed(3),
            color: data.colors[name]
          });
        });
        break;
      case 'bar':
        Object.keys(collectedData[data.name]).forEach(name => {
          collectOutputResults[data.name].push({
            x: name,
            xLabel: name,
            y: collectedData[data.name][name].value
          });
        });
        break;
      default:
        return null;
    }
    return null;
  });
  return collectOutputResults;
};

export const migrateQuestionToBlock = q => {
  const typeNameMapping = {
    open_question: 'Open Question',
    mc_question: 'Multiple Choice Question',
    slider: 'Slider',
    camera: 'Camera',
    video_camera: 'Camera (Video)',
    video: 'Video',
    iframe: 'Showcase a website',
    message: 'Message',
    distributor: 'Distributor',
    matrix: 'Matrix'
  };

  const nameMapping = {
    open_question: 'short-text',
    mc_question: 'multiple-choice',
    slider: 'rating',
    camera: 'camera',
    video_camera: 'capture-video',
    video: 'show-video',
    iframe: 'showcase-website',
    message: 'message',
    distributor: 'distributor',
    matrix: 'matrix'
  };

  return {
    _id: q._id,
    id: q.id,
    media_filename: q.media,
    media_orig_filename: q.mediaFilename,
    media_url: q.mediaUrl,
    question: q.question,
    type: typeNameMapping[q.type],
    name: nameMapping[q.type],
    code: q.code,
    source: q.source,
    key: q.key,
    autocomplete: q.autocomplete,
    answers: q.choices
      ? q.choices.map(c => ({
          id: c.id,
          answer: c.answer,
          image_filename: c.image,
          image_orig_filename: c.imageFilename,
          lockedPosition: c.lockedPosition,
          end: c.end,
          nextFlow: c.nextFlow,
          decisionFrameworkType: c.decisionFrameworkType
        }))
      : [],
    allowOther: q.allowOther,
    allowNoneOfTheAbove: q.allowNoneOfTheAbove,
    allowSkip: q.allowSkip,
    selectAtLeast: q.selectAtLeast,
    selectAtMost: q.selectAtMost,
    selection_criteria_type: null,
    selection_criteria: null,
    rows: null,
    columns: null,
    each_row_required: null,
    limit_per_column: null,
    answer_min_characters: q.minCharacters,
    answer_max_characters: q.maxCharacters,
    minValue: q.minValue,
    maxValue: q.maxValue,
    skip_to_block: null,
    end: q.end,
    random: q.randomOrder,
    multiple: null,
    show_selected_answers_order: q.rankAnswers,
    ranked: q.rankAnswers,
    only_visible_for_admins: q.onlyVisibleForAdmins,
    distributeEvenly: q.distributeEvenly,
    isCommunityAssignQuestion: q.isCommunityAssignQuestion,
    matrix: q.matrix
  };
};

const mapStateToProps = state => {
  const {
    survey,
    activeSorting,
    activeFilteringOnSelection,
    activePresentationMode,
    activeChartView
  } = state.campaigns.portal.campaignResults;

  const { activeFilters } = state.campaigns.portal.campaignResults.filter;
  let resultBlocks = {};
  let outputResults = {};
  let unFilteredOutputResults = {};
  let headerStats = null;
  let collectedData = {};
  let unFilteredCollectedData = {};
  let responseTimes = null;

  checkboxBlocksProcessed = [];

  // Refactor this lists
  const collectData = [
    {
      name: 'genders',
      label: 'Gender',
      valuePath: 'attributes.userData.gender',
      type: 'gauge',
      values: ['Male', 'Female', 'Unknown'],
      nullField: 'Unknown',
      colors: {
        Male: '#5200F1',
        Female: '#A87FF8',
        Unknown: '#DEDEE3'
      }
    },
    {
      name: 'ageGroups',
      label: 'Age groups',
      valuePath: 'attributes.userData.age',
      type: 'bar',
      transformator: value => {
        if (value) {
          if (value < 18) {
            return 'Under 18';
          }
          if (value >= 18 && value < 25) {
            return '18-24';
          }
          if (value >= 25 && value < 35) {
            return '25-34';
          }
          if (value >= 35 && value < 45) {
            return '35-44';
          }
          if (value >= 45 && value < 55) {
            return '45-54';
          }
          if (value >= 55 && value < 66) {
            return '55-65';
          }
          if (value >= 66) {
            return '65+';
          }
        }

        return 'Unknown';
      },
      values: [
        'Unknown',
        'Under 18',
        '18-24',
        '25-34',
        '35-44',
        '45-54',
        '55-65',
        '65+'
      ]
    },
    {
      name: 'employementTypes',
      valuePath: 'attributes.userData.newOccupation.mainActivity',
      label: 'Employment',
      type: 'bar',
      transformator: value => {
        const occupationMapping = {
          WORKER: 'Worker',
          CLERK: 'Clerk',
          CIVIL_SERVANT: 'Civil servant',
          SELF_EMPLOYED_PERSON: 'Self employed person',
          HOUSEWIFE: 'Housewife',
          WITHOUT_OCCUPATION: 'Without occupation',
          RETIRED: 'Retired',
          LOOKING_FOR_JOB: 'Looking for a job',
          STUDENT: 'Student',
          OTHER: 'Other'
        };

        if (value && occupationMapping[value]) return occupationMapping[value];
        return 'Unknown';
      },
      values: [
        'Worker',
        'Clerk',
        'Civil servant',
        'Self employed person',
        'Housewife',
        'Without occupation',
        'Retired',
        'Looking for a job',
        'Student',
        'Other'
      ]
    },
    {
      name: 'languages',
      label: 'Language',
      valuePath: `attributes.userData.surveyLanguage`,
      type: 'gauge',
      values: ['Dutch', 'French', 'English', 'German'],
      nullField: 'Unknown',
      colors: {
        Dutch: '#5200F1',
        French: '#A87FF8',
        English: '#DEDEE3',
        German: '#000000'
      }
    }
  ];

  if (survey && survey.questions && survey.questions.length) {
    survey.questions.forEach(block => {
      if (block) {
        const resultCampaignBlock = migrateQuestionToBlock(block);

        resultCampaignBlock.results = [];
        resultCampaignBlock.unfilteredResults = [];
        resultCampaignBlock.resultStats = { timeTaken: [], values: [] };
        resultCampaignBlock.unfilteredResultStats = {
          timeTaken: [],
          values: []
        };
        if (
          resultCampaignBlock.type === 'Checkboxes' ||
          resultCampaignBlock.type === 'Multiple Choice Question'
        ) {
          resultCampaignBlock.dropOutResults = calculateDropOutResults(
            resultCampaignBlock,
            state
          );
        }
        resultBlocks[block.id] = resultCampaignBlock;
      }
    });
  }

  if (
    survey &&
    survey.questions &&
    survey.questions.length &&
    state.campaigns.portal.campaignResults.results
  ) {
    const { results } = state.campaigns.portal.campaignResults;

    // Prepare data collector
    collectData.forEach(data => {
      collectedData[data.name] = {};
      unFilteredCollectedData[data.name] = {};

      data.values.forEach(value => {
        collectedData[data.name][value] = { value: 0 };
        unFilteredCollectedData[data.name][value] = { value: 0 };
      });
    });

    const filtersRelation =
      state.campaigns.portal.campaignResults.filter.relation;
    const nestedActiveFilters =
      state.campaigns.portal.campaignResults.filter.nestedFilters;
    const filteredResults = [];
    const unFilteredResults = [];
    let totalTimeTaken = 0;
    const totalResponses = results.length;
    results.forEach(result => {
      const resultAnswer = result;

      // Add unfiltered (all) results
      resultBlocks = calculateBlockResults(
        resultBlocks,
        resultAnswer,
        'unfilteredResults',
        false
      );

      // Filter results
      if (
        !filterResults(
          activeFilters,
          resultAnswer,
          filtersRelation,
          nestedActiveFilters
        )
      )
        return null;

      // Calculate result time taken
      if (result.attributes.block_results.length) {
        resultAnswer.attributes.timeTaken = calculateTimeTaken(
          result.attributes.block_results[0].started_at,
          result.attributes.block_results[
            result.attributes.block_results.length - 1
          ].finished_at
        );
        totalTimeTaken += resultAnswer.attributes.timeTaken;
      }

      // Add answer to list
      filteredResults.push(resultAnswer);

      // Asign result to Question Blocks
      resultBlocks = calculateBlockResults(
        resultBlocks,
        resultAnswer,
        'results',
        true
      );
      // debugger;
      // Calculate data for side charts
      collectedData = calculateSideBlockResults(
        collectData,
        resultAnswer,
        collectedData
      );

      return resultAnswer;
    });

    results.forEach(result => {
      const unFilteredResultAnswer = result;
      unFilteredResults.push(unFilteredResultAnswer);
      unFilteredCollectedData = calculateSideBlockResults(
        collectData,
        unFilteredResultAnswer,
        unFilteredCollectedData
      );

      return unFilteredResultAnswer;
    });

    // Header stats
    headerStats = calculateHeaderStats(
      totalResponses,
      filteredResults.length,
      survey.maxResponses,
      totalTimeTaken
    );

    responseTimes = calculateResponseTimes(filteredResults);

    // Prepare results for chart components
    outputResults = prepareResultsForCharts(
      collectData,
      outputResults,
      collectedData,
      filteredResults
    );

    unFilteredOutputResults = prepareResultsForCharts(
      collectData,
      unFilteredOutputResults,
      unFilteredCollectedData,
      unFilteredResults
    );

    // Age groups in percentage
    if (outputResults && outputResults.ageGroups) {
      outputResults.totalAgeGroupsCount = outputResults.ageGroups.reduce(
        (acc, g) => acc + (g && g.y ? g.y : 0),
        0
      );

      outputResults.unfilteredTotalAgeGroupsCount = unFilteredOutputResults.ageGroups.reduce(
        (acc, g) => acc + (g && g.y ? g.y : 0),
        0
      );
    }
  }

  return {
    survey,
    activeFilters,
    activeSorting,
    activeFilteringOnSelection,
    activePresentationMode,
    activeChartView,
    headerStats,
    responseTimes,
    filtersRelation: state.campaigns.portal.campaignResults.filter.relation,
    nestedFilters: state.campaigns.portal.campaignResults.filter.nestedFilters,
    campaignOverallStats: state.campaigns.portal.campaignResults.stats,
    collectData,
    resultBlocks,
    results: outputResults,
    isAdmin: state.auth.isAdmin,
    isProcessing: state.campaigns.isProcessing,
    isFetching: state.campaigns.isFetching,
    isResultsPageLoading: state.campaigns.portal.campaignResults.loading,
    error: state.campaigns.error,
    isAuthenticated: state.auth.isAuthenticated,
    clientEmail: state.auth.clientEmail,
    isPremiumClient: state.auth.isPremiumClient,
    unfilteredResults: state.campaigns.portal.campaignResults.results,
    unFilteredSideResults: unFilteredOutputResults,
    isProfilingTagsManager: state.auth.isProfilingTagsManager,
    isBusinessConfigurationManager: state.auth.isBusinessConfigurationManager,
    accountManagement: state.auth.accountManagement
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoadResultsPage: (id, survey) =>
    dispatch(actions.loadResultsPage(id, ownProps.viewToken, survey)),
  onToggleSorting: (sortingName, sortingOrder) =>
    dispatch(actions.toggleSorting(sortingName, sortingOrder)),
  onToggleFilter: (
    filterName,
    valuePath,
    value,
    resultsPath,
    valueChecker,
    additionalAnswerLabel,
    updateExistingFilter
  ) =>
    dispatch(
      actions.toggleFilter(
        filterName,
        valuePath,
        value,
        resultsPath,
        valueChecker,
        additionalAnswerLabel,
        updateExistingFilter
      )
    ),
  onResetFilters: () => dispatch(actions.resetFilters()),
  onGenerateCSV: (id, questionIndices, viewToken, exportRemovedResponses) =>
    dispatch(
      actions.generateCSV(
        id,
        questionIndices,
        viewToken,
        exportRemovedResponses
      )
    ),
  onMergeDatasets: ids => dispatch(actions.mergeDatasets(ids)),
  onSetFiltersRelation: relation =>
    dispatch(actions.setFiltersRelation(relation)),
  onTimePrettyPrint: totalSeconds => {
    let secondsToCalculate = totalSeconds;
    const hours = Math.floor(secondsToCalculate / 3600).toFixed(0);
    secondsToCalculate %= 3600;
    const minutes = Math.floor(secondsToCalculate / 60).toFixed(0);
    const seconds = (secondsToCalculate % 60).toFixed(2);

    let outputTime = '';
    outputTime += hours !== '0' ? `${hours}h ` : '';
    outputTime += minutes !== '0' ? `${minutes}m ` : '';
    outputTime += `${seconds}s`;
    return outputTime;
  },
  onGoToPortalPage: (onSuccess, history) =>
    dispatch(actions.goToPortalPage(onSuccess, history)),
  onLogout: () => dispatch(logout()),
  onEditCampaign: campaign => {
    dispatch(actions.editCampaign(campaign));
    ownProps.history.push(`/campaign/${campaign.id}/edit`);
  },
  onShowResults: campaign => {
    dispatch(actions.showCampaignResults());
    ownProps.history.push(`/campaign/${campaign.id}/results`);
  },
  onAssignCategoresToOpenAnswer: (campaignId, categorization, viewToken) =>
    dispatch(
      actions.onAssignCategoresToOpenAnswer(
        campaignId,
        categorization,
        viewToken
      )
    ),
  setSelectedAccountManagementRecord: record => {
    dispatch(setSelectedAccountManagementRecord(record));
    window.location.href = '/clients';
  },
  onSetActiveFilters: activeFilters =>
    dispatch(actions.onSetActiveFilters(activeFilters)),
  setActiveSorting: activeSorting =>
    dispatch(actions.setActiveSorting(activeSorting)),
  setActiveFilteringOnSelection: activeFilteringOnSelection =>
    dispatch(actions.setActiveFilteringOnSelection(activeFilteringOnSelection)),
  setActivePresentationMode: activePresentationMode =>
    dispatch(actions.setActivePresentationMode(activePresentationMode)),
  setActiveChartView: activeChartView =>
    dispatch(actions.setActiveChartView(activeChartView)),
  onToggleActiveBooleanState: (fieldName, booleanName, booleanState) =>
    dispatch(
      actions.toggleActiveBooleanState(fieldName, booleanName, booleanState)
    ),
  removeNestedFilter: filterName =>
    dispatch(actions.removeNestedFilter(filterName))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    getViewAllGraphStats(
      feature,
      filteredResultIds,
      questionsMapping,
      useUnfilteredResultStats
    ) {
      const allResults = stateProps.unfilteredResults;

      const filteredResults = !useUnfilteredResultStats
        ? allResults.filter(r => filteredResultIds.indexOf(r.id) > -1)
        : allResults.filter(r => filteredResultIds.indexOf(r.id) === -1);

      const resultsField = !useUnfilteredResultStats
        ? 'results'
        : 'unfilteredResults';
      const resultStatsField = !useUnfilteredResultStats
        ? 'resultStats'
        : 'unfilteredResultStats';

      const cleanedQuestionsMapping = Object.values(questionsMapping).reduce(
        (acc, question) => ({
          ...acc,
          [question.id]: {
            ...question,
            [resultsField]: [],
            [resultStatsField]: {
              timeTaken: [],
              values: []
            }
          }
        }),
        {}
      );

      let resultBlocks = JSON.parse(JSON.stringify(cleanedQuestionsMapping));
      filteredResults.forEach(result => {
        resultBlocks = calculateBlockResults(
          resultBlocks,
          result,
          resultsField,
          !useUnfilteredResultStats
        );
      });

      return resultBlocks;
    }
  })
)(CampaignResultsPage);
