import gql from 'graphql-tag';

export const GET_BUSINESS_CONFIG_RECORDS = gql`
  query GetBusinessConfigRecords(
    $type: BusinessConfigurationType!
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
    $parent: ID
  ) {
    getBusinessConfigRecords(
      input: {
        type: $type
        start: $start
        end: $end
        search: $search
        parent: $parent
      }
    ) {
      totalCount
      records {
        id
        name
        type
        parent
      }
      errors {
        field
        message
      }
    }
  }
`;

export const CREATE_BUSINESS_CONFIG_RECORD = gql`
  mutation CreateBusinessConfigRecord(
    $name: String!
    $type: BusinessConfigurationType!
    $parent: ID
  ) {
    createBusinessConfigRecord(
      input: { name: $name, type: $type, parent: $parent }
    ) {
      businessConfig {
        id
      }
      done
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_BUSINESS_CONFIG_RECORD = gql`
  mutation UpdateBusinessConfigRecord(
    $id: ID!
    $name: String!
    $type: BusinessConfigurationType!
    $parent: ID
  ) {
    updateBusinessConfigRecord(
      input: { id: $id, name: $name, type: $type, parent: $parent }
    ) {
      done
      errors {
        field
        message
      }
    }
  }
`;

export const REMOVE_BUSINESS_CONFIG_RECORD = gql`
  mutation RemoveBusinessConfigRecord($id: ID!) {
    removeBusinessConfigRecord(input: { id: $id }) {
      done
      errors {
        field
        message
      }
    }
  }
`;
