export const QUESTION_TYPES = {
  MESSAGE: 'message',
  OPEN_QUESTION: 'open_question',
  MULTIPLE_CHOICE: 'mc_question',
  RANKING: 'ranking', // visible only in add questions list
  RATING_SCALE: 'slider',
  TAKE_A_PICTURE: 'camera',
  RECORD_A_VIDEO: 'video_camera',
  PLAY_A_VIDEO: 'video',
  IFRAME: 'iframe',
  DISTRIBUTOR: 'distributor',
  ADD_TO_COMMUNITY: 'add_to_community', // visible only in add questions list
  MATRIX: 'matrix'
};

export const QUESTION_DESIGNER_EVENTS = {
  UPDATE_ACTIVE_QUESTION: 'UPDATE_ACTIVE_QUESTION',
  REMOVE_QUESTION_SELECTION: 'REMOVE_QUESTION_SELECTION',
  SCROLL_TO_ACTIVE_QUESTION: 'SCROLL_TO_ACTIVE_QUESTION',
  SHOW_CONSTRAINTS_POPUP_DELETE_QUESTION:
    'SHOW_CONSTRAINTS_POPUP_DELETE_QUESTION',
  SHOW_CONSTRAINTS_POPUP_DELETE_GROUP: 'SHOW_CONSTRAINTS_POPUP_DELETE_GROUP'
};

export const QUOTA_UNITS = {
  PERCENTAGE: 'PERCENTAGE',
  ABSOLUTE: 'ABSOLUTE'
};

export const QUESTION_DESIGNER_ACTION = {
  QUESTION_MOVE: 'QUESTION_MOVE',
  QUESTION_DUPLICATE: 'QUESTION_DUPLICATE',
  QUESTION_DELETE: 'QUESTION_DELETE',
  QUESTION_ADD_TO_GROUP: 'QUESTION_GROUP',
  GROUP_MOVE: 'GROUP_MOVE',
  GROUP_DUPLICATE: 'GROUP_DUPLICATE',
  GROUP_DELETE: 'GROUP_DELETE',
  GROUP_RANDOMIZE: 'GROUP_RANDOMIZE',
  GROUP_UNGROUP: 'GROUP_UNGROUP',
  SET_QUESTION_JUMP: 'SET_QUESTION_JUMP',
  SET_GROUP_JUMP: 'SET_GROUP_JUMP',
  ADD_DISTRIBUTOR: 'ADD_DISTRIBUTOR',
  SET_DISTRIBUTOR_JUMP: 'SET_DISTRIBUTOR_JUMP',
  NEST_INTO_PARENT_GROUP: 'NEST_INTO_PARENT_GROUP',
  PROFILE_QUESTION_TAGGING: 'PROFILE_QUESTION_TAGGING'
};

export const LOCATION_TYPES = {
  BEFORE: 'before',
  AFTER: 'after'
};

export const SURVEY_END_TYPES = {
  SUCCESSFUL: 'Success',
  NOT_ELIGIBLE: 'Failure'
};

export const SURVEY_LANGUAGES = {
  ENGLISH: 'English',
  DUTCH: 'Dutch',
  FRENCH: 'French',
  GERMAN: 'German'
};

export const TEMPLATES_WIZARD_STEP_KEYS = {
  SELECT_START: 'SELECT_START',
  SELECT_LANGUAGE: 'SELECT_LANGUAGE',
  SELECT_TEMPLATE_TYPE: 'SELECT_TEMPLATE_TYPE',
  SET_CONTEXT: 'SET_CONTEXT',
  SELECT_TESTS_NUMBER: 'SELECT_TESTS_NUMBER',
  DEFINE_POINTS: 'DEFINE_POINTS',
  SET_SCALE: 'SET_SCALE',
  SET_SUCCESS_CRITERIA: 'SET_SUCCESS_CRITERIA',
  LOGIC_MAP: 'LOGIC_MAP'
};

export const TEMPLATES_WIZARD_START_OPTIONS = {
  SELECT_TEMPLATE: 'SELECT_TEMPLATE',
  START_SCRATCH: 'START_SCRATCH'
};

export const TEMPLATES_WIZARD_TEMPLATE_TYPES = {
  PROBLEM_VALIDATION: 'PROBLEM_VALIDATION',
  IDEA_SELECTION: 'IDEA_SELECTION',
  VALUE_SELECTION: 'VALUE_SELECTION',
  CONCEPT_TEST: 'CONCEPT_TEST'
};

export const TEMPLATES_SCALES = {
  RATING_SCALE: 'RATING_SCALE',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE'
};

export const MC_SELECTOR_ANSWERS = {
  I_STRONGLY_DISAGREE: 'I_STRONGLY_DISAGREE',
  I_DISAGREE: 'I_DISAGREE',
  I_AGREE: 'I_AGREE',
  I_STRONGLY_AGREE: 'I_STRONGLY_AGREE',
  I_NEITHER_AGREE_NOR_DISAGREE: 'I_NEITHER_AGREE_NOR_DISAGREE',
  I_SOMEWHAT_DISAGREE: 'I_SOMEWHAT_DISAGREE',
  I_SOMEWHAT_AGREE: 'I_SOMEWHAT_AGREE'
};

export const MC_QUESTION_OPTIONS = [
  {
    id: 1,
    values: [
      MC_SELECTOR_ANSWERS.I_STRONGLY_DISAGREE,
      MC_SELECTOR_ANSWERS.I_DISAGREE,
      MC_SELECTOR_ANSWERS.I_SOMEWHAT_DISAGREE,
      MC_SELECTOR_ANSWERS.I_NEITHER_AGREE_NOR_DISAGREE,
      MC_SELECTOR_ANSWERS.I_SOMEWHAT_AGREE,
      MC_SELECTOR_ANSWERS.I_AGREE,
      MC_SELECTOR_ANSWERS.I_STRONGLY_AGREE
    ]
  },
  {
    id: 2,
    values: [
      MC_SELECTOR_ANSWERS.I_STRONGLY_DISAGREE,
      MC_SELECTOR_ANSWERS.I_DISAGREE,
      MC_SELECTOR_ANSWERS.I_NEITHER_AGREE_NOR_DISAGREE,
      MC_SELECTOR_ANSWERS.I_AGREE,
      MC_SELECTOR_ANSWERS.I_STRONGLY_AGREE
    ]
  },
  {
    id: 3,
    values: [
      MC_SELECTOR_ANSWERS.I_STRONGLY_DISAGREE,
      MC_SELECTOR_ANSWERS.I_DISAGREE,
      MC_SELECTOR_ANSWERS.I_AGREE,
      MC_SELECTOR_ANSWERS.I_STRONGLY_AGREE
    ]
  }
];

export const DYNAMIC_FILTER_GROUPS = {
  AGE: 'AGE',
  GENDER: 'GENDER'
};

export const DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS = {
  RECOMMENDED: 'RECOMMENDED',
  STRICT: 'STRICT'
};

export const SURVEY_BUILDER_POPUP_TYPES = {
  CONFIRMATION: 'CONFIRMATION',
  PROFILE_QUESTION_CATEGORY: 'PROFILE_QUESTION_CATEGORY',
  RESEARCH_COST_INCREASED: 'RESEARCH_COST_INCREASED',
  MATRIX_OPTIONS_LIMIT_WARNING: 'MATRIX_OPTIONS_LIMIT_WARNING',
  MATRIX_QUESTION_EDIT_SCALE: 'MATRIX_QUESTION_EDIT_SCALE',

  EXTRA_QUESTIONS_ABOVE_LICENCE_ADDED: 'EXTRA_QUESTIONS_ABOVE_LICENCE_ADDED',
  NO_LICENCE_MORE_THAN_15: 'NO_LICENCE_MORE_THAN_15',
  SURVEY_UNPUBLISHED_QUESTIONS_ADDED: 'SURVEY_UNPUBLISHED_QUESTIONS_ADDED',
  SAMPLE_SIZE_CHARGE_INCREASED: 'SAMPLE_SIZE_CHARGE_INCREASED'
};

export const TARGET_AUDIENCE_STEPS = {
  SOCIO_DEMOGRAPHICS: 'SOCIO_DEMOGRAPHICS',
  PROFILING_QUESTIONS: 'PROFILING_QUESTIONS',
  NOT_ELIGIBLE_RESPONDENTS: 'NOT_ELIGIBLE_RESPONDENTS',
  SAMPLE_SIZE: 'SAMPLE_SIZE'
};

// Backend DECISION_FRAMEWORK_TYPES
export const DECISION_FRAMEWORK_TYPES = {
  VALUE_SELECTION_GRAPH: 'VALUE_SELECTION_GRAPH', // Concept exploration
  OPPORTUNITY_GRAPH: 'OPPORTUNITY_GRAPH', // Problem validation
  IDEA_SELECTION_GRAPH: 'IDEA_SELECTION_GRAPH', // Idea selection
  CONCEPT_TEST_GRAPH: 'CONCEPT_TEST_GRAPH', // Concept validation
  SINGLE: 'SINGLE', // For Matrix question
  CUSTOM_GRAPH: 'CUSTOM_GRAPH'
};

export const INCIDENCE_RATE_DROPOUT_VALUE_ESTIMATIONS = {
  LOW: 'LOW',
  MID: 'MID',
  HIGH: 'HIGH'
};

export const CONSULTANCY_VALIDATION_SUPPORT_TYPES = {
  NONE: 'NONE',
  MINI_SUPPORT: 'MINI_SUPPORT',
  LIGHT_SUPPORT: 'LIGHT_SUPPORT',
  FULL_SUPPORT: 'FULL_SUPPORT'
};

export const CONSULTANCY_VALIDATION_SUPPORT_MAPPING = {
  NONE: 'None',
  MINI_SUPPORT:
    'Mini validation support - quality check on survey questions (2h)',
  LIGHT_SUPPORT:
    'Light validation support - support with scoping, survey draft & programming',
  FULL_SUPPORT:
    'Full validation support - support with scoping, survey draft, programming & analysis with presentation & report'
};

export const CONSULTANCY_OTHER_ON_DEMAND_SERVICES_TYPES = {
  DECISION_EXPERT_SUPPORT: 'DECISION_EXPERT_SUPPORT',
  TRANSLATION_PROJECT_MANAGEMENT: 'TRANSLATION_PROJECT_MANAGEMENT',
  MULTI_COUNTRY_SETUP: 'MULTI_COUNTRY_SETUP',
  MULTI_COUNTRY_ANALYSIS: 'MULTI_COUNTRY_ANALYSIS'
};

export const CONSULTANCY_OTHER_ON_DEMAND_SERVICES_MAPPING = {
  DECISION_EXPERT_SUPPORT:
    'Decision expert support - support with scoping & translating your data into actionable decisions (bridge research & business)',
  TRANSLATION_PROJECT_MANAGEMENT:
    'Translation & project management - support with translating & setting up your survey',
  MULTI_COUNTRY_SETUP:
    'Multi country set-up - fixed fee for set-up in additional country',
  MULTI_COUNTRY_ANALYSIS:
    'Multi country analysis - fixed fee for analysis in additional country'
};

export const MATRIX_SCALE_TYPES = {
  DEFAULT: 'DEFAULT',
  IMPORTANT: 'IMPORTANT',
  CHALLENGING: 'CHALLENGING',
  VALUABLE: 'VALUABLE',
  UNIQUE: 'UNIQUE',
  BASIC: 'BASIC'
};

export const MATRIX_QUESTION_CUSTOM_SCALE_TYPES = {
  LIKERT_SCALE: 'LIKERT_SCALE',
  RATING_SCALE: 'RATING_SCALE'
};
