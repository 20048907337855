import React, { useState, useRef } from 'react';

import DropdownPopup from './components/DropdownPopup/DropdownPopup';
import blackArrowDown from '../../../../../../../../../../../../assets/img/black-arrow-down.svg';
import Icon from '../../../../../../../../../../Icon';

import styles from './Dropdown.module.css';

const Dropdown = props => {
  const {
    options,
    selectedOption,
    onSelectCompleted,
    placeholder,
    setFeaturesScrollingDisabled,
    leftDropdown,
    customStyle = {}
  } = props;

  const [showDropdownPopup, setShowDropdownPopup] = useState(false);
  const dropdownParentRef = useRef();

  const page = [];

  const selectedOptionIcon =
    options &&
    options.length &&
    selectedOption &&
    options.find(q => q.id === selectedOption.id)
      ? options.find(q => q.id === selectedOption.id).icon
      : null;

  const selectedOptionNumber =
    options &&
    options.length &&
    selectedOption &&
    options.find(q => q.id === selectedOption.id) &&
    (options.find(q => q.id === selectedOption.id).formattedGlobalIndex ||
      options.find(q => q.id === selectedOption.id).formattedGlobalIndex === 0)
      ? `${options.find(q => q.id === selectedOption.id).formattedGlobalIndex}.`
      : '-';

  page.push(
    <div
      key="population-label-dropdown-placeholder"
      className={styles.placeholderContainer}
      style={{ backgroundImage: `url(${blackArrowDown})` }}
      onClick={() => {
        setShowDropdownPopup(true);
        setFeaturesScrollingDisabled(true);
      }}
      role="presentation"
    >
      {selectedOption ? (
        <>
          <span className={styles.dropdownItemNumber}>
            {selectedOptionNumber}
          </span>
          <Icon
            type={selectedOptionIcon}
            style={{
              color: '#5200f1',
              marginLeft: '5px',
              marginRight: '5px',
              fontSize: '10px'
            }}
          />
        </>
      ) : null}
      <span>{selectedOption ? selectedOption.question : placeholder}</span>
    </div>
  );

  if (showDropdownPopup) {
    page.push(
      <DropdownPopup
        key="population-label-dropdown-placeholder-popup"
        options={options}
        onSelectCompleted={onSelectCompleted}
        hideDropdownPopup={() => {
          setShowDropdownPopup(false);
          setFeaturesScrollingDisabled(false);
        }}
        setFeaturesScrollingDisabled={setFeaturesScrollingDisabled}
        emptyListPlaceholder={`You need at least two multiple choice or rating scale\n questions to create a data point.`}
        dropdownParentRef={dropdownParentRef}
        marginLeft={leftDropdown ? `288px` : '-3px'}
      />
    );
  }

  return (
    <div
      className={styles.dropdownContainer}
      style={customStyle}
      ref={dropdownParentRef}
    >
      {page}
    </div>
  );
};

export default Dropdown;
