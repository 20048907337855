import React, { useState, useRef, useEffect } from 'react';

import styles from './MatrixQuestionEditScale.module.css';

import tickIcon from '../../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../../assets/img/delete.svg';

import AxisScaleFields from './components/QuestionChoiceTagging/AxisScaleFields';
import getMatrixScaleLabels from '../../../../../../../../../users/helpers/getMatrixScaleLabels';
import {
  DECISION_FRAMEWORK_TYPES,
  MATRIX_QUESTION_CUSTOM_SCALE_TYPES
} from '../../../../helpers/constants';

export default ({ actions, surveyBuilderPopup }) => {
  const [newSessionValues, setNewSessionValues] = useState({});

  const { question, surveyLanguage } = surveyBuilderPopup;

  const optionsContainerRef = useRef();

  useEffect(() => {
    // Set scale default values if there are no saved custom values
    if (question && question.matrix && question.matrix.decisionFrameworkType) {
      let newScale;
      if (
        question.matrix &&
        question.matrix.customScale &&
        question.matrix.customScale.type ===
          MATRIX_QUESTION_CUSTOM_SCALE_TYPES.LIKERT_SCALE
      ) {
        newScale = {
          xScaleLabels: question.matrix.customScale.xScaleLabels,
          yScaleLabels: question.matrix.customScale.yScaleLabels
        };
      } else {
        newScale = getMatrixScaleLabels(
          question.matrix.decisionFrameworkType,
          surveyLanguage
        );
      }

      setNewSessionValues(newScale);
    }
  }, []);

  const onConfirm = () => {
    const questionMatrix = { ...question.matrix };

    questionMatrix.customScale = {
      ...(question.customScale || {}),
      type: MATRIX_QUESTION_CUSTOM_SCALE_TYPES.LIKERT_SCALE,
      xScaleLabels: newSessionValues.xScaleLabels,
      yScaleLabels: newSessionValues.yScaleLabels
    };

    actions.updateQuestionProperty('matrix', questionMatrix);

    const questionToSetAsActiveFlow = {
      ...question,
      matrix: questionMatrix
    };

    actions.setActiveFlow(questionToSetAsActiveFlow);

    actions.toggleSurveyBuilderPopup(null);
  };

  const onAxisScaleFieldChangeValue = (
    scalePropertyName,
    fieldIndex,
    value
  ) => {
    const scaleToUpdate = [...newSessionValues[scalePropertyName]];
    scaleToUpdate[fieldIndex] = value;

    setNewSessionValues({
      ...newSessionValues,
      [scalePropertyName]: scaleToUpdate
    });
  };

  const isConfirmVisible = () => {
    if (
      newSessionValues &&
      newSessionValues.xScaleLabels &&
      newSessionValues.xScaleLabels.length &&
      newSessionValues.xScaleLabels.filter(l => l.trim().length > 0).length < 4
    ) {
      return false;
    }

    if (
      question &&
      question.matrix &&
      question.matrix.decisionFrameworkType !== DECISION_FRAMEWORK_TYPES.SINGLE
    ) {
      if (
        newSessionValues &&
        newSessionValues.yScaleLabels &&
        newSessionValues.yScaleLabels.length &&
        newSessionValues.yScaleLabels.filter(l => l.trim().length > 0).length <
          4
      ) {
        return false;
      }
    }

    return true;
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.top}>
          <div className={styles.title}>Edit scale</div>
        </div>
        <div
          ref={optionsContainerRef}
          className={styles.axisScaleFieldContainers}
        >
          <AxisScaleFields
            title="Scale x-axis"
            values={newSessionValues.xScaleLabels}
            onValueChange={(fieldIndex, value) =>
              onAxisScaleFieldChangeValue('xScaleLabels', fieldIndex, value)
            }
          />
          {question &&
          question.matrix &&
          question.matrix.decisionFrameworkType !==
            DECISION_FRAMEWORK_TYPES.SINGLE ? (
            <AxisScaleFields
              title="Scale y-axis"
              values={newSessionValues.yScaleLabels}
              onValueChange={(fieldIndex, value) =>
                onAxisScaleFieldChangeValue('yScaleLabels', fieldIndex, value)
              }
            />
          ) : null}
        </div>
      </div>
      <div className={styles.footer}>
        <div />
        <div className={styles.actions}>
          {isConfirmVisible() ? (
            <img
              className={`${
                styles.confirm
              } workspaces-manage-workspace-confirm-button`}
              src={tickIcon}
              alt="Confirm icon"
              onClick={() => onConfirm()}
              role="presentation"
            />
          ) : null}
          <img
            className={styles.close}
            src={closeIcon}
            alt="Close icon"
            onClick={() => actions.toggleSurveyBuilderPopup(null)}
            role="presentation"
          />
        </div>
      </div>
    </div>
  );
};
