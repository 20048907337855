import React from 'react';
import ReactPaginate from 'react-paginate';

import ConfigurationRecord from './components/ConfigurationRecord/ConfigurationRecord';
import CommunityAutomationsRecordLoader from '../../../../../../../CommunityAutomations/components/CommunityAutomationsListPage/components/CommunityAutomationsRecordLoader/CommunityAutomationsRecordLoader';

import { BUSINESS_CONFIGURATION_TYPES } from '../../../../../../helpers/constants';

import styles from './ConfigurationTable.module.css';

const ConfigurationTable = props => {
  const {
    editActions,
    removeActions,
    title,
    noResultsPlaceholder,
    recordsPerPage,
    records,
    subRecords,
    setSubRecords,
    onFetchSubRecords,
    totalCount,
    filtering,
    setFiltering,
    loading
  } = props;

  return (
    <div className={styles.container}>
      {loading ? (
        <table
          border="0"
          cellSpacing="0"
          cellPadding="0"
          className={styles.mainTable}
        >
          <thead>
            <tr className={styles.headerRow}>
              <th>{title}</th>
            </tr>
          </thead>
          <CommunityAutomationsRecordLoader heigth={35} />
        </table>
      ) : null}
      {!loading ? (
        <div>
          <table
            border="0"
            cellSpacing="0"
            cellPadding="0"
            className={styles.mainTable}
          >
            <thead>
              <tr className={styles.headerRow}>
                <th>{title}</th>
              </tr>
            </thead>
            {records && records.length ? (
              <tbody>
                {records.map((r, i) => (
                  <ConfigurationRecord
                    editActions={editActions}
                    removeActions={removeActions}
                    record={r}
                    key={`bussiness-config-record-${i.toString()}`}
                    isParentRecord={
                      r && r.type === BUSINESS_CONFIGURATION_TYPES.CATEGORY
                    }
                    subRecords={subRecords[r.id]}
                    onFetchSubRecords={async filteringVariables => {
                      const result = await onFetchSubRecords({
                        variables: {
                          ...filteringVariables,
                          type: BUSINESS_CONFIGURATION_TYPES.CHALLENGE,
                          parent: r.id
                        }
                      });

                      if (result && result.records) {
                        setSubRecords({
                          ...subRecords,
                          [r.id]: {
                            visible: true,
                            records: result.records
                          }
                        });
                      }
                    }}
                    toggleShowSubRecords={visible =>
                      setSubRecords({
                        ...subRecords,
                        [r.id]: { ...subRecords[r.id], visible }
                      })
                    }
                  />
                ))}
              </tbody>
            ) : null}
          </table>
          {!records || !records.length ? (
            <div className={styles.noResultsPlaceholder}>
              {noResultsPlaceholder}
            </div>
          ) : null}
        </div>
      ) : null}
      {recordsPerPage && records && records.length ? (
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={Math.ceil(totalCount / recordsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          initialPage={filtering.page}
          forcePage={filtering.page}
          disableInitialCallback
          onPageChange={pageNumber =>
            setFiltering({ ...filtering, page: pageNumber.selected })
          }
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default ConfigurationTable;
