import React, { useState, useEffect, useRef, Fragment } from 'react';

import styles from './EmojiPopupContainer.module.css';
import EmojiCategories from '../EmojiCategories/EmojiCategories';

const EmojiPopupContainer = ({
  hideDropdownPopup,
  marginLeft,
  emojisPickerDropdownRef,
  action
}) => {
  const [topPosition, setTopPosition] = useState(-1000); // hacky but works
  const wrapperRef = useRef(null);

  const handleClickOutside = event => {
    if (
      !(
        wrapperRef &&
        wrapperRef.current &&
        wrapperRef.current.contains(event.target)
      )
    ) {
      hideDropdownPopup();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(
    () => {
      const height = emojisPickerDropdownRef.current.offsetHeight;
      const rect = emojisPickerDropdownRef.current.getBoundingClientRect();
      const win = emojisPickerDropdownRef.current.ownerDocument.defaultView;

      setTopPosition(rect.top + win.pageYOffset - window.scrollY + height);
    },
    [emojisPickerDropdownRef]
  );

  return (
    <div
      ref={wrapperRef}
      className={styles.container}
      style={{ top: `${topPosition}px`, marginLeft }}
    >
      <div className={styles.emojiPickerContainer}>
        {EmojiCategories.map(category => (
          <Fragment key={category.title}>
            <span className={styles.emojiCategory}>{category.title}</span>
            {category.emojis.map(emoji => {
              if (emoji !== '️') {
                return (
                  <span
                    key={emoji}
                    role="presentation"
                    className={styles.emoji}
                    onMouseDown={e => {
                      e.preventDefault();
                      action.action(emoji);
                    }}
                  >
                    {emoji}
                  </span>
                );
              }
              return null;
            })}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default EmojiPopupContainer;
