import { BUSINESS_CONFIGURATION_TYPES } from '../../../../../helpers/constants';

export default ({ setActivePopup, onSubmit }) => ({
  label: 'Add company goal',
  setPopupAction: () => {
    setActivePopup({
      title: 'Add company goal',
      record: {
        name: '',
        type: BUSINESS_CONFIGURATION_TYPES.GOAL
      },
      fields: [
        {
          fieldName: 'name',
          label: null,
          type: 'input',
          placeholder: 'Type here'
        }
      ],
      validate: r => !!(r && r.name && r.name.length && r.name.trim().length),
      onSubmit
    });
  }
});
