export const templateTypes = {
  OPPORTUNITY_GRAPH: 'OPPORTUNITY_GRAPH',
  CUSTOM_GRAPH: 'CUSTOM_GRAPH',
  VALUE_SELECTION_GRAPH: 'VALUE_SELECTION_GRAPH',
  IDEA_SELECTION_GRAPH: 'IDEA_SELECTION_GRAPH',
  CONCEPT_TEST_GRAPH: 'CONCEPT_TEST_GRAPH'
};

export const templateConfigurations = {
  [templateTypes.OPPORTUNITY_GRAPH]: {
    xQuestionName: 'importanceQuestion',
    yQuestionName: 'satisfactionQuestion',
    placeholders: {
      description: `Describe your problem as you would like it displayed in the graph's legend`,
      xAxisDropdown: 'Select question (important)',
      yAxisDropdown: 'Select question (challenging)'
    },
    title: 'Problem validation',
    description:
      'The x-axis shows how important a problem is. The y-axis shows how challenging current solutions are.'
  },
  [templateTypes.CUSTOM_GRAPH]: {
    xQuestionName: 'xQuestion',
    yQuestionName: 'yQuestion',
    placeholders: {
      description: `Describe your data point as you would like it displayed in the graph's legend`,
      xAxisDropdown: 'Select value (x-axis)',
      yAxisDropdown: 'Select value (y-axis)'
    },
    title: 'Create custom graph',
    description: 'Name your x-axis and y-axis.'
  },
  [templateTypes.VALUE_SELECTION_GRAPH]: {
    xQuestionName: 'xQuestion',
    yQuestionName: 'yQuestion',
    placeholders: {
      description: `Describe your value as you would like it displayed in the graph's legend`,
      xAxisDropdown: 'Select question (value)',
      yAxisDropdown: 'Select question (basic requirement)'
    },
    title: 'Concept exploration',
    description:
      'The x-axis shows how valuable a value is. The y-axis shows which values are essential to include in your MVP or basic package.'
  },
  [templateTypes.IDEA_SELECTION_GRAPH]: {
    xQuestionName: 'xQuestion',
    yQuestionName: 'yQuestion',
    placeholders: {
      description: `Describe your idea as you would like it displayed in the graph's legend`,
      xAxisDropdown: 'Select question (value)',
      yAxisDropdown: 'Select question (uniqueness)'
    },
    title: 'Idea selection',
    description:
      'The x-axis shows how valuable an idea is. The y-axis shows how unique an idea is.'
  },
  [templateTypes.CONCEPT_TEST_GRAPH]: {
    xQuestionName: 'xQuestion',
    yQuestionName: 'yQuestion',
    placeholders: {
      description: `Describe your concept as you would like it displayed in the graph's legend`,
      xAxisDropdown: 'Select question (value)',
      yAxisDropdown: 'Select question (uniqueness)'
    },
    title: 'Concept validation',
    description:
      'The x-axis shows how valuable a concept is. The y-axis shows how unique a concept is.'
  }
};

export const LOVERS_HATERS_GRAPH_TYPES = {
  FILTER_ON_SPECIFIC_POINTS: 'FILTER_ON_SPECIFIC_POINTS',
  VIEW_ALL_DATA_POINTS: 'VIEW_ALL_DATA_POINTS'
};

export const LOVERS_HATERS_GRAPH_TYPES_DESCRIPTION = {
  [LOVERS_HATERS_GRAPH_TYPES.FILTER_ON_SPECIFIC_POINTS]:
    "Filter on a specific data point's lovers/haters",
  [LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS]:
    "View all data points' lovers / haters"
};

export const LOVERS_HATERS_FILTER_LABELS = {
  xLovers: 'Lovers x-axis (highest 25% scores)',
  yLovers: 'Lovers y-axis (highest 25% scores)',
  xLoversyLovers: 'Lovers x-axis and y-axis (highest 25% scores)',
  xHaters: 'Haters x-axis (lowest 25% scores)',
  yHaters: 'Haters y-axis (lowest 25% scores)',
  xHatersyHaters: 'Haters x-axis and y-axis (lowest 25% scores)'
};

export const LOVERS_HATERS_FILTER_DATAPOINT_LABELS = {
  xLovers: 'Lovers x-axis',
  yLovers: 'Lovers y-axis',
  xLoversyLovers: 'Lovers x-axis and y-axis',
  xHaters: 'Haters x-axis',
  yHaters: 'Haters y-axis',
  xHatersyHaters: 'Haters x-axis and y-axis'
};

export const defaultTemplateGraphName = 'Decision framework';

export const graphTypes = {
  ...templateTypes,
  MERGED_GRAPH: 'MERGED_GRAPH',
  HIDDEN_GRAPH: 'HIDDEN_GRAPH'
};
