import { graphTypes } from './constants';

export default (questions, activeInsightId, survey) => {
  const {
    hiddenGraphs,
    mergedGraphs,
    opportunityGraphs,
    customGraphs,
    conceptTestGraphs,
    valueSelectionGraphs,
    ideaSelectionGraphs
  } = survey;

  const graphsToReturn = [
    ...(hiddenGraphs && hiddenGraphs.length
      ? hiddenGraphs.map(g => ({ ...g, graphType: graphTypes.HIDDEN_GRAPH }))
      : []),
    ...(mergedGraphs && mergedGraphs.length
      ? mergedGraphs.map(g => ({ ...g, graphType: graphTypes.MERGED_GRAPH }))
      : []),
    ...(opportunityGraphs && opportunityGraphs.length
      ? opportunityGraphs.map(g => ({
          ...g,
          graphType: graphTypes.OPPORTUNITY_GRAPH
        }))
      : []),
    ...(customGraphs && customGraphs.length
      ? customGraphs.map(g => ({ ...g, graphType: graphTypes.CUSTOM_GRAPH }))
      : []),
    ...(conceptTestGraphs && conceptTestGraphs.length
      ? conceptTestGraphs.map(g => ({
          ...g,
          graphType: graphTypes.CONCEPT_TEST_GRAPH
        }))
      : []),
    ...(valueSelectionGraphs && valueSelectionGraphs.length
      ? valueSelectionGraphs.map(g => ({
          ...g,
          graphType: graphTypes.VALUE_SELECTION_GRAPH
        }))
      : []),
    ...(ideaSelectionGraphs && ideaSelectionGraphs.length
      ? ideaSelectionGraphs.map(g => ({
          ...g,
          graphType: graphTypes.IDEA_SELECTION_GRAPH
        }))
      : [])
  ];

  let templatesAndQuestions = [
    ...graphsToReturn,
    ...(questions && questions.length ? questions : [])
  ];

  // SORT AND FILTER templates and questions from insight data point questions
  if (activeInsightId) {
    const activeInsight =
      survey.insightsData &&
      survey.insightsData.insights &&
      survey.insightsData.insights.find(
        i => i._id.toString() === activeInsightId
      );

    if (
      activeInsight &&
      activeInsight.questions &&
      activeInsight.questions.length
    ) {
      templatesAndQuestions = activeInsight.questions.map(q => {
        const templateQuestion = templatesAndQuestions.find(
          tQ => tQ.id === q.questionId
        );

        return templateQuestion;
      });
    }
  }

  return templatesAndQuestions;
};
