import { DECISION_FRAMEWORK_TYPES } from '../../../helpers/constants';

export default {
  [DECISION_FRAMEWORK_TYPES.OPPORTUNITY_GRAPH]: {
    X_MATRIX_LABEL: 'important',
    Y_MATRIX_LABEL: 'challenging',
    X_MATRIX_QUESTION: 'How important do you find ...',
    Y_MATRIX_QUESTION: 'How difficult you find ...'
  },
  [DECISION_FRAMEWORK_TYPES.IDEA_SELECTION_GRAPH]: {
    X_MATRIX_LABEL: 'valuable',
    Y_MATRIX_LABEL: 'unique',
    X_MATRIX_QUESTION: 'How valuable do you find ...',
    Y_MATRIX_QUESTION: 'How unique do you find ...'
  },
  [DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH]: {
    X_MATRIX_LABEL: 'valuable',
    Y_MATRIX_LABEL: 'basic requirement',
    X_MATRIX_QUESTION: 'How valuable do you find ...',
    Y_MATRIX_QUESTION: 'How essential do you find ...'
  },
  [DECISION_FRAMEWORK_TYPES.CONCEPT_TEST_GRAPH]: {
    X_MATRIX_LABEL: 'valuable',
    Y_MATRIX_LABEL: 'unique',
    X_MATRIX_QUESTION: 'How valuable do you find ...',
    Y_MATRIX_QUESTION: 'How unique do you find ...'
  },
  [DECISION_FRAMEWORK_TYPES.CUSTOM_GRAPH]: {
    X_MATRIX_LABEL: '', // field empty for this type
    Y_MATRIX_LABEL: '', // field empty for this type
    X_MATRIX_QUESTION:
      'What do you think of this statement for following concepts: <b>"I think this is ..."</b>',
    Y_MATRIX_QUESTION:
      'What do you think of this statement for following concepts: <b>"I think this is ..."</b>'
  },
  [DECISION_FRAMEWORK_TYPES.SINGLE]: {
    X_MATRIX_LABEL: '', // field empty for this type
    Y_MATRIX_LABEL: '', // field not available for this type
    X_MATRIX_QUESTION:
      'What do you think of this statement for following concepts: <b>"I think this is ..."</b>',
    Y_MATRIX_QUESTION: '' // field not available for this type
  }
};
