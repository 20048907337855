import React, { useState, useEffect } from 'react';

import ImagePopup from '../Answers/components/ImagePopup/ImagePopup';

import zoomInIcon from '../../../../../../assets/img/zoom-in.svg';

import styles from './Matrix.module.css';
import getMatrixScaleLabels from '../../../../../../helpers/getMatrixScaleLabels';

const Matrix = props => {
  const {
    block,
    setValid,
    answers,
    matrixValues,
    setMatrixValues,
    matrixLastPage,
    valuesChanged,
    setValuesChanged,
    displayRichText,
    customThemeBaseColor,
    getMediaAltTag
  } = props;

  const [displayImagePopup, setDisplayImagePopup] = useState({
    visible: false,
    src: '',
    alt: ''
  });
  const [currentQuestionId, setCurrentQuestionId] = useState(
    !matrixLastPage && block && block.matrix && block.matrix.xQuestionId
      ? block.matrix.xQuestionId
      : null
  );
  const [answerOptions, setAnswerOptions] = useState([]);

  useEffect(() => {
    if (document && document.documentElement && customThemeBaseColor) {
      document.documentElement.style.setProperty(
        '--mc-question-answer-background-color',
        `${customThemeBaseColor}10`
      );
      document.documentElement.style.setProperty(
        '--mc-question-image-answer-background-color-active',
        `${customThemeBaseColor}90`
      );
    }
  }, []);

  useEffect(
    () => {
      if (
        block &&
        block.matrix &&
        block.matrix.xQuestionId &&
        block.matrix.yQuestionId
      ) {
        const scaleLabels = getMatrixScaleLabels(
          block.matrix.decisionFrameworkType,
          localStorage.getItem('campaignLanguage') || 'Dutch'
        );

        let answerOptionsToSet = null;

        if (matrixLastPage) {
          if (currentQuestionId !== block.matrix.yQuestionId) {
            setCurrentQuestionId(block.matrix.yQuestionId);
          }

          // Set labels for y question
          if (
            block.matrix.customScale &&
            block.matrix.customScale.yScaleLabels &&
            block.matrix.customScale.yScaleLabels.length
          ) {
            answerOptionsToSet = block.matrix.customScale.yScaleLabels;
          } else {
            answerOptionsToSet = scaleLabels.yScaleLabels;
          }

          setValid(allOptionsSelected(matrixValues, block.matrix.yQuestionId));
        } else {
          if (currentQuestionId !== block.matrix.xQuestionId) {
            setCurrentQuestionId(block.matrix.xQuestionId);
          }

          // Set labels for x question
          if (
            block.matrix.customScale &&
            block.matrix.customScale.xScaleLabels &&
            block.matrix.customScale.xScaleLabels.length
          ) {
            answerOptionsToSet = block.matrix.customScale.xScaleLabels;
          } else {
            answerOptionsToSet = scaleLabels.xScaleLabels;
          }

          setValid(allOptionsSelected(matrixValues, block.matrix.xQuestionId));
        }

        if (answerOptionsToSet) {
          setAnswerOptions(answerOptionsToSet);
        } else if (
          block.matrix.customScale &&
          block.matrix.customScale.xScaleLabels &&
          block.matrix.customScale.xScaleLabels.length
        ) {
          setAnswerOptions(block.matrix.customScale.xScaleLabels);
        } else {
          setAnswerOptions(scaleLabels.xScaleLabels);
        }
      }
    },
    [matrixLastPage, matrixValues, answers]
  );

  const getBackGroundColor = index => {
    const maxOpacity = 0.99;
    const opacity = Math.round(
      (maxOpacity / answerOptions.length) * (index + 1) * 100
    );

    return `${customThemeBaseColor || '#a7e0f5'}${opacity}`;
  };

  const isOptionSelected = (answerId, answerIndex) => {
    if (
      matrixValues &&
      matrixValues.length &&
      matrixValues.some(
        value =>
          value.questionId === currentQuestionId &&
          value.choices.some(
            choice =>
              choice.choiceId === answerId &&
              choice.answers.includes(answerIndex)
          )
      )
    ) {
      return true;
    }
    return false;
  };

  const allOptionsSelected = (newMatrixValues, questionId) =>
    newMatrixValues &&
    newMatrixValues.length &&
    newMatrixValues.some(
      questionMatrix =>
        questionMatrix &&
        questionMatrix.questionId === questionId &&
        questionMatrix.choices &&
        questionMatrix.choices.length &&
        answers &&
        answers.length &&
        answers.every(answer =>
          questionMatrix.choices.some(choice => choice.choiceId === answer.id)
        )
    );

  const findQuestion = (values, findIndex) => {
    if (findIndex) {
      return values.findIndex(value => value.questionId === currentQuestionId);
    }
    return values.find(value => value.questionId === currentQuestionId);
  };
  const findChoice = (answerId, choices) =>
    choices.find(choice => choice.choiceId === answerId);

  const onClickAnswerOption = (answerId, answerIndex) => {
    if (currentQuestionId && matrixValues) {
      let newCopyMatrixValues = matrixValues.map(mValue => ({
        ...mValue,
        choices: mValue.choices.map(choice => ({ ...choice }))
      }));

      const questionInValues = findQuestion(newCopyMatrixValues);

      if (
        newCopyMatrixValues &&
        newCopyMatrixValues.length &&
        questionInValues
      ) {
        // Question in state
        const choiceInValues = findChoice(answerId, questionInValues.choices);
        if (choiceInValues) {
          // Choice in state
          const newQuestionValues = { ...questionInValues };
          newQuestionValues.choices = newQuestionValues.choices.map(choice => {
            if (choice.choiceId === choiceInValues.choiceId) {
              return {
                ...choice,
                answers: [answerIndex]
              };
            }
            return choice;
          });
          const questionInValuesIndex = findQuestion(newCopyMatrixValues, true);
          newCopyMatrixValues.splice(
            questionInValuesIndex,
            1,
            newQuestionValues
          );
        } else {
          // Add choice to question choices in state
          const newQuestionValues = { ...questionInValues };
          newQuestionValues.choices = [
            ...newQuestionValues.choices,
            {
              choiceId: answerId,
              answers: [answerIndex]
            }
          ];
          const questionInValuesIndex = findQuestion(newCopyMatrixValues, true);
          newCopyMatrixValues.splice(
            questionInValuesIndex,
            1,
            newQuestionValues
          );
        }
      } else {
        // Add question with choice in state
        newCopyMatrixValues = [
          ...newCopyMatrixValues,
          {
            questionId: currentQuestionId,
            choices: [
              {
                choiceId: answerId,
                answers: [answerIndex]
              }
            ]
          }
        ];
      }
      setMatrixValues(newCopyMatrixValues);
      if (!valuesChanged) {
        setValuesChanged(true);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.gridContainer}>
        <div className={styles.row}>
          <div className={styles.questionChoice} />
          {answerOptions && answerOptions.length
            ? answerOptions.map((option, index) => (
                <div
                  className={styles.columnTitle}
                  key={option}
                  style={{
                    backgroundColor: getBackGroundColor(index),
                    width: `${100 / answerOptions.length}%`
                  }}
                >
                  <span>{option}</span>
                </div>
              ))
            : null}
        </div>
        {answers && answers.length
          ? answers.map(answer => (
              <div className={styles.row}>
                <div className={styles.questionChoice}>
                  {answer.imageUrl && (
                    <div
                      className={styles.imageContainer}
                      role="presentation"
                      onClick={() => {
                        setDisplayImagePopup({
                          visible: true,
                          src: answer.imageUrl,
                          alt: getMediaAltTag(answer.answer)
                        });
                      }}
                    >
                      <img
                        src={answer.imageUrl}
                        alt={getMediaAltTag(answer.answer)}
                        className={styles.answerImage}
                      />
                      <div className={styles.zoomInIconContainer}>
                        <img
                          src={zoomInIcon}
                          alt="Zoom in"
                          className={styles.zoomInIcon}
                        />
                      </div>
                    </div>
                  )}
                  {displayRichText(answer.answer, block.type)}
                </div>
                {answerOptions && answerOptions.length
                  ? answerOptions.map((_o, index) => (
                      <div
                        className={styles.answerOption}
                        style={{ width: `${50 / answerOptions.length}%` }}
                      >
                        <div
                          className={styles.selectorContainer}
                          role="presentation"
                          onClick={() => onClickAnswerOption(answer.id, index)}
                        >
                          <div
                            className={`${styles.selector} ${
                              isOptionSelected(answer.id, index)
                                ? styles.selected
                                : ''
                            }`}
                          />
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            ))
          : null}
      </div>

      <div className={styles.mobileContainer}>
        {answers && answers.length
          ? answers.map(answer => (
              <div className={styles.mobileQuestionChoice}>
                {answer.imageUrl && (
                  <div className={styles.imageContainer}>
                    <img
                      src={answer.imageUrl}
                      alt={getMediaAltTag(answer.answer)}
                      className={styles.answerImage}
                    />
                    <div
                      className={styles.zoomInIconContainer}
                      role="presentation"
                      onClick={() => {
                        setDisplayImagePopup({
                          visible: true,
                          src: answer.imageUrl,
                          alt: getMediaAltTag(answer.answer)
                        });
                      }}
                    >
                      <img
                        src={zoomInIcon}
                        alt="Zoom in"
                        className={styles.zoomInIcon}
                      />
                    </div>
                  </div>
                )}
                <div className={styles.answerText}>
                  {displayRichText(answer.answer, block.type)}
                </div>
                <div className={styles.answerOptionsContainer}>
                  <div className={styles.answerRange}>
                    <span>{answerOptions[0]}</span>
                    <span className={styles.line} />
                    <span>{answerOptions[answerOptions.length - 1]}</span>
                  </div>
                  <div className={styles.answerOptions}>
                    {answerOptions.map((_o, index) => (
                      <div
                        className={styles.answerOption}
                        style={{ width: `${50 / answerOptions.length}%` }}
                      >
                        <div
                          className={styles.selectorContainer}
                          role="presentation"
                          onClick={() => onClickAnswerOption(answer.id, index)}
                        >
                          <div
                            className={`${styles.selector} ${
                              isOptionSelected(answer.id, index)
                                ? styles.selected
                                : ''
                            }`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>

      {displayImagePopup.visible ? (
        <ImagePopup
          src={displayImagePopup.src}
          alt={displayImagePopup.alt}
          onClose={() =>
            setDisplayImagePopup({
              visible: false,
              src: '',
              alt: ''
            })
          }
        />
      ) : null}
    </div>
  );
};

export default Matrix;
