export default ({ setActivePopup, onSubmit }) => ({
  label: null, // button label
  setPopupAction: record => {
    setActivePopup({
      title: 'Update category',
      record,
      fields: [
        {
          fieldName: 'name',
          label: null,
          type: 'input',
          placeholder: 'Type here'
        }
      ],
      validate: r => !!(r && r.name && r.name.length && r.name.trim().length),
      onSubmit
    });
  }
});
