import { connect } from 'react-redux';
import PortalPage from '../components/PortalPage';
import { logout, setSelectedAccountManagementRecord } from '../../auth/actions';
import * as actions from '../actions';

const mapStateToProps = state => ({
  isAdmin: state.auth.isAdmin,
  isAuthenticated: state.auth.isAuthenticated,
  isProfilingTagsManager: state.auth.isProfilingTagsManager,
  isBusinessConfigurationManager: state.auth.isBusinessConfigurationManager,
  clientEmail: state.auth.clientEmail,
  accountManagement: state.auth.accountManagement
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(logout()),
  onSearchCampaigns: search => dispatch(actions.searchCampaigns(search)),
  setSelectedAccountManagementRecord: record => {
    dispatch(setSelectedAccountManagementRecord(record));
    window.location.href = '/clients';
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PortalPage);
