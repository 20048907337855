import { fetchJSON, fetchBlob, fetchRaw } from './helpers/fetch';
/* global localStorage, FormData */

const Api = baseUrl => {
  let appUrl = null;
  const backend = localStorage.getItem('backend');

  if (backend === 'local') {
    appUrl = 'http://localhost:8080';
  } else if (backend === 'test') {
    appUrl = 'https://test.buffl.be';
  } else if (backend === 'prod') {
    appUrl = 'https://buffl-prod-backend.azurewebsites.net';
  } else if (baseUrl) {
    appUrl = baseUrl;
  } else if (process.env.REACT_APP_ENV === 'qa') {
    appUrl = 'https://test.buffl.be';
  } else {
    appUrl = 'https://buffl-prod-backend.azurewebsites.net';
  }

  const apiUrl = url => `${appUrl}/api/clients/v2${url}`;
  const anonUrl = url => `${appUrl}/api/anon/v2${url}`;

  return {
    register: async (
      email,
      password,
      betaKey,
      company,
      telephone,
      firstname,
      lastname,
      howDidYouLearnAboutBuffl
    ) =>
      fetchJSON(
        apiUrl('/clients/register'),
        localStorage.getItem('token'),
        'POST',
        {
          email,
          password,
          betaKey,
          company,
          telephone,
          firstname,
          lastname,
          howDidYouLearnAboutBuffl
        }
      ),
    login: async (email, password, invitationToken) => {
      const input = { email, password };

      if (invitationToken) {
        input.invitationToken = invitationToken;
      }

      const result = await fetchJSON(
        apiUrl('/clients/login'),
        localStorage.getItem('token'),
        'POST',
        input
      );

      return {
        token: result.json.token,
        isAdmin: result.json.isAdmin,
        id: result.json.id,
        isPremiumClient: result.json.premium,
        isProfilingTagsManager: result.json.profilingTagsManager,
        isBusinessConfigurationManager: result.json.businessConfigurationManager
      };
    },
    getAuthenticatedClient: async beforeLogin => {
      // Selected account management record
      const accountManagementSelectedRecord = localStorage.getItem(
        'qd_am_selected_record'
      );

      let url = '/clients/get-client-details';
      if (
        accountManagementSelectedRecord &&
        accountManagementSelectedRecord !== ''
      ) {
        url = `${url}?amSelectedRecord=${accountManagementSelectedRecord}`;
      }

      const result = await fetchJSON(
        apiUrl(url),
        localStorage.getItem('token'),
        'GET',
        undefined,
        beforeLogin
      );

      if (!result) return null;

      return {
        admin: result.json.admin,
        id: result.json.id,
        email: result.json.email,
        profilingTagsManager: result.json.profilingTagsManager,
        businessConfigurationManager: result.json.businessConfigurationManager,
        accountManagementManagerRoles:
          result.json.accountManagementManagerRoles,
        accountManagementParents: result.json.accountManagementParents,
        starred_campaigns: result.json.starred_campaigns,
        permissions: result.json.permissions,
        multipleAccountManagementRecordsToSelect:
          result.json.multipleAccountManagementRecordsToSelect,
        selectedAccountManagementRecord:
          result.json.selectedAccountManagementRecord,
        defaultConstants: result.json.defaultConstants
      };
    },
    getAllClients: async (page, size, filter, sortColumn, sortType) => {
      const sort = `${sortType === 'desc' ? '-' : ''}${sortColumn},-id`;
      let clientsUrl =
        page || page === 0
          ? `/clients?page[number]=${page + 1}&page[size]=${size}&sort=${sort}`
          : '/clients';

      if (filter) {
        clientsUrl = `${clientsUrl}${filter}`;
      }

      const result = await fetchJSON(
        apiUrl(clientsUrl),
        localStorage.getItem('token')
      );

      return {
        clients: result.json.data.map(d => d.attributes),
        total: result.json.meta.total
      };
    },
    getCampaigns: async (
      selectedAMRecord,
      page,
      size,
      filter,
      sortColumn,
      sortType
    ) => {
      const sort = `${sortType === 'desc' ? '-' : ''}${sortColumn}`;

      let campaignsUrl = `/campaigns?page[number]=${page +
        1}&page[size]=${size}&sort=${sort}`;

      if (filter) {
        campaignsUrl = `${campaignsUrl}${filter}`;
      }

      campaignsUrl = `${campaignsUrl}&amSelectedRecord=${selectedAMRecord ||
        'All'}`;

      const result = await fetchJSON(
        apiUrl(campaignsUrl),
        localStorage.getItem('token')
      );

      return {
        campaigns: result.json.data.map(d =>
          Object.assign({}, d.attributes, {
            resultCount: result.json.meta.results[d.attributes.id]
          })
        ),
        total: result.json.meta.total
      };
    },
    getCampaign: async (campaignId, viewToken) => {
      let result;
      if (viewToken) {
        result = await fetchJSON(
          anonUrl(`/${viewToken}/campaigns/${campaignId}`)
        );
      } else {
        result = await fetchJSON(
          apiUrl(`/campaigns/${campaignId}`),
          localStorage.getItem('token')
        );
      }

      return result.json.data.attributes;
    },
    createCampaign: async amSelectedRecord => {
      const result = await fetchJSON(
        apiUrl('/campaigns'),
        localStorage.getItem('token'),
        'POST',
        {
          data: {
            attributes: { userFilter: { language: { $in: ['Dutch'] } } },
            amSelectedRecord
          }
        }
      );

      return result.json.data.attributes;
    },
    deleteCampaign: async id =>
      fetchJSON(
        apiUrl(`/campaigns/${id}`),
        localStorage.getItem('token'),
        'DELETE'
      ),
    publishCampaign: async id =>
      fetchJSON(
        apiUrl(`/campaigns/${id}/publish`),
        localStorage.getItem('token'),
        'POST',
        { publish: true }
      ),
    unpublishCampaign: async id =>
      fetchJSON(
        apiUrl(`/campaigns/${id}/publish`),
        localStorage.getItem('token'),
        'POST',
        { publish: false }
      ),
    payCampaign: async id =>
      fetchJSON(
        apiUrl(`/campaigns/${id}/pay`),
        localStorage.getItem('token'),
        'POST'
      ),
    getResults: async (campaignId, viewToken) => {
      let result;
      if (viewToken) {
        result = await fetchJSON(
          anonUrl(`/${viewToken}/campaigns/${campaignId}/results`)
        );
      } else {
        result = await fetchJSON(
          apiUrl(`/campaigns/${campaignId}/results`),
          localStorage.getItem('token')
        );
      }
      return result;
    },

    getDropOutResults: async (campaignId, viewToken) => {
      let result;
      if (viewToken) {
        result = await fetchJSON(
          anonUrl(`/${viewToken}/campaigns/${campaignId}/results/dropout`)
        );
      } else {
        result = await fetchJSON(
          apiUrl(`/campaigns/${campaignId}/results/dropout`),
          localStorage.getItem('token')
        );
      }
      return result;
    },

    getCampaignOverallStats: async campaignId =>
      fetchJSON(
        apiUrl(`/campaigns/${campaignId}/stats`),
        localStorage.getItem('token')
      ),

    generateResultsFile: async (
      campaignId,
      type,
      questionIndices,
      viewToken,
      exportRemovedResponses
    ) => {
      const customHeaders = {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      };
      const customBody = JSON.stringify({
        data: {
          questionIndices:
            questionIndices && questionIndices.length ? questionIndices : [],
          exportRemovedResponses
        }
      });
      let result;
      if (viewToken) {
        result = await fetchBlob(
          anonUrl(`/${viewToken}/campaigns/${campaignId}/results/${type}`),
          viewToken,
          'POST',
          customHeaders,
          customBody
        );
      } else {
        result = await fetchBlob(
          apiUrl(`/campaigns/${campaignId}/results/${type}`),
          localStorage.getItem('token'),
          'POST',
          customHeaders,
          customBody
        );
      }
      return result.blob;
    },

    mergeDatasets: async campaignIds => {
      const result = await fetchBlob(
        apiUrl(`/bulk/campaigns/results/csv?campaigns=${campaignIds}`),
        localStorage.getItem('token')
      );
      return result.blob;
    },

    uploadMedia: async file => {
      const data = new FormData();
      data.append('campaign-preview', file);

      const res = await fetchRaw(
        apiUrl('/campaigns/upload'),
        localStorage.getItem('token'),
        'POST',
        data
      );

      let result;
      try {
        result = await res.json();
      } catch (err) {
        throw new Error(`${res.status}: ${err.message}`);
      }

      return result;
    },

    assignCategoresToOpenAnswer: async (
      campaignId,
      categorization,
      viewToken
    ) => {
      if (viewToken) {
        return fetchJSON(
          anonUrl(
            `/${viewToken}/campaigns/${campaignId}/assign_categores_to_open_answer`
          ),
          viewToken,
          'POST',
          {
            data: {
              categorization
            }
          }
        );
      }
      return fetchJSON(
        apiUrl(`/campaigns/${campaignId}/assign_categores_to_open_answer`),
        localStorage.getItem('token'),
        'POST',
        {
          data: {
            categorization
          }
        }
      );
    },

    starCampaign: async (campaign, star) =>
      fetchJSON(
        apiUrl(`/campaigns/${campaign.id}/star`),
        localStorage.getItem('token'),
        'PATCH',
        {
          data: {
            star
          }
        }
      ),

    getAnalyticsData: async (campaign, input, viewToken) => {
      if (viewToken) {
        return fetchJSON(
          anonUrl(`/${viewToken}/campaigns/${campaign.id}/get_analytics_data`),
          viewToken,
          'POST',
          {
            data: {
              input
            }
          }
        );
      }
      return fetchJSON(
        apiUrl(`/campaigns/${campaign.id}/get_analytics_data`),
        localStorage.getItem('token'),
        'POST',
        {
          data: {
            input
          }
        }
      );
    },

    getAllUsers: async (page, size, search) => {
      let usersUrl = `/users?page[number]=${page + 1}&page[size]=${size}`;

      if (search) {
        usersUrl = `${usersUrl}&filter[email]=${search}`;
      }

      return fetchJSON(apiUrl(usersUrl), localStorage.getItem('token'));
    },

    sendPushNotification: async (pushNotificationProps, activeFilters) =>
      fetchJSON(
        apiUrl(`/push_notifications`),
        localStorage.getItem('token'),
        'POST',
        {
          data: {
            pushNotificationProps,
            activeFilters
          }
        }
      ),

    generateAccountManagementBudgetOverview: async (
      recordId,
      startDate,
      endDate
    ) => {
      const customHeaders = {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      };
      const customBody = JSON.stringify({
        data: {
          startDate,
          endDate
        }
      });
      const result = await fetchBlob(
        apiUrl(`/account_management_budget_overview/csv/${recordId}`),
        localStorage.getItem('token'),
        'POST',
        customHeaders,
        customBody
      );
      return result;
    },

    generateGlobalAccountManagementBudgetOverview: async (
      startDate,
      endDate
    ) => {
      const customHeaders = {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      };
      const customBody = JSON.stringify({
        data: {
          startDate,
          endDate
        }
      });
      const result = await fetchBlob(
        apiUrl(`/account_management_budget_overview/csv/global`),
        localStorage.getItem('token'),
        'POST',
        customHeaders,
        customBody
      );
      return result;
    },

    generateScopingSurveys: async (startDate, endDate) => {
      const customHeaders = {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      };
      const customBody = JSON.stringify({
        data: {
          startDate,
          endDate
        }
      });
      const result = await fetchBlob(
        apiUrl(`/surveys/scoping/csv/global`),
        localStorage.getItem('token'),
        'POST',
        customHeaders,
        customBody
      );
      return result;
    },

    exportCommunityUsers: async communities => {
      const customHeaders = {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      };
      const customBody = JSON.stringify({
        data: {
          communities
        }
      });
      const result = await fetchBlob(
        apiUrl(`/users/export_community_users/csv`),
        localStorage.getItem('token'),
        'POST',
        customHeaders,
        customBody
      );
      return result;
    },

    exportAcquisitionMetricStats: async (appliedFilters, timeframe) => {
      const customHeaders = {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      };
      const customBody = JSON.stringify({
        data: {
          appliedFilters,
          timeframe
        }
      });
      const result = await fetchBlob(
        apiUrl(`/acquisition_metric_stats/csv`),
        localStorage.getItem('token'),
        'POST',
        customHeaders,
        customBody
      );
      return result;
    }
  };
};

const ErrorHandler = history => apiFn => async (...args) => {
  try {
    return await apiFn(...args);
  } catch (err) {
    if (err.name === 'ApiError') {
      history.push('/clients/auth/login');
    }
    throw err;
  }
};

export default (baseUrl, history) => {
  const api = Api(baseUrl);
  const errorHandler = ErrorHandler(history);

  return {
    ...Object.keys(api).reduce(
      (endPointAcc, endPoint) => ({
        ...endPointAcc,
        [endPoint]: errorHandler(api[endPoint])
      }),
      {}
    )
  };
};
