/* eslint-disable no-useless-escape */
import { DECISION_FRAMEWORK_TYPES } from '../../../helpers/constants';

export default {
  [DECISION_FRAMEWORK_TYPES.OPPORTUNITY_GRAPH]: {
    X_MATRIX_LABEL: 'belangrijk',
    Y_MATRIX_LABEL: 'uitdagend',
    X_MATRIX_QUESTION: 'Hoe belangrijk vind jij ...',
    Y_MATRIX_QUESTION: 'Hoe moeilijk vind jij ...'
  },
  [DECISION_FRAMEWORK_TYPES.IDEA_SELECTION_GRAPH]: {
    X_MATRIX_LABEL: 'waardevol',
    Y_MATRIX_LABEL: 'uniek',
    X_MATRIX_QUESTION: 'Hoe waardevol vind jij ...',
    Y_MATRIX_QUESTION: 'Hoe uniek vind jij ...'
  },
  [DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH]: {
    X_MATRIX_LABEL: 'waardevol',
    Y_MATRIX_LABEL: 'een basisvereiste',
    X_MATRIX_QUESTION: 'Hoe waardevol vind jij ...',
    Y_MATRIX_QUESTION: 'Hoe essentieel vind jij ...'
  },
  [DECISION_FRAMEWORK_TYPES.CONCEPT_TEST_GRAPH]: {
    X_MATRIX_LABEL: 'waardevol',
    Y_MATRIX_LABEL: 'uniek',
    X_MATRIX_QUESTION: 'Hoe waardevol vind jij ...',
    Y_MATRIX_QUESTION: 'Hoe uniek vind jij ...'
  },
  [DECISION_FRAMEWORK_TYPES.CUSTOM_GRAPH]: {
    X_MATRIX_LABEL: '', // field empty for this type
    Y_MATRIX_LABEL: '', // field empty for this type
    X_MATRIX_QUESTION:
      'Wat vind je van volgende stelling: <b>"Ik vind dit ..."</b>',
    Y_MATRIX_QUESTION:
      'Wat vind je van volgende stelling: <b>"Ik vind dit ..."</b>'
  },
  [DECISION_FRAMEWORK_TYPES.SINGLE]: {
    X_MATRIX_LABEL: '', // field empty for this type
    Y_MATRIX_LABEL: '', // field not available for this type
    X_MATRIX_QUESTION:
      'Wat vind je van volgende stelling: <b>"Ik vind dit ..."</b>',
    Y_MATRIX_QUESTION: '' // field not available for this type
  }
};
