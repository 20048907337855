export default (
  graphId,
  surveyId,
  features,
  updateGraphMutation,
  viewToken,
  unfiltered
) => {
  if (graphId && surveyId && features && updateGraphMutation) {
    const updatedFeatures = features.map(iF => {
      const innerFeature = iF;

      delete innerFeature.__typename;

      if (innerFeature.featureIndex || innerFeature.featureIndex === 0) {
        delete innerFeature.featureIndex;
      }

      // For unfiltered features we only save ID and active state
      if (unfiltered) {
        return {
          ...(innerFeature.id ? { id: innerFeature.id } : {}),
          active: innerFeature.active
        };
      }

      return innerFeature;
    });

    // Prevent inconsistent data
    if (features.length !== updatedFeatures.length) {
      alert('Error!');
      return null;
    }

    updateGraphMutation({
      variables: {
        id: graphId,
        survey: surveyId,
        [unfiltered ? 'unFilteredFeatures' : 'features']: updatedFeatures,
        ...(viewToken ? { viewToken } : {})
      }
    });
  }

  return null;
};

export const updateGraphFilteredAndUnfilteredFeatures = (
  graphId,
  surveyId,
  features,
  unfilteredFeatures,
  updateGraphMutation,
  viewToken
) => {
  if (graphId && surveyId && features && updateGraphMutation) {
    const updatedFeatures = features.map(iF => {
      const innerFeature = iF;

      delete innerFeature.__typename;

      if (innerFeature.featureIndex || innerFeature.featureIndex === 0) {
        delete innerFeature.featureIndex;
      }

      return innerFeature;
    });

    const updatedUnfilteredFeatures = unfilteredFeatures.map(iF => {
      const innerFeature = iF;

      delete innerFeature.__typename;

      if (innerFeature.featureIndex || innerFeature.featureIndex === 0) {
        delete innerFeature.featureIndex;
      }

      // For unfiltered features we only save ID and active state
      return {
        ...(innerFeature.id ? { id: innerFeature.id } : {}),
        active: innerFeature.active
      };
    });

    // Prevent inconsistent data
    if (updatedFeatures.length !== updatedUnfilteredFeatures.length) {
      alert('Error!');
      return null;
    }

    updateGraphMutation({
      variables: {
        id: graphId,
        survey: surveyId,
        features: updatedFeatures,
        unFilteredFeatures: updatedUnfilteredFeatures,
        ...(viewToken ? { viewToken } : {})
      }
    });
  }

  return null;
};
