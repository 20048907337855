import React from 'react';

import styles from './DataPoint.module.css';
import InsightsTextarea from '../InsightsTextarea/InsightsTextarea';
import Dropdown from '../../../../../Blocks/Graphs/components/EditGraphsPopup/Templates/components/GraphFeatures/Feature/Dropdown/Dropdown';
import closeIcon from '../../../../../../../../assets/img/delete.svg';

export default ({
  question, // we also use data point term
  setQuestionData,
  dropdownOptions,
  setDataPointsScrollingDisabled,
  onRemoveDataPointClick,
  index
}) => {
  const onQuestionDropdownSelect = option => {
    const newSelectedQuestionData = {
      ...question,
      questionId: option.id,
      type: option.optionType
    };

    setQuestionData(newSelectedQuestionData);
  };

  const selectedOption =
    dropdownOptions && question && question.questionId
      ? dropdownOptions.find(q => q.id === question.questionId)
      : null;

  return (
    <div className={styles.container}>
      <div className={styles.background} />
      <div className={styles.dataPointContainer}>
        <Dropdown
          options={dropdownOptions}
          selectedOption={selectedOption}
          placeholder="Select question"
          onSelectCompleted={onQuestionDropdownSelect}
          questionsTypeLabel="what"
          setFeaturesScrollingDisabled={setDataPointsScrollingDisabled}
          leftDropdown
          customStyle={{ width: 'calc(100% + 5px)' }}
        />
        <div className={styles.fieldsContainer}>
          <InsightsTextarea
            internalData={question}
            setInternalData={setQuestionData}
            setDataPointsScrollingDisabled={setDataPointsScrollingDisabled}
          />
        </div>
        {index > 0 ? (
          <img
            className={styles.close}
            src={closeIcon}
            alt="Close icon"
            onClick={() => onRemoveDataPointClick(index)}
            role="presentation"
          />
        ) : null}
      </div>
    </div>
  );
};
