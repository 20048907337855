import enStrings from '../strings/en';
import nlStrings from '../strings/nl';
import frStrings from '../strings/fr';
import deStrings from '../strings/de';
import {
  DECISION_FRAMEWORK_TYPES,
  MATRIX_SCALE_TYPES
} from '../../clients/surveys/components/SurveyBuilderPage/SurveyBuilder/helpers/constants';

const getStrings = language => {
  if (language === 'NL' || language === 'Dutch') {
    return nlStrings;
  }
  if (language === 'FR' || language === 'French') {
    return frStrings;
  }
  if (language === 'EN' || language === 'English') {
    return enStrings;
  }
  if (language === 'DE' || language === 'German') {
    return deStrings;
  }
  return nlStrings;
};

const getScaleLanguageLabels = (scale, strings) => {
  if (scale === MATRIX_SCALE_TYPES.IMPORTANT) {
    return [
      strings.MATRIX_NOT_IMPORTANT_AT_ALL,
      strings.MATRIX_RATHER_NOT_IMPORTANT,
      strings.MATRIX_RATHER_IMPORTANT,
      strings.MATRIX_VERY_IMPORTANT
    ];
  }

  if (scale === MATRIX_SCALE_TYPES.CHALLENGING) {
    return [
      strings.MATRIX_NOT_DIFFICULT_AT_ALL,
      strings.MATRIX_RATHER_NOT_DIFFICULT,
      strings.MATRIX_RATHER_DIFFICULT,
      strings.MATRIX_VERY_DIFFICULT
    ];
  }

  if (scale === MATRIX_SCALE_TYPES.VALUABLE) {
    return [
      strings.MATRIX_NOT_VALUABLE_AT_ALL,
      strings.MATRIX_RATHER_NOT_VALUABLE,
      strings.MATRIX_RATHER_VALUABLE,
      strings.MATRIX_VERY_VALUABLE
    ];
  }

  if (scale === MATRIX_SCALE_TYPES.UNIQUE) {
    return [
      strings.MATRIX_NOT_UNIQUE_AT_ALL,
      strings.MATRIX_RATHER_NOT_UNIQUE,
      strings.MATRIX_RATHER_UNIQUE,
      strings.MATRIX_VERY_UNIQUE
    ];
  }

  if (scale === MATRIX_SCALE_TYPES.BASIC) {
    return [
      strings.MATRIX_CERTAINLY_NOT_ESSENTIAL,
      strings.MATRIX_RATHER_NOT_ESSENTIAL,
      strings.MATRIX_RATHER_ESSENTIAL,
      strings.MATRIX_CERTAINLY_ESSENTIAL
    ];
  }

  return [
    strings.MATRIX_I_STRONGLY_DISAGREE,
    strings.MATRIX_I_DISAGREE,
    strings.MATRIX_I_AGREE,
    strings.MATRIX_I_STRONGLY_AGREE
  ];
};

export default (type, language) => {
  const strings = getStrings(language);

  if (type === DECISION_FRAMEWORK_TYPES.OPPORTUNITY_GRAPH) {
    return {
      xScaleLabels: getScaleLanguageLabels(
        MATRIX_SCALE_TYPES.IMPORTANT,
        strings
      ),
      yScaleLabels: getScaleLanguageLabels(
        MATRIX_SCALE_TYPES.CHALLENGING,
        strings
      )
    };
  }

  if (type === DECISION_FRAMEWORK_TYPES.IDEA_SELECTION_GRAPH) {
    return {
      xScaleLabels: getScaleLanguageLabels(
        MATRIX_SCALE_TYPES.VALUABLE,
        strings
      ),
      yScaleLabels: getScaleLanguageLabels(MATRIX_SCALE_TYPES.UNIQUE, strings)
    };
  }

  if (type === DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH) {
    return {
      xScaleLabels: getScaleLanguageLabels(
        MATRIX_SCALE_TYPES.VALUABLE,
        strings
      ),
      yScaleLabels: getScaleLanguageLabels(MATRIX_SCALE_TYPES.BASIC, strings)
    };
  }

  if (type === DECISION_FRAMEWORK_TYPES.CONCEPT_TEST_GRAPH) {
    return {
      xScaleLabels: getScaleLanguageLabels(
        MATRIX_SCALE_TYPES.VALUABLE,
        strings
      ),
      yScaleLabels: getScaleLanguageLabels(MATRIX_SCALE_TYPES.UNIQUE, strings)
    };
  }

  return {
    xScaleLabels: getScaleLanguageLabels(MATRIX_SCALE_TYPES.DEFAULT, strings),
    yScaleLabels: getScaleLanguageLabels(MATRIX_SCALE_TYPES.DEFAULT, strings)
  };
};
