import React from 'react';

import styles from './AxisScaleFields.module.css';

const NUMBER_OF_AVAILABLE_FIELDS = 4;

export default ({ title, values, onValueChange }) => {
  if (!values) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.fieldsContainer}>
        {[...Array(NUMBER_OF_AVAILABLE_FIELDS).keys()].map(fieldIndex => (
          <div className={styles.fieldRow} key={fieldIndex}>
            <span className={styles.fieldRowNumber}>{fieldIndex + 1}</span>
            <input
              className={styles.field}
              value={values[fieldIndex]}
              onChange={e => onValueChange(fieldIndex, e.target.value)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
