import { parseRichText } from '../../../../../../../helpers/richTextHelpers';
import getMatrixScaleLabels from '../../../../../../../../../users/helpers/getMatrixScaleLabels';

const getPercentageValue = (value, total) => {
  let percentage = 0;
  if (value && total) {
    percentage = (value / total) * 100;
  }
  return percentage.toFixed(2);
};

export default (question, results, resultStats, surveyLanguage) => {
  let totalAnswers = 0;

  const scaleLabels = getMatrixScaleLabels(
    question &&
      question.matrixDetails &&
      question.matrixDetails.decisionFrameworkType
      ? question.matrixDetails.decisionFrameworkType
      : null,
    surveyLanguage || 'English'
  );

  const detailedAnswers = question.answers.map((answer, index, arr) => {
    const isOther = question.allowOther && index === arr.length - 1;
    const answerResponse = resultStats.values.filter(
      result => result.x === index
    );

    if (answerResponse.length) {
      let label = parseRichText(question.answers[answerResponse[0].x].answer);

      if (question.type === 'Matrix' && question.matrixDetails) {
        if (
          question.matrixDetails.customScale &&
          question.matrixDetails.customScale.xScaleLabels.length &&
          question.matrixDetails.customScale.xScaleLabels[answerResponse[0].x]
        ) {
          label =
            question.matrixDetails.customScale.xScaleLabels[
              answerResponse[0].x
            ];
        } else {
          label = scaleLabels.xScaleLabels[answerResponse[0].x];
        }
      }

      totalAnswers += answerResponse[0].y;
      return {
        x: answerResponse[0].x,
        label,
        y: answerResponse[0].y,
        image_filename: question.answers[answerResponse[0].x].image_filename,
        isOther
      };
    }

    let label = parseRichText(answer.answer);

    if (question.type === 'Matrix' && question.matrixDetails) {
      if (
        question.matrixDetails.customScale &&
        question.matrixDetails.customScale.xScaleLabels.length &&
        question.matrixDetails.customScale.xScaleLabels[index]
      ) {
        label = question.matrixDetails.customScale.xScaleLabels[index];
      } else {
        label = scaleLabels.xScaleLabels[index];
      }
    }

    return {
      x: index,
      label,
      y: 0,
      image_filename: answer.image_filename,
      isOther
    };
  });

  return detailedAnswers.map(answer => {
    const answerWithPercentage = answer;
    if (answerWithPercentage.dropOut === true) {
      answerWithPercentage.percentage = getPercentageValue(
        answer.y,
        results.length + question.dropOutResults.length
      );
      return answerWithPercentage;
    }
    if (
      question.type === 'Checkboxes' ||
      (question.type === 'Multiple Choice Question' &&
        question.selectAtMost &&
        question.selectAtMost > 1)
    ) {
      answerWithPercentage.percentage = getPercentageValue(
        answer.y,
        results.length
      );
    }
    if (
      (question.type === 'Multiple Choice Question' &&
        (!question.selectAtMost || question.selectAtMost === 1)) ||
      question.type === 'Matrix'
    ) {
      answerWithPercentage.percentage = getPercentageValue(
        answer.y,
        totalAnswers
      );
    }
    return answerWithPercentage;
  });
};
