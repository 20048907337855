export const COMMUNITY_AUTOMATION_TYPES = {
  EVENT: 'EVENT',
  ACTION: 'ACTION'
};

export const COMMUNITY_AUTOMATION_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const COMMUNITY_EVENT_STEP_NAMES = {
  NOT_OPEN_APP_IN_X_DAYS: 'NOT_OPEN_APP_IN_X_DAYS',
  USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS: 'USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS',
  USER_RECEIVES_BAD_ANSWER_FLAG: 'USER_RECEIVES_BAD_ANSWER_FLAG',
  BAD_ANSWER_FLAG_EXPIRES: 'BAD_ANSWER_FLAG_EXPIRES',
  USER_REPUTATION_DECREASES_TO_X: 'USER_REPUTATION_DECREASES_TO_X',
  USER_REPUTATION_INCREASES_TO_X: 'USER_REPUTATION_INCREASES_TO_X',
  USER_CREATED: 'USER_CREATED',
  USER_RESULT_REWARDED: 'USER_RESULT_REWARDED'
};

export const COMMUNITY_EVENT_STEP_NAMES_LABELS = {
  NOT_OPEN_APP_IN_X_DAYS: 'Not opened the app in x days',
  USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS:
    'User successfully completed x survey(s) in the last y week(s)',
  USER_RECEIVES_BAD_ANSWER_FLAG: 'User receives a bad answer flag',
  BAD_ANSWER_FLAG_EXPIRES: 'Bad flag expires',
  USER_REPUTATION_DECREASES_TO_X: 'User’ profile reputation decreases to x %',
  USER_REPUTATION_INCREASES_TO_X: 'User’ profile reputation increases to x %',
  USER_CREATED: 'User registered',
  USER_RESULT_REWARDED: 'User gave x good open answers'
};

export const COMMUNITY_ACTION_STEP_NAMES = {
  SEND_PUSH_NOTIFICATION: 'SEND_PUSH_NOTIFICATION',
  SEND_EMAIL: 'SEND_EMAIL',
  POPUP_MESSAGE: 'POPUP_MESSAGE',
  GIVE_TOKENS: 'GIVE_TOKENS',
  DECREASE_PROFILE_REPUTATION_WITH_X: 'DECREASE_PROFILE_REPUTATION_WITH_X',
  INCREASE_PROFILE_REPUTATION_WITH_X: 'INCREASE_PROFILE_REPUTATION_WITH_X'
};

export const COMMUNITY_ACTION_STEP_NAMES_LABELS = {
  SEND_PUSH_NOTIFICATION: 'Push notification',
  SEND_EMAIL: 'Send mail',
  POPUP_MESSAGE: 'In-app pop-up message',
  GIVE_TOKENS: 'Give x tokens',
  DECREASE_PROFILE_REPUTATION_WITH_X: 'Decrease profile reputation with x %',
  INCREASE_PROFILE_REPUTATION_WITH_X: 'Increase profile reputation with x %'
};

export const SEND_EMAIL_ACTION_TYPES = {
  USER: 'USER',
  OTHER_RECIPIENT: 'OTHER_RECIPIENT'
};

export const SEND_EMAIL_ACTION_OPTIONS = [
  {
    value: SEND_EMAIL_ACTION_TYPES.USER,
    label: 'User that triggered the event'
  },
  {
    value: SEND_EMAIL_ACTION_TYPES.OTHER_RECIPIENT,
    label: 'Other recipient'
  }
];
