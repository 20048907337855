import { CONFIGURATION_TYPE_NAMES } from '../../../../../helpers/constants';

export default ({ setActivePopup, onSubmit }) => ({
  label: null, // button label
  setPopupAction: record => {
    setActivePopup({
      title: `Delete ${CONFIGURATION_TYPE_NAMES[record.type]}`,
      description: `Are you sure you want to delete this ${
        CONFIGURATION_TYPE_NAMES[record.type]
      }?`,
      record,
      fields: [],
      validate: () => true,
      onSubmit
    });
  }
});
