import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import ConfigurationActions from './components/ConfigurationActions/ConfigurationActions';
import ConfigurationTable from './components/ConfigurationTable/ConfigurationTable';

import {
  TABLE_TYPES,
  BUSINESS_CONFIGURATION_TYPES,
  ACTION_TYPES,
  CONFIGURATION_TYPE_NAMES
} from '../../../../helpers/constants';

import addGoalAction from './actions/addGoalAction';
import removeRecordAction from './actions/removeRecordAction';

import {
  GET_BUSINESS_CONFIG_RECORDS,
  CREATE_BUSINESS_CONFIG_RECORD,
  UPDATE_BUSINESS_CONFIG_RECORD,
  REMOVE_BUSINESS_CONFIG_RECORD
} from '../../../../../../graphql/BusinessConfiguration';

import styles from './ConfigurationTableType.module.css';
import updateGoalAction from './actions/updateGoalAction';

const GoalsTable = props => {
  const { recordsPerPage, setActivePopup, errors, setErrors } = props;

  const [filtering, setFiltering] = useState({
    page: 0,
    search: ''
  });

  const recordVariables = {
    start: filtering.page * recordsPerPage,
    end: (filtering.page + 1) * recordsPerPage,
    search: filtering.search
  };

  const {
    data: { getBusinessConfigRecords: goals } = {},
    refetch,
    loading: getBussinConfigRecordsLoading,
    error: getRecordsError
  } = useQuery(GET_BUSINESS_CONFIG_RECORDS, {
    variables: {
      ...recordVariables,
      type: BUSINESS_CONFIGURATION_TYPES.GOAL
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  // Handle errors when fetching records
  const fetchFailedErrorMessage = 'Fetching company goals failed.';
  if (getRecordsError && !errors.some(e => e === fetchFailedErrorMessage)) {
    setErrors(prevErrors => [...prevErrors, fetchFailedErrorMessage]);
  }
  if (
    goals &&
    goals.errors &&
    goals.errors.length &&
    goals.errors[0].message &&
    !errors.some(e => e === goals.errors[0].message)
  ) {
    setErrors(prevErrors => [...prevErrors, goals.errors[0].message]);
  }

  const [
    createBusinessConfigRecord,
    { loading: createBusinessConfigRecordLoading }
  ] = useMutation(CREATE_BUSINESS_CONFIG_RECORD);

  const [
    updateBusinessConfigRecord,
    { loading: updateBusinessConfigRecordLoading }
  ] = useMutation(UPDATE_BUSINESS_CONFIG_RECORD);

  const [
    removeBusinessConfigRecord,
    { loading: removeBusinessConfigRecordLoading }
  ] = useMutation(REMOVE_BUSINESS_CONFIG_RECORD);

  const loading =
    getBussinConfigRecordsLoading ||
    createBusinessConfigRecordLoading ||
    updateBusinessConfigRecordLoading ||
    removeBusinessConfigRecordLoading;

  const onAddRecordSubmit = async r => {
    const data = await createBusinessConfigRecord({
      variables: r
    });

    const createRecord =
      data && data.data && data.data.createBusinessConfigRecord;

    if (
      createRecord &&
      createRecord.done &&
      createRecord.businessConfig &&
      createRecord.businessConfig.id
    ) {
      setActivePopup(null);
      refetch();
    } else if (createRecord && !createRecord.done) {
      return {
        error:
          createRecord.errors &&
          createRecord.errors.length &&
          createRecord.errors[0].message
            ? createRecord.errors[0].message
            : `Adding ${CONFIGURATION_TYPE_NAMES[r.type]} failed`
      };
    } else {
      setActivePopup(null);
    }

    return {
      done: true
    };
  };

  const onUpdateRecordSubmit = async r => {
    const recordToUpdate = r;
    if (recordToUpdate.__typename) {
      delete recordToUpdate.__typename;
    }

    const data = await updateBusinessConfigRecord({
      variables: recordToUpdate
    });

    const updateRecord =
      data && data.data && data.data.updateBusinessConfigRecord;

    if (updateRecord && updateRecord.done) {
      setActivePopup(null);
      refetch();
    } else if (updateRecord && !updateRecord.done) {
      return {
        error:
          updateRecord.errors &&
          updateRecord.errors.length &&
          updateRecord.errors[0].message
            ? updateRecord.errors[0].message
            : `Updating ${CONFIGURATION_TYPE_NAMES[r.type]} failed`
      };
    } else {
      setActivePopup(null);
    }

    return {
      done: true
    };
  };

  const onRemoveRecordSubmit = async r => {
    const data = await removeBusinessConfigRecord({
      variables: {
        id: r.id
      }
    });

    const removeRecord =
      data && data.data && data.data.removeBusinessConfigRecord;

    if (removeRecord && removeRecord.done) {
      setActivePopup(null);
      refetch();
    } else if (removeRecord && !removeRecord.done) {
      return {
        error:
          removeRecord.errors &&
          removeRecord.errors.length &&
          removeRecord.errors[0].message
            ? removeRecord.errors[0].message
            : `Removing ${CONFIGURATION_TYPE_NAMES[r.type]} failed`
      };
    } else {
      setActivePopup(null);
    }

    return {
      done: true
    };
  };

  const actions = {
    [ACTION_TYPES.ADD]: {
      [BUSINESS_CONFIGURATION_TYPES.GOAL]: addGoalAction({
        setActivePopup,
        onSubmit: onAddRecordSubmit
      })
    },
    [ACTION_TYPES.EDIT]: {
      [BUSINESS_CONFIGURATION_TYPES.GOAL]: updateGoalAction({
        setActivePopup,
        onSubmit: onUpdateRecordSubmit
      })
    },
    [ACTION_TYPES.DELETE]: {
      [BUSINESS_CONFIGURATION_TYPES.GOAL]: removeRecordAction({
        setActivePopup,
        onSubmit: onRemoveRecordSubmit,
        configType: BUSINESS_CONFIGURATION_TYPES.GOAL
      })
    }
  };

  return (
    <div className={styles.container}>
      <ConfigurationActions
        addActions={actions[ACTION_TYPES.ADD]}
        loading={loading}
      />
      <ConfigurationTable
        editActions={actions[ACTION_TYPES.EDIT]}
        removeActions={actions[ACTION_TYPES.DELETE]}
        title="Company goal"
        type={TABLE_TYPES.GOAL}
        noResultsPlaceholder="No company goals"
        setActivePopup={setActivePopup}
        recordsPerPage={recordsPerPage}
        records={
          goals && goals.records && goals.records.length ? goals.records : []
        }
        subRecords={[]}
        totalCount={(goals && goals.totalCount) || 0}
        filtering={filtering}
        setFiltering={setFiltering}
        loading={loading}
      />
    </div>
  );
};

export default GoalsTable;
