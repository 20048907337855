import { BUSINESS_CONFIGURATION_TYPES } from '../../../../../helpers/constants';

export default ({ setActivePopup, onSubmit, fetchRecords }) => ({
  label: 'Add business challenge',
  setPopupAction: () => {
    setActivePopup({
      title: 'Add business challenge',
      record: {
        name: '',
        type: BUSINESS_CONFIGURATION_TYPES.CHALLENGE
      },
      fields: [
        {
          fieldName: 'name',
          label: 'Name',
          type: 'input',
          placeholder: 'Type here'
        },
        {
          fieldName: 'parent',
          label: 'Category',
          type: 'dropdown',
          placeholder: 'Select category',
          fetchParentFunction: fetchRecords
        }
      ],
      validate: r =>
        !!(r && r.name && r.name.length && r.name.trim().length && r.parent),
      onSubmit
    });
  }
});
