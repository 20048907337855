import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import ConfigurationActions from './components/ConfigurationActions/ConfigurationActions';
import ConfigurationTable from './components/ConfigurationTable/ConfigurationTable';

import addCategoryAction from './actions/addCategoryAction';
import addChallengeAction from './actions/addChallengeAction';
import updateCategoryAction from './actions/updateCategoryAction';
import updateChallengeAction from './actions/updateChallengeAction';
import removeRecordAction from './actions/removeRecordAction';

import useLazyQuery from '../../../../../../surveys/hooks/useLazyQuery';

import {
  TABLE_TYPES,
  BUSINESS_CONFIGURATION_TYPES,
  ACTION_TYPES,
  CONFIGURATION_TYPE_NAMES
} from '../../../../helpers/constants';

import {
  GET_BUSINESS_CONFIG_RECORDS,
  CREATE_BUSINESS_CONFIG_RECORD,
  UPDATE_BUSINESS_CONFIG_RECORD,
  REMOVE_BUSINESS_CONFIG_RECORD
} from '../../../../../../graphql/BusinessConfiguration';

import styles from './ConfigurationTableType.module.css';

const ChallengesTable = props => {
  const { recordsPerPage, setActivePopup, errors, setErrors } = props;

  const [categoryFiltering, setCategoryFiltering] = useState({
    page: 0,
    search: ''
  });
  const [subRecords, setSubRecords] = useState({});

  const recordVariables = {
    start: categoryFiltering.page * recordsPerPage,
    end: (categoryFiltering.page + 1) * recordsPerPage,
    search: categoryFiltering.search
  };

  const {
    data: { getBusinessConfigRecords: categories } = {},
    refetch: categoryRefetch,
    loading: getBussinConfigRecordsLoading,
    error: getRecordsError
  } = useQuery(GET_BUSINESS_CONFIG_RECORDS, {
    variables: {
      ...recordVariables,
      type: BUSINESS_CONFIGURATION_TYPES.CATEGORY
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  // Handle errors when fetching records
  const fetchFailedErrorMessage = 'Fetching business challenges failed.';
  if (getRecordsError && !errors.some(e => e === fetchFailedErrorMessage)) {
    setErrors(prevErrors => [...prevErrors, fetchFailedErrorMessage]);
  }
  if (
    categories &&
    categories.errors &&
    categories.errors.length &&
    categories.errors[0].message &&
    !errors.some(e => e === categories.errors[0].message)
  ) {
    setErrors(prevErrors => [...prevErrors, categories.errors[0].message]);
  }

  const getConfigRecords = useLazyQuery(GET_BUSINESS_CONFIG_RECORDS, {
    fetchPolicy: 'no-cache'
  });

  const [
    createBusinessConfigRecord,
    { loading: createBusinessConfigRecordLoading }
  ] = useMutation(CREATE_BUSINESS_CONFIG_RECORD);

  const [
    updateBusinessConfigRecord,
    { loading: updateBusinessConfigRecordLoading }
  ] = useMutation(UPDATE_BUSINESS_CONFIG_RECORD);

  const [
    removeBusinessConfigRecord,
    { loading: removeBusinessConfigRecordLoading }
  ] = useMutation(REMOVE_BUSINESS_CONFIG_RECORD);

  const fetchRecords = async variables => {
    const result = await getConfigRecords(variables);

    if (
      result &&
      result.data &&
      result.data.getBusinessConfigRecords &&
      result.data.getBusinessConfigRecords.records
    ) {
      return { records: result.data.getBusinessConfigRecords.records };
    }

    return {
      error: `Failed to fetch records${
        variables && variables.variables && variables.variables.type
          ? `of type ${variables.variables.type}`
          : ''
      } `
    };
  };

  const loading =
    getBussinConfigRecordsLoading ||
    createBusinessConfigRecordLoading ||
    updateBusinessConfigRecordLoading ||
    removeBusinessConfigRecordLoading;

  const onAddRecordSubmit = async r => {
    if (
      r &&
      r.type === BUSINESS_CONFIGURATION_TYPES.CHALLENGE &&
      !(r.parent && r.parent.value)
    ) {
      // Show error inside popup
      return {
        error: 'No category for business challenge provided'
      };
    }

    const recordToCreate = {
      ...r,
      ...(r.parent && r.parent.value ? { parent: r.parent.value } : {}) // get value from dropdown label/value object
    };
    const data = await createBusinessConfigRecord({
      variables: recordToCreate
    });

    const createRecord =
      data && data.data && data.data.createBusinessConfigRecord;

    if (
      createRecord &&
      createRecord.done &&
      createRecord.businessConfig &&
      createRecord.businessConfig.id
    ) {
      setActivePopup(null);
      if (r.type === BUSINESS_CONFIGURATION_TYPES.CHALLENGE) {
        // Only add to subRecords if it exists (if subRecords data already fetched)
        if (subRecords && subRecords[r.parent.value]) {
          setSubRecords({
            ...subRecords,
            [r.parent.value]: {
              ...subRecords[r.parent.value],
              records: [
                { id: createRecord.businessConfig.id, ...recordToCreate },
                ...(subRecords[r.parent.value] &&
                subRecords[r.parent.value] &&
                subRecords[r.parent.value].records
                  ? subRecords[r.parent.value].records
                  : [])
              ]
            }
          });
        }
      } else {
        categoryRefetch();
      }
    } else if (createRecord && !createRecord.done) {
      // Show error inside popup
      return {
        error:
          createRecord.errors &&
          createRecord.errors.length &&
          createRecord.errors[0].message
            ? createRecord.errors[0].message
            : `Adding ${CONFIGURATION_TYPE_NAMES[r.type]} failed`
      };
    } else {
      setActivePopup(null);
    }

    return {
      done: true
    };
  };

  const onUpdateRecordSubmit = async (r, previousRecord) => {
    const recordToUpdate = {
      ...r,
      ...(r.parent && r.parent.value ? { parent: r.parent.value } : {}) // get value from dropdown label/value object
    };
    if (recordToUpdate.__typename) {
      delete recordToUpdate.__typename;
    }

    const data = await updateBusinessConfigRecord({
      variables: recordToUpdate
    });

    const updateRecord =
      data && data.data && data.data.updateBusinessConfigRecord;

    if (updateRecord && updateRecord.done) {
      setActivePopup(null);
      if (r.type === BUSINESS_CONFIGURATION_TYPES.CHALLENGE) {
        let newSubRecords = { ...subRecords };
        // Update subRecord in state
        if (subRecords && subRecords[r.parent.value]) {
          const existingRecords = subRecords[r.parent.value].records || [];

          const updatedRecords = existingRecords.some(
            subRecord => subRecord.id === r.id
          )
            ? existingRecords.map(subRecord =>
                subRecord.id === r.id
                  ? { ...subRecord, ...recordToUpdate }
                  : subRecord
              )
            : [...existingRecords, { ...recordToUpdate }]; // Parent of subRecord has changed -> add to new

          newSubRecords = {
            ...subRecords,
            [r.parent.value]: {
              ...subRecords[r.parent.value],
              records: updatedRecords
            }
          };
        }
        // Parent of subRecord has changed -> remove from old
        if (
          previousRecord &&
          previousRecord.parent &&
          previousRecord.parent.value &&
          previousRecord.parent.value !== r.parent.value &&
          newSubRecords &&
          newSubRecords[previousRecord.parent.value] &&
          newSubRecords[previousRecord.parent.value].records &&
          newSubRecords[previousRecord.parent.value].records.some(
            subRecord => subRecord.id === r.id
          )
        ) {
          newSubRecords = {
            ...newSubRecords,
            [previousRecord.parent.value]: {
              ...newSubRecords[previousRecord.parent.value],
              records: newSubRecords[
                previousRecord.parent.value
              ].records.filter(prevRecord => prevRecord.id !== r.id)
            }
          };
        }
        setSubRecords(newSubRecords);
      } else {
        categoryRefetch();
      }
    } else if (updateRecord && !updateRecord.done) {
      return {
        error:
          updateRecord.errors &&
          updateRecord.errors.length &&
          updateRecord.errors[0].message
            ? updateRecord.errors[0].message
            : `Updating ${CONFIGURATION_TYPE_NAMES[r.type]} failed`
      };
    } else {
      setActivePopup(null);
    }

    return {
      done: true
    };
  };

  const onRemoveRecordSubmit = async r => {
    const data = await removeBusinessConfigRecord({
      variables: {
        id: r.id
      }
    });

    const removeRecord =
      data && data.data && data.data.removeBusinessConfigRecord;

    if (removeRecord && removeRecord.done) {
      setActivePopup(null);
      if (r.type === BUSINESS_CONFIGURATION_TYPES.CHALLENGE) {
        if (subRecords && subRecords[r.parent]) {
          // Only add to subRecords if it exists (if subRecords data already fetched)
          setSubRecords({
            ...subRecords,
            [r.parent]: {
              ...subRecords[r.parent],
              records: subRecords[r.parent].records
                ? subRecords[r.parent].records.reduce(
                    (newRecords, subRecord) => {
                      if (subRecord.id !== r.id) {
                        return [...newRecords, subRecord];
                      }
                      return newRecords;
                    },
                    []
                  )
                : []
            }
          });
        }
      } else {
        categoryRefetch();
      }
    } else if (removeRecord && !removeRecord.done) {
      return {
        error:
          removeRecord.errors &&
          removeRecord.errors.length &&
          removeRecord.errors[0].message
            ? removeRecord.errors[0].message
            : `Removing ${CONFIGURATION_TYPE_NAMES[r.type]} failed`
      };
    } else {
      setActivePopup(null);
    }

    return {
      done: true
    };
  };

  const actions = {
    [ACTION_TYPES.ADD]: {
      [BUSINESS_CONFIGURATION_TYPES.CATEGORY]: addCategoryAction({
        setActivePopup,
        onSubmit: onAddRecordSubmit
      }),
      [BUSINESS_CONFIGURATION_TYPES.CHALLENGE]: addChallengeAction({
        setActivePopup,
        onSubmit: onAddRecordSubmit,
        fetchRecords
      })
    },
    [ACTION_TYPES.EDIT]: {
      [BUSINESS_CONFIGURATION_TYPES.CATEGORY]: updateCategoryAction({
        setActivePopup,
        onSubmit: onUpdateRecordSubmit
      }),
      [BUSINESS_CONFIGURATION_TYPES.CHALLENGE]: updateChallengeAction({
        setActivePopup,
        onSubmit: onUpdateRecordSubmit,
        fetchRecords
      })
    },
    [ACTION_TYPES.DELETE]: {
      [BUSINESS_CONFIGURATION_TYPES.CATEGORY]: removeRecordAction({
        setActivePopup,
        onSubmit: onRemoveRecordSubmit
      }),
      [BUSINESS_CONFIGURATION_TYPES.CHALLENGE]: removeRecordAction({
        setActivePopup,
        onSubmit: onRemoveRecordSubmit
      })
    }
  };

  return (
    <div className={styles.container}>
      <ConfigurationActions
        addActions={actions[ACTION_TYPES.ADD]}
        loading={loading}
      />
      <ConfigurationTable
        editActions={actions[ACTION_TYPES.EDIT]}
        removeActions={actions[ACTION_TYPES.DELETE]}
        title="Business challenges"
        type={TABLE_TYPES.CHALLENGE}
        noResultsPlaceholder="No categories"
        setActivePopup={setActivePopup}
        recordsPerPage={recordsPerPage}
        records={
          categories && categories.records && categories.records.length
            ? [...categories.records]
            : []
        }
        subRecords={subRecords}
        setSubRecords={setSubRecords}
        onFetchSubRecords={fetchRecords}
        totalCount={(categories && categories.totalCount) || 0}
        filtering={categoryFiltering}
        setFiltering={setCategoryFiltering}
        loading={loading}
      />
    </div>
  );
};

export default ChallengesTable;
