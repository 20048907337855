import React from 'react';

import Dropdown from '../../../../../../../../../../../../campaigns/components/Dropdown';

import styles from './ActionDropdown.module.css';

export default class ActionDropdown extends Dropdown {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteConfirmationDialog: false
    };
  }

  render() {
    return (
      <div
        ref={this.setWrapperRef}
        className={styles.popup}
        style={this.props.positionStyle}
      >
        <div
          className={styles.option}
          role="presentation"
          onClick={e => {
            e.stopPropagation();
            this.props.onEditClick();
          }}
        >
          Edit
        </div>
        <div
          role="presentation"
          className={styles.option}
          onClick={e => {
            e.stopPropagation();
            this.props.onDeleteClick();
          }}
        >
          Delete
        </div>
      </div>
    );
  }
}
