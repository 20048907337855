export default ({ setActivePopup, onSubmit, fetchRecords }) => ({
  label: null, // button label
  setPopupAction: (record, parentRecord) => {
    setActivePopup({
      title: 'Update business challenge',
      record: {
        ...record,
        parent: {
          label: parentRecord.name,
          value: parentRecord.id
        }
      },
      fields: [
        {
          fieldName: 'name',
          label: 'Name',
          type: 'input',
          placeholder: 'Type here'
        },
        {
          fieldName: 'parent',
          label: 'Category',
          type: 'dropdown',
          placeholder: 'Select category',
          fetchParentFunction: fetchRecords
        }
      ],
      validate: r =>
        !!(r && r.name && r.name.length && r.name.trim().length && r.parent),
      onSubmit
    });
  }
});
