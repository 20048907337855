import React from 'react';
import styles from './CommunityAutomationPropertesSingleStep.module.css';
import {
  COMMUNITY_ACTION_STEP_NAMES,
  COMMUNITY_ACTION_STEP_NAMES_LABELS,
  COMMUNITY_EVENT_STEP_NAMES,
  COMMUNITY_EVENT_STEP_NAMES_LABELS,
  SEND_EMAIL_ACTION_TYPES,
  SEND_EMAIL_ACTION_OPTIONS
} from '../../../../../../helpers/constants';
import DropdownSelector from '../../../../../../../CommunicationCenter/DropdownSelector/DropdownSelector';
import useLazyQuery from '../../../../../../../../surveys/hooks/useLazyQuery';
import { GET_EMAIL_NOTIFICATION_TEMPLATES } from '../../../../../../../../graphql/PushNotifications';

export default ({
  selectedStep,
  selectedCommunityAutomationStepIndex,
  communityAutomation,
  setCommunityAutomation
}) => {
  const onChangeProperty = (property, value) => {
    const newSteps = communityAutomation.steps;
    newSteps[selectedCommunityAutomationStepIndex][property] = value;

    setCommunityAutomation({
      ...communityAutomation,
      steps: newSteps
    });
  };

  const onChangePercentageProperty = (e, property) => {
    let newValue = e.target.value;
    if (newValue) {
      newValue = parseInt(e.target.value, 10);
    }
    if (!newValue || (newValue >= 0 && newValue <= 100)) {
      onChangeProperty(property, parseInt(e.target.value, 10));
    }
  };

  const getEmailNotificationTemplates = useLazyQuery(
    GET_EMAIL_NOTIFICATION_TEMPLATES,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const fetchTemplates = async () => {
    const templates = await getEmailNotificationTemplates({
      variables: {
        mappingName: 'AUTOMATION'
      }
    });

    if (
      templates &&
      templates.data &&
      templates.data.getEmailNotificationTemplates &&
      templates.data.getEmailNotificationTemplates.data &&
      templates.data.getEmailNotificationTemplates.data.length
    ) {
      return templates.data.getEmailNotificationTemplates.data.map(
        template => ({
          value: template.id,
          label: template.name.replace('[AUTOMATION] ', '')
        })
      );
    }

    return null;
  };

  const maxPushTitleLength = 39;
  const maxPopupTitleLength = 25;
  const maxPopupDescriptionLength = 250;
  const maxPopupButtonLength = 20;

  return (
    <div className={styles.container}>
      {selectedStep &&
      selectedStep.stepName ===
        COMMUNITY_EVENT_STEP_NAMES.NOT_OPEN_APP_IN_X_DAYS ? (
        <>
          <div className={styles.header}>
            <div className={styles.title}>Add event</div>
            <div className={styles.description}>
              {COMMUNITY_EVENT_STEP_NAMES_LABELS.NOT_OPEN_APP_IN_X_DAYS}
            </div>
            <div className={styles.subDescription}>
              Resets each time user opens the app
            </div>

            <div className={styles.fieldTitle}>Number of days</div>
            <div className={styles.fieldContainer}>
              <input
                className={`${styles.inputField} ${styles.amount1NarrowField}`}
                type="number"
                value={selectedStep.amount1 || ''}
                onKeyDown={evt =>
                  evt.key === 'ArrowDown' ||
                  evt.key === 'ArrowUp' ||
                  evt.key === 'e' ||
                  evt.key === 'E' ||
                  evt.key === '.' ||
                  evt.key === '-'
                    ? evt.preventDefault()
                    : null
                }
                onChange={e =>
                  onChangeProperty('amount1', parseInt(e.target.value, 10))
                }
                onWheel={e => e.target.blur()}
              />
            </div>
          </div>
        </>
      ) : null}

      {selectedStep &&
      selectedStep.stepName ===
        COMMUNITY_EVENT_STEP_NAMES.USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS ? (
        <>
          <div className={styles.header}>
            <div className={styles.title}>Add event</div>
            <div className={styles.description}>
              {
                COMMUNITY_EVENT_STEP_NAMES_LABELS.USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS
              }
            </div>
            <div className={styles.subDescription}>
              Resets every y week(s) after it has been triggered
            </div>

            <div className={styles.fieldTitle}>Number of surveys</div>
            <div className={styles.fieldContainer}>
              <input
                className={`${styles.inputField} ${styles.amount1NarrowField}`}
                type="number"
                value={selectedStep.amount1 || ''}
                onKeyDown={evt =>
                  evt.key === 'ArrowDown' ||
                  evt.key === 'ArrowUp' ||
                  evt.key === 'e' ||
                  evt.key === 'E' ||
                  evt.key === '.' ||
                  evt.key === '-'
                    ? evt.preventDefault()
                    : null
                }
                onChange={e =>
                  onChangeProperty('amount1', parseInt(e.target.value, 10))
                }
                onWheel={e => e.target.blur()}
              />
            </div>

            <div className={styles.fieldTitle}>Number of weeks</div>
            <div className={styles.fieldContainer}>
              <input
                className={`${styles.inputField} ${styles.amount1NarrowField}`}
                type="number"
                value={selectedStep.amount2 || ''}
                onKeyDown={evt =>
                  evt.key === 'ArrowDown' ||
                  evt.key === 'ArrowUp' ||
                  evt.key === 'e' ||
                  evt.key === 'E' ||
                  evt.key === '.' ||
                  evt.key === '-'
                    ? evt.preventDefault()
                    : null
                }
                onChange={e =>
                  onChangeProperty('amount2', parseInt(e.target.value, 10))
                }
                onWheel={e => e.target.blur()}
              />
            </div>
          </div>
        </>
      ) : null}

      {selectedStep &&
      selectedStep.stepName ===
        COMMUNITY_EVENT_STEP_NAMES.USER_RECEIVES_BAD_ANSWER_FLAG ? (
        <>
          <div className={styles.header}>
            <div className={styles.title}>Add event</div>
            <div className={styles.description}>
              {COMMUNITY_EVENT_STEP_NAMES_LABELS.USER_RECEIVES_BAD_ANSWER_FLAG}
            </div>
            <div className={styles.fieldTitle}>Flag should expire after</div>
            <div className={styles.fieldContainer}>
              <input
                className={`${styles.inputField} ${styles.amount1NarrowField} ${
                  styles.inputFieldWithLabel
                }`}
                type="number"
                value={selectedStep.amount1 || ''}
                onKeyDown={evt =>
                  evt.key === 'ArrowDown' ||
                  evt.key === 'ArrowUp' ||
                  evt.key === 'e' ||
                  evt.key === 'E' ||
                  evt.key === '.' ||
                  evt.key === '-'
                    ? evt.preventDefault()
                    : null
                }
                onChange={e =>
                  onChangeProperty('amount1', parseInt(e.target.value, 10))
                }
                onWheel={e => e.target.blur()}
              />
              <span className={styles.inputFieldLabel}>months</span>
            </div>
          </div>
        </>
      ) : null}

      {selectedStep &&
      selectedStep.stepName ===
        COMMUNITY_EVENT_STEP_NAMES.BAD_ANSWER_FLAG_EXPIRES ? (
        <>
          <div className={styles.header}>
            <div className={styles.title}>Add event</div>
            <div className={styles.description}>
              {COMMUNITY_EVENT_STEP_NAMES_LABELS.BAD_ANSWER_FLAG_EXPIRES}
            </div>
          </div>
        </>
      ) : null}

      {selectedStep &&
      selectedStep.stepName ===
        COMMUNITY_EVENT_STEP_NAMES.USER_REPUTATION_DECREASES_TO_X ? (
        <>
          <div className={styles.header}>
            <div className={styles.title}>Add event</div>
            <div className={styles.description}>
              {COMMUNITY_EVENT_STEP_NAMES_LABELS.USER_REPUTATION_DECREASES_TO_X}
            </div>
            <div className={styles.fieldTitle}>Reputation</div>
            <div className={styles.fieldContainer}>
              <input
                className={`${styles.inputField} ${styles.amount1NarrowField} ${
                  styles.inputFieldWithLabel
                }`}
                type="number"
                value={
                  selectedStep.amount1 || selectedStep.amount1 === 0
                    ? selectedStep.amount1
                    : ''
                }
                onKeyDown={evt =>
                  evt.key === 'ArrowDown' ||
                  evt.key === 'ArrowUp' ||
                  evt.key === 'e' ||
                  evt.key === 'E' ||
                  evt.key === '.' ||
                  evt.key === '-'
                    ? evt.preventDefault()
                    : null
                }
                onChange={e => {
                  onChangePercentageProperty(e, 'amount1');
                }}
                onWheel={e => e.target.blur()}
              />
              <span className={styles.inputFieldLabel}>%</span>
            </div>
          </div>
        </>
      ) : null}

      {selectedStep &&
      selectedStep.stepName ===
        COMMUNITY_EVENT_STEP_NAMES.USER_REPUTATION_INCREASES_TO_X ? (
        <>
          <div className={styles.header}>
            <div className={styles.title}>Add event</div>
            <div className={styles.description}>
              {COMMUNITY_EVENT_STEP_NAMES_LABELS.USER_REPUTATION_INCREASES_TO_X}
            </div>
            <div className={styles.fieldTitle}>Reputation</div>
            <div className={styles.fieldContainer}>
              <input
                className={`${styles.inputField} ${styles.amount1NarrowField} ${
                  styles.inputFieldWithLabel
                }`}
                type="number"
                value={
                  selectedStep.amount1 || selectedStep.amount1 === 0
                    ? selectedStep.amount1
                    : ''
                }
                onKeyDown={evt =>
                  evt.key === 'ArrowDown' ||
                  evt.key === 'ArrowUp' ||
                  evt.key === 'e' ||
                  evt.key === 'E' ||
                  evt.key === '.' ||
                  evt.key === '-'
                    ? evt.preventDefault()
                    : null
                }
                onChange={e => {
                  onChangePercentageProperty(e, 'amount1');
                }}
                onWheel={e => e.target.blur()}
              />
              <span className={styles.inputFieldLabel}>%</span>
            </div>
          </div>
        </>
      ) : null}

      {selectedStep &&
      selectedStep.stepName ===
        COMMUNITY_EVENT_STEP_NAMES.USER_RESULT_REWARDED ? (
        <>
          <div className={styles.header}>
            <div className={styles.title}>Add event</div>
            <div className={styles.description}>
              {COMMUNITY_EVENT_STEP_NAMES_LABELS.USER_RESULT_REWARDED}
            </div>

            <div className={styles.fieldTitle}>Amount</div>
            <div className={styles.fieldContainer}>
              <input
                className={`${styles.inputField} ${styles.amount1NarrowField}`}
                type="number"
                value={selectedStep.amount1 || ''}
                onKeyDown={evt =>
                  evt.key === 'ArrowDown' ||
                  evt.key === 'ArrowUp' ||
                  evt.key === 'e' ||
                  evt.key === 'E' ||
                  evt.key === '.' ||
                  evt.key === '-'
                    ? evt.preventDefault()
                    : null
                }
                onChange={e =>
                  onChangeProperty('amount1', parseInt(e.target.value, 10))
                }
                onWheel={e => e.target.blur()}
              />
            </div>
          </div>
        </>
      ) : null}

      {selectedStep &&
      selectedStep.stepName ===
        COMMUNITY_ACTION_STEP_NAMES.SEND_PUSH_NOTIFICATION ? (
        <div className={styles.header}>
          <div className={styles.title}>Add action</div>
          <div className={styles.description}>Push notification</div>
          <div className={styles.fieldTitle}>Title</div>
          <div className={styles.fieldContainer}>
            <input
              className={styles.inputField}
              value={selectedStep.title || ''}
              onChange={e => {
                if (e.target.value.length <= maxPushTitleLength) {
                  onChangeProperty('title', e.target.value);
                }
              }}
            />
            <span className={styles.inputCharacterCount}>
              {maxPushTitleLength -
                (selectedStep && selectedStep.title && selectedStep.title.length
                  ? selectedStep.title.length
                  : 0)}
            </span>
          </div>
          <div className={styles.fieldTitle}>Description</div>
          <div className={styles.fieldContainer}>
            <textarea
              className={styles.textareaField}
              onChange={e => onChangeProperty('description', e.target.value)}
              value={selectedStep.description}
            />
          </div>
        </div>
      ) : null}

      {selectedStep &&
      selectedStep.stepName === COMMUNITY_ACTION_STEP_NAMES.SEND_EMAIL ? (
        <div className={styles.header}>
          <div className={styles.title}>Add action</div>
          <div className={styles.description}>Send mail</div>
          <div className={styles.fieldTitle}>Select to</div>
          <div className={styles.fieldContainer}>
            <DropdownSelector
              filterDropdownStyle={
                selectedStep &&
                selectedStep.option2 &&
                selectedStep.option2.value
                  ? `${styles.dropdownWiderFilterStyle} ${styles.selectedValue}`
                  : styles.dropdownWiderFilterStyle
              }
              dropdownPopupStyle={styles.filterDropdownPopup}
              options={SEND_EMAIL_ACTION_OPTIONS}
              value={
                selectedStep &&
                selectedStep.option2 &&
                selectedStep.option2.value
                  ? {
                      value: selectedStep.option2.value,
                      label: selectedStep.option2.label
                    }
                  : { value: null, label: '' }
              }
              onSelectCompleted={(value, label) => {
                const newSteps = communityAutomation.steps;
                newSteps[selectedCommunityAutomationStepIndex].option2 = {
                  value,
                  label
                };

                setCommunityAutomation({
                  ...communityAutomation,
                  steps: newSteps
                });
              }}
              placeholder="Select recipient"
            />
          </div>
          {selectedStep &&
          selectedStep.option2 &&
          selectedStep.option2.value ===
            SEND_EMAIL_ACTION_TYPES.OTHER_RECIPIENT ? (
            <>
              <div className={styles.fieldTitle}>E-mail address recipient</div>
              <div className={styles.fieldContainer}>
                <input
                  className={styles.inputField}
                  value={selectedStep.email || ''}
                  onChange={e => onChangeProperty('email', e.target.value)}
                />
              </div>
            </>
          ) : null}
          <div className={styles.fieldTitle}>
            What is the subject line for this template?
          </div>
          <div className={styles.fieldContainer}>
            <input
              className={styles.inputField}
              value={selectedStep.title || ''}
              onChange={e => {
                if (e.target.value.length <= maxPushTitleLength) {
                  onChangeProperty('title', e.target.value);
                }
              }}
            />
            <span className={styles.inputCharacterCount}>
              {maxPushTitleLength -
                (selectedStep && selectedStep.title && selectedStep.title.length
                  ? selectedStep.title.length
                  : 0)}
            </span>
          </div>
          <div className={styles.fieldTitle}>Select email template</div>
          <div className={styles.fieldContainer}>
            <DropdownSelector
              filterDropdownStyle={
                selectedStep &&
                selectedStep.option1 &&
                selectedStep.option1.value
                  ? `${styles.dropdownWiderFilterStyle} ${styles.selectedValue}`
                  : styles.dropdownWiderFilterStyle
              }
              dropdownPopupStyle={styles.filterDropdownPopup}
              options={null}
              fetchOptions={fetchTemplates}
              value={
                selectedStep &&
                selectedStep.option1 &&
                selectedStep.option1.value
                  ? {
                      value: selectedStep.option1.value,
                      label: selectedStep.option1.label
                    }
                  : { value: null, label: '' }
              }
              onSelectCompleted={(value, label) => {
                const newSteps = communityAutomation.steps;
                newSteps[selectedCommunityAutomationStepIndex].option1 = {
                  value,
                  label
                };

                setCommunityAutomation({
                  ...communityAutomation,
                  steps: newSteps
                });
              }}
              placeholder="Select template"
            />
          </div>
        </div>
      ) : null}

      {selectedStep &&
      selectedStep.stepName === COMMUNITY_ACTION_STEP_NAMES.GIVE_TOKENS ? (
        <div className={styles.header}>
          <div className={styles.title}>Add action</div>
          <div className={styles.description}>Give user x tokens</div>
          <div className={styles.fieldTitle}>Amount of tokens</div>
          <div className={styles.fieldContainer}>
            <input
              className={`${styles.inputField} ${styles.amount1NarrowField}`}
              type="number"
              value={selectedStep.amount3 || ''}
              onKeyDown={evt =>
                evt.key === 'ArrowDown' ||
                evt.key === 'ArrowUp' ||
                evt.key === 'e' ||
                evt.key === 'E' ||
                evt.key === '.' ||
                evt.key === '-'
                  ? evt.preventDefault()
                  : null
              }
              onChange={e =>
                onChangeProperty('amount3', parseInt(e.target.value, 10))
              }
              onWheel={e => e.target.blur()}
            />
          </div>
          <div className={styles.fieldTitle}>
            Reason (visible in token history)
          </div>
          <div className={styles.fieldContainer}>
            <input
              className={styles.inputField}
              value={selectedStep.title || ''}
              onChange={e => {
                if (e.target.value.length <= maxPushTitleLength) {
                  onChangeProperty('title', e.target.value);
                }
              }}
            />
            <span className={styles.inputCharacterCount}>
              {maxPushTitleLength -
                (selectedStep && selectedStep.title && selectedStep.title.length
                  ? selectedStep.title.length
                  : 0)}
            </span>
          </div>
        </div>
      ) : null}

      {selectedStep &&
      selectedStep.stepName === COMMUNITY_ACTION_STEP_NAMES.POPUP_MESSAGE ? (
        <div className={styles.header}>
          <div className={styles.title}>Add action</div>
          <div className={styles.description}>In-app pop-up message</div>
          <div className={styles.fieldTitle}>Title</div>
          <div className={styles.fieldContainer}>
            <input
              className={styles.inputField}
              value={selectedStep.title || ''}
              onChange={e => {
                if (e.target.value.length <= maxPopupTitleLength) {
                  onChangeProperty('title', e.target.value);
                }
              }}
            />
            <span className={styles.inputCharacterCount}>
              {maxPopupTitleLength -
                (selectedStep && selectedStep.title && selectedStep.title.length
                  ? selectedStep.title.length
                  : 0)}
            </span>
          </div>
          <div className={styles.fieldTitle}>Description</div>
          <div className={styles.fieldContainer}>
            <textarea
              className={styles.textareaField}
              onChange={e => {
                if (e.target.value.length <= maxPopupDescriptionLength) {
                  onChangeProperty('description', e.target.value);
                }
              }}
              value={selectedStep.description}
            />
            <span className={styles.inputCharacterCount}>
              {maxPopupDescriptionLength -
                (selectedStep &&
                selectedStep.description &&
                selectedStep.description.length
                  ? selectedStep.description.length
                  : 0)}
            </span>
          </div>
          <div className={styles.fieldTitle}>Button</div>
          <div className={styles.fieldContainer}>
            <input
              className={styles.inputField}
              value={selectedStep.submitText || ''}
              onChange={e => {
                if (e.target.value.length <= maxPopupButtonLength) {
                  onChangeProperty('submitText', e.target.value);
                }
              }}
            />
            <span className={styles.inputCharacterCount}>
              {maxPopupButtonLength -
                (selectedStep &&
                selectedStep.submitText &&
                selectedStep.submitText.length
                  ? selectedStep.submitText.length
                  : 0)}
            </span>
          </div>
        </div>
      ) : null}

      {selectedStep &&
      selectedStep.stepName ===
        COMMUNITY_ACTION_STEP_NAMES.DECREASE_PROFILE_REPUTATION_WITH_X ? (
        <div className={styles.header}>
          <div className={styles.title}>Add action</div>
          <div className={styles.description}>
            {
              COMMUNITY_ACTION_STEP_NAMES_LABELS.DECREASE_PROFILE_REPUTATION_WITH_X
            }
          </div>
          <div className={styles.fieldTitle} />
          <div className={styles.fieldContainer}>
            <input
              className={`${styles.inputField} ${styles.amount1NarrowField} ${
                styles.inputFieldWithLabel
              }`}
              type="number"
              value={
                selectedStep.amount1 || selectedStep.amount1 === 0
                  ? selectedStep.amount1
                  : ''
              }
              onKeyDown={evt =>
                evt.key === 'ArrowDown' ||
                evt.key === 'ArrowUp' ||
                evt.key === 'e' ||
                evt.key === 'E' ||
                evt.key === '.' ||
                evt.key === '-'
                  ? evt.preventDefault()
                  : null
              }
              onChange={e => {
                onChangePercentageProperty(e, 'amount1');
              }}
              onWheel={e => e.target.blur()}
            />
            <span className={styles.inputFieldLabel}>%</span>
          </div>
        </div>
      ) : null}

      {selectedStep &&
      selectedStep.stepName ===
        COMMUNITY_ACTION_STEP_NAMES.INCREASE_PROFILE_REPUTATION_WITH_X ? (
        <div className={styles.header}>
          <div className={styles.title}>Add action</div>
          <div className={styles.description}>
            {
              COMMUNITY_ACTION_STEP_NAMES_LABELS.INCREASE_PROFILE_REPUTATION_WITH_X
            }
          </div>
          <div className={styles.fieldTitle} />
          <div className={styles.fieldContainer}>
            <input
              className={`${styles.inputField} ${styles.amount1NarrowField} ${
                styles.inputFieldWithLabel
              }`}
              type="number"
              value={
                selectedStep.amount1 || selectedStep.amount1 === 0
                  ? selectedStep.amount1
                  : ''
              }
              onKeyDown={evt =>
                evt.key === 'ArrowDown' ||
                evt.key === 'ArrowUp' ||
                evt.key === 'e' ||
                evt.key === 'E' ||
                evt.key === '.' ||
                evt.key === '-'
                  ? evt.preventDefault()
                  : null
              }
              onChange={e => {
                onChangePercentageProperty(e, 'amount1');
              }}
              onWheel={e => e.target.blur()}
            />
            <span className={styles.inputFieldLabel}>%</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};
