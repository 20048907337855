import calculateQuestionStats from './calculateQuestionStats';

export default (
  mergedGraphQuestions,
  isPercentage,
  isFiltered,
  hiddenQuestions,
  surveyLanguage
) => {
  const getChartData = mergedGraphQuestionsWithStats => {
    const chartData = [];
    mergedGraphQuestionsWithStats.forEach((question, questionIndex) => {
      if (
        question.questionStats &&
        !(
          hiddenQuestions &&
          hiddenQuestions.length &&
          hiddenQuestions.indexOf(questionIndex) > -1
        )
      ) {
        question.questionStats.forEach(singleStat => {
          const existingChartData = chartData.find(
            cd => cd.group.trim() === singleStat.label.trim()
          );

          const value = isPercentage ? singleStat.percentage : singleStat.y;

          if (existingChartData) {
            existingChartData[questionIndex] = value;
          }

          if (
            !existingChartData &&
            (value || value === 0) &&
            !Number.isNaN(parseInt(value, 10))
          ) {
            chartData.push({
              group: singleStat.label.trim(),
              [questionIndex]: value
            });
          }
        });
      }
    });
    return chartData;
  };

  const mergedGraphQuestionsWithStats = mergedGraphQuestions.map(mGQ => ({
    ...mGQ,
    questionStats:
      mGQ &&
      calculateQuestionStats(mGQ, mGQ.results, mGQ.resultStats, surveyLanguage)
  }));

  const chartData = getChartData(mergedGraphQuestionsWithStats);

  if (isFiltered) {
    const mergedGraphUnfilteredQuestionsWithStats = mergedGraphQuestions.map(
      mGQ => ({
        ...mGQ,
        questionStats: calculateQuestionStats(
          mGQ,
          mGQ.unfilteredResults,
          mGQ.unfilteredResultStats,
          surveyLanguage
        )
      })
    );

    const unfilteredChartData = getChartData(
      mergedGraphUnfilteredQuestionsWithStats
    );

    unfilteredChartData.forEach((unfilteredData, unfilteredDataIndex) => {
      const unfilteredValueKeys = Object.keys(unfilteredData).filter(
        key => key !== 'group'
      );

      unfilteredValueKeys.forEach(unfilteredValueKey => {
        if (
          chartData[unfilteredDataIndex] &&
          unfilteredChartData[unfilteredDataIndex] &&
          unfilteredChartData[unfilteredDataIndex][unfilteredValueKey]
        ) {
          chartData[unfilteredDataIndex][`${unfilteredValueKey}_nofilter`] =
            unfilteredChartData[unfilteredDataIndex][unfilteredValueKey];
        }
      });
    });
  }

  return chartData;
};
