import React, { useState } from 'react';

import ActionDropdown from './components/ActionDropdown/ActionDropdown';

import arrowGrey from '../../../../../../../../../../assets/img/arrow-down.svg';

import styles from './ConfigurationRecord.module.css';

const ConfigurationRecord = ({
  editActions,
  removeActions,
  key,
  record,
  parentRecord,
  subRecords,
  onFetchSubRecords,
  toggleShowSubRecords,
  isParentRecord = false,
  isSubRecord = false
}) => {
  const [actionDropdownVisible, setActionDropdownVisible] = useState(false);

  const actionDropdownPositionStyle = {
    right: '10px',
    top: '25px',
    bottomPosition: '40px',
    width: '160px'
  };

  return (
    <>
      <tr className={styles.rowContainer} key={key}>
        <td title={record.name} className={isSubRecord ? styles.subRecord : ''}>
          {record.name}
          {isParentRecord ? (
            <img
              role="presentation"
              alt={`${subRecords && subRecords.visible ? 'visible' : 'hidden'}`}
              className={`${
                subRecords && subRecords.visible
                  ? `${styles.arrow} ${styles.arrowPointingUp}`
                  : styles.arrow
              }`}
              src={arrowGrey}
              onClick={() => {
                if (subRecords) {
                  toggleShowSubRecords(!subRecords.visible);
                } else {
                  // No pagination necessary for subrecords
                  onFetchSubRecords({
                    start: 0,
                    end: 100
                  });
                }
              }}
            />
          ) : null}
        </td>
        <td>
          <div className={styles.actionsContainer}>
            <div
              role="presentation"
              className={styles.actionsButton}
              onClick={e => {
                e.stopPropagation();
                setActionDropdownVisible(true);
              }}
            >
              ...
            </div>
            {actionDropdownVisible ? (
              <ActionDropdown
                positionStyle={actionDropdownPositionStyle}
                onBlur={() => setActionDropdownVisible(false)}
                onEditClick={() => {
                  editActions[record.type].setPopupAction(record, parentRecord);
                  setActionDropdownVisible(false);
                }}
                onDeleteClick={() => {
                  removeActions[record.type].setPopupAction(record);
                  setActionDropdownVisible(false);
                }}
              />
            ) : null}
          </div>
        </td>
      </tr>
      {isParentRecord &&
      subRecords &&
      subRecords.visible &&
      subRecords.records &&
      subRecords.records.length
        ? subRecords.records.map(r => (
            <ConfigurationRecord
              editActions={editActions}
              removeActions={removeActions}
              record={r}
              parentRecord={record}
              key={`bussiness-config-sub-record-${r.id.toString()}`}
              isSubRecord
            />
          ))
        : null}
    </>
  );
};

export default ConfigurationRecord;
