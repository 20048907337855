export const TABLE_TYPES = { CHALLENGE: 'CHALLENGE', GOAL: 'GOAL' };

export const BUSINESS_CONFIGURATION_TYPES = {
  CHALLENGE: 'CHALLENGE',
  GOAL: 'GOAL',
  CATEGORY: 'CATEGORY'
};

export const CONFIGURATION_TYPE_NAMES = {
  [BUSINESS_CONFIGURATION_TYPES.CHALLENGE]: 'business challenge',
  [BUSINESS_CONFIGURATION_TYPES.GOAL]: 'company goal',
  [BUSINESS_CONFIGURATION_TYPES.CATEGORY]: 'category'
};

export const ACTION_TYPES = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE'
};
