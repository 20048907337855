export default {
  CONFIRM: 'Bestätigen',
  TERMS: 'Bedingungen',
  SURVEY: 'Umfrage',
  YOUR_INFO: 'Ihre Daten',
  THANKS: 'Danke',
  BY_CLICK_AGREE:
    'Indem ich auf „Zustimmen und fortfahren“ klicke, stimme ich hiermit zu und stimme den Bedingungen zu: den Allgemeinen Geschäftsbedingungen und der Datenschutzrichtlinie',
  AGREE_AND_CONTINUE: 'Zustimmen und fortfahren',
  BACK: 'Zurück',
  NEXT: 'Weiter',
  I_AM_AWARE_OF_THE: 'Ich habe die',
  READ_AND_UNDERSTAND: 'gelesen und verstanden',
  THE_TERMS_AND_CONDITIONS:
    'The general terms and conditions and the privacy policy',
  TERMS_AND_CONDITIONS: 'Geschäftsbedingungen',
  AND_AGREE_BY_CHECKING_THIS_BOX:
    'und stimme diesen zu, indem ich dieses Kästchen ankreuze.',
  AND_CONFIRM_I_AGREE: 'und bestätige, dass ich damit einverstanden bin.',
  I_AGREE: 'Ich stimme zu',
  GOVERNED_BY_BUFFL_POLICY:
    'Für diese Umfrage gelten die Datenschutzbestimmungen von BUFFL.\nBitte lesen Sie',
  PRIVACY_POLICY: 'unsere Datenschutzbestimmungen',
  BEFORE_PROCEEDING: 'bevor Sie fortfahren.',
  AGREE_TO_PROCEED: 'Sind Sie damit einverstanden, fortzufahren?',
  YES_I_AGREE: 'Ja, ich stimme zu',
  NO_I_DO_NOT_AGREE: 'Nein, ich stimme nicht zu',
  PLEASE_SELECT_VALUE: 'Bitte auswählen',
  PLEASE_ENTER: 'Bitte gehen Sie weiter',
  WE_ARE_ALMOST_THERE:
    'Wir sind fast fertig, wir wollen Sie nur etwas besser kennenlernen!',
  FEMALE: 'Weiblich',
  MALE: 'Männlich',
  OTHER: 'Andere',
  GENDER: 'Geschlecht',
  AGE: 'Alter',
  POSTAL_CODE: 'Postleitzahl',
  CREATE_USER_PROFILE: 'Benutzerprofil erstellen',
  PASSWORD: 'Passwort',
  REPEAT_PASSWORD: 'Passwort wiederholen',
  PLEASE_REPEAT_PASSWORD: 'Bitte Passwort wiederholen',
  WE_NEED_YOUR_EMAIL: 'Wir brauchen Ihre E-Mail-Adresse.',
  SO_WE_CAN_SEND_YOU_EMAIL:
    '.. Damit wir Ihnen eine Bestätigungs-E-Mail senden können, um Ihre Umfrage zu validieren',
  EMAIL: 'Email',
  SO_YOU_CAN_BE_CONTACTED_IF_WIN:
    '.. Damit Sie kontaktiert werden können, wenn Sie einen Preis gewinnen',
  YES_I_WANT_TO_BE_CONTACTED_IF_WIN:
    'Ja, ich möchte kontaktiert werden, wenn ich gewinne',
  SO_YOU_CAN_BE_CONTACTED_FOR_THE_NEXT_PARTS:
    '.. Damit Sie für die nächsten Teile der Umfrage kontaktiert werden können',
  YES_I_WANT_TO_BE_CONTACTED_FOR_NEXT_SURVEYS:
    'Ja, ich möchte für die nächsten Umfragen kontaktiert werden',
  SO_YOU_CAN_BE_ADDED_TO_MAILING_LIST_OF_CUSTOMER:
    '.. Damit Sie zur Mailingliste hinzugefügt werden können',
  YES_I_WANT_TO_BE_ADDED_TO_MAILING_LIST_OF_CUSTOMER:
    'Ich möchte in die Mailingliste aufgenommen werden',
  SO_YOU_CAN_HAVE_OWN_BUFFL_LOGIN:
    '..Damit Sie Ihr eigenes BUFFALO-Login haben können.',
  WHAT_IS_BUFFL_ABOUT: 'Was ist BUFFL?',
  AT_BUFFL_YOU_CAN_HELP_DETERMINE_PRODUCTS:
    'Bei BUFFL können Sie mitbestimmen, wie die Produkte, Dienstleistungen und Marken von morgen aussehen sollen. In der mobilen BUFFL-App können Sie an mehr Umfragen teilnehmen und werden mit digitalen Geschenkgutscheinen belohnt!',
  THE_SURVEY_YOU_ARE_LOOKING_IS_UNFORTUNATELY_NO_AVAILABLE: 'Vielen Dank!',
  THE_SURVEY_YOU_ARE_LOOKING_IS_NO_AVAILABLE:
    'Die von Ihnen gesuchte Umfrage ist derzeit nicht mehr verfügbar.',
  CHECKOUT_AWESOME_BUFFL_APP:
    'Schauen Sie sich die fantastische BUFFL-App für weitere Umfragen an und lassen Sie sich mit tollen Preisen belohnen.',
  START: 'Start',
  ALL_FIELDS_ARE_REQUIRED: 'Alle Felder sind erforderlich',
  PASSWORDS_ARE_NOT_SAME: 'Passwörter sind nicht gleich',
  EMAIL_IS_REQUIRED: 'E-Mail ist erforderlich',
  WE_HAVE_SENT_YOU_EMAIL_WITH_CONFIRMATION_LINK:
    'Wir haben Ihnen eine E-Mail mit einem Bestätigungslink an Ihre E-Mail-Adresse gesendet. Es ist möglich, dass wir an Ihren Spam-Ordner gesendet wurden.',
  RESULT_ACCEPTED: 'Ergebnis akzeptiert.',
  YOUR_RESULTS_HAS_BEEN_CONFIRMED: 'Ihr Ergebnis wurde erfolgreich bestätigt.',
  SURVEY_FAILED: 'Vielen Dank für Ihre Teilnahme!',
  UNFORTUNATELY_YOU_ARE_NOT_ELIGIBLE: 'Vielen Dank!',
  UNFORTUNATELY: 'Unglücklicherweise',
  UNFORTUNATELY_ON_FAILED_PAGE: 'Unglücklicherweise',
  UNFORTUNATELY_ON_NOT_AVAILABLE_PAGE: 'Unglücklicherweise',
  YOU_ARE_NOT_ELIGIBLE:
    'Ihr Profil entspricht nicht den Anforderungen für diese Recherche.',
  DO_NOT_WORRY_DOZENS_OF_RESEARCHES_FAILED:
    'Vielen Dank, dass Sie uns Ihre Meinung mitteilen möchten. Wir haben bereits Danke, dass Sie uns Ihre Meinung mitteilen möchten. Wir haben bereits genügend Befragte mit einem ähnlichen Profil wie dem Ihren. Keine Sorge, in Kürze werden neue Umfragen online gehen. Möchten Sie die Ersten sein, die davon erfahren? Dann laden Sie jetzt die BUFFL-App herunter!',
  DO_NOT_WORRY_DOZENS_OF_RESEARCHES_NOT_AVAILABLE:
    'Vielen Dank, dass Sie uns Ihre Meinung mitteilen möchten. Wir haben bereits genug Teilnehmer für diese Umfrage. Keine Sorge, in Kürze werden neue Umfragen online gehen. Möchten Sie die Ersten sein, die davon erfahren? Dann laden Sie jetzt die BUFFL-App herunter!',
  RESULT_CONFIRMED: 'Ergebnis bestätigt.',
  YOUR_RESULT_WAS_ALREADY_REGISTERED_SUCESSFULY:
    'Ihre Antwort wurde bereits erfolgreich registriert!',
  IF_YOU_LIKE_PARTICIPATING_IN_MORE_SURVEYS:
    'Wenn Sie gerne an weiteren Umfragen teilnehmen und tolle Preise verdienen, installieren Sie unsere BUFFL-App!',
  CONFIRM_EMAIL: 'Bestätigen Sie Ihre E-Mail.',
  MINIMAL_CHARACTERS: 'Minimale Zeichen',
  MAXIMAL_CHARACTERS: 'Maximale Zeichen',
  START_A_NEW_SURVEY: 'Neue Umfrage starten',
  READ_FULL_TERMS_AND_CONDITIONS: 'Vollständige Geschäftsbedingungen lesen',
  READ_FULL_PRIVACY_POLICY: 'Vollständige Datenschutzerklärung lesen',
  WELCOME: 'Willkommen',
  WELCOME_STEP: 'Willkommen',
  USER_PROFILE: 'Benutzerprofil',
  CONFIRMATION: 'Bestätigung',
  WE_MAKE_YOU_PLEASANT_HERE:
    'Wir möchten es Ihnen während dieser Umfrage so angenehm wie möglich machen. Wählen Sie daher unten aus, in welcher Sprache Sie fortfahren möchten.',
  YOU_ARE_NOT_JANE: 'Schön, Sie kennenzulernen!',
  SOCIO_DEMO:
    'Bevor wir mit der Umfrage beginnen können, möchten wir Sie um einige grundlegende Informationen bitten. Diese Informationen helfen uns, unsere Befragten besser zu verstehen.\n\nSeien Sie versichert - BUFFL behandelt Ihre Daten mit Sorgfalt. Das bedeutet, dass alle Antworten anonymisiert werden.',
  WE_NEED_FEW_MORE_DETAILS_FROM_YOU:
    'Bevor wir mit der Umfrage beginnen können, möchten wir Sie um einige grundlegende Informationen bitten. Diese Informationen helfen uns, unsere Befragten besser zu verstehen.',
  REST_ASSURED_RESPONSES_ARE_ANONYMIZED:
    'Seien Sie versichert – BUFFALO behandelt Ihre Daten mit Sorgfalt. Das bedeutet, dass alle Antworten anonymisiert werden.',
  YOU_ARE: 'Sie sind...',
  YOU_ARE_A: 'Sie sind ein...',
  YOUR_POSTCODE_IS: 'Ihre Postleitzahl ist...',
  YEARS_OLD: '...Jahre alt.',
  HOW_OLD_ARE_YOU: 'Wie alt Sind sie?',
  MALE_SHORT: 'M',
  FEMALE_SHORT: 'W',
  ENTER_POSTCODE: 'Geben Sie Ihre Postleitzahl ein',
  INTRODUCTION: `Sind Sie bereit?\n\n Diese Umfrage wird eine Vielzahl von Fragen enthalten - und wir sind in erster Linie an Ihrer persönlichen Antwort interessiert: Es gibt keine falschen Antworten!\n\nDiese Umfrage wird etwa {time} Minuten dauern.\n\nWir hoffen, es wird Ihnen gefallen.\n\nLos geht's!`,
  READY_TO_LAUNCH: 'Sind Sie bereit?',
  DURING_SURVEY_WILL_PRESENT_SURVEY:
    'Diese Umfrage wird eine Vielzahl von Fragen enthalten - und uns interessiert vor allem Ihre persönliche Antwort: Es gibt keine falschen Antworten!',
  THIS_SURVEY_TAKES:
    'Diese Umfrage wird ungefähr {time} Minuten Ihrer Zeit in Anspruch nehmen.',
  WE_HOPE_YOU_WILL_ENJOY: 'Wir hoffen, dass es Ihnen gefallen wird.',
  LETS_GO: `Los geht's!'`,
  JUST_THIS: 'Vielen Dank für Ihre Teilnahme!',
  WE_CAN_REACH_YOU:
    'Können Sie bitte Ihre E-Mail bestätigen? Wir benötigen dies, um Ihre Antworten zu validieren.',
  WOULD_YOU_LIKE_TO_RECEIVE_NEW_SURVEYS:
    'Halten Sie mich über zukünftige Umfragen auf dem Laufenden',
  YOU_WANT_TO_BE_CONTACTED:
    'Benachrichtigen Sie mich, wenn ich einen Preis gewonnen habe',
  A_DONKEY_DOESNT_BUMP:
    'Ein Esel stößt nicht zweimal auf denselben Stein. Erstellen Sie schnell ein Benutzerprofil und vermeiden Sie es, Zeit mit der Teilnahme an zukünftigen Umfragen oder Wettbewerben zu verschwenden!',
  CREATE_A_BUFFL_USER_PROFILE: 'Ein BUFFL-Benutzerprofil erstellen',
  BUFFL_HAS_DOZENS_OF_RESEARCHES:
    'BUFFL führt jede Woche Dutzende von Recherchen zu einer Vielzahl von Themen durch - es ist also für jeden Geschmack etwas dabei.',
  COMPLETED: `Wären Sie daran interessiert, Ihre Meinung zu den neuesten innovativen Produkten abzugeben, Wertmarken zu verdienen und die Chance zu haben, fantastische Preise zu gewinnen - einschließlich des Ausprobierens neuer Produkte?\n\nInstallieren Sie die BUFFL-App, die im App Store und im Google Play Store erhältlich ist .`,
  WOULD_YOU_BE_INTERESTED_GIVING_AN_OPINION:
    'Wären Sie daran interessiert, Ihre Meinung zu den neuesten innovativen Produkten abzugeben, Token zu verdienen und die Chance zu haben, fantastische Preise zu gewinnen - einschließlich des Ausprobierens neuer Produkte?',
  YOUR_ANSWERS_WILL_BE_PROCESSED_SECURELY:
    'Ihre Antworten werden sicher und anonym verarbeitet.',
  INSTALL_THE_BUFFL_APP:
    'Installieren Sie die BUFFL-App, erhältlich im App Store und Google Play Store.',
  WOULD_YOU_STAY_UP_OF_LATEST_NEWS_VIA_MAIL:
    'Möchten Sie lieber per E-Mail über die neuesten Nachrichten auf dem Laufenden bleiben – oder nur deine E-Mail hinterlassen, um einen Preis zu gewinnen? Hinterlassen Sie dann hier Ihre E-Mail-Adresse!',
  CHOOSE_PASSWORD: 'Wählen Sie Ihr Passwort',
  ENTER_YOUR_PASSWORD: 'Geben Sie Ihr Passwort ein',
  CONFIRM_PASSWORD: 'Bestätigen Sie Ihr Passwort',
  OPEN_MAILBOX_CONFIRM_ANSWER:
    'Öffnen Sie Ihr Postfach und bestätigen Sie Ihre Antworten und Ihre E-Mail-Adresse.',
  CHECK_SPAM_FOLDER:
    'Überprüfen Sie unbedingt Ihren Spam-Ordner auf heimtückische.',
  THANK_YOU: 'Vielen Dank fürs Teilnehmen.',
  MORE_IMPACT: 'Sehnsucht nach mehr Wirkung?',
  WE_GOT_YOUR_ANSWERS: 'Wir haben Ihre Antworten erhalten, danke!',
  WE_ARE_BUFFL: 'Wir sind BUFFL.',
  THROUGH_YOUR_PARTICIPATION:
    'Durch Ihre Teilnahme an solchen Umfragen entscheiden Sie, wie Produkte, Dienstleistungen und Marken aussehen sollen. Möchten Sie öfter teilnehmen und immer die Chance haben, schöne Geschenke zu gewinnen? Dann laden Sie unbedingt die BUFFL-App herunter und geben Sie Bescheid.',
  YOU_HAVE_ALREADY_PARTICIPATED:
    'Sie haben bereits an dieser Umfrage teilgenommen',
  SHARE_YOUR_OPINION: '',
  CONTRIBUTE_TO_INNOVATIONS_OF_TOMORROW: '',
  BE_REWARDED: '',
  REVIEW_ONE: '',
  REVIEW_TWO: '',
  REVIEW_THREE: '',
  JOIN_THE_HERD: 'Schließen Sie sich der Herde an',
  WHAT_DOES_BUFFL_OFFER: '',
  DATE_OF_BIRTH: 'Geburtsdatum',
  CHOOSE_A_GENDER: 'Wählen Sie ein Geschlecht',
  CREATE_ACCOUNT: 'Benutzerkonto erstellen',
  CONGRATULATIONS: 'Glückwünsch',
  PART_OF_THE_HERD: 'Sie sind jetzt offiziell Teil der Herde.',
  DOWNLOAD_AND_START_EARNING_TOKENS:
    'Laden Sie die App herunter und verdienen Sie Token',
  DOWNLOAD_THE_APP_NOW: 'Laden Sie jetzt die BUFFL-App herunter!',
  PLEASE_FILL_FIELD: 'Bitte füllen Sie dieses Feld aus',
  PLEASE_ENTER_VALID_EMAIL: 'Bitte geben Sie eine gültige Email-Adresse ein',
  PLEASE_ENTER_DATE_OF_BIRTH_RIGHT_FORMAT:
    'Geben Sie das Geburtsdatum im richtigen Format ein',
  PLEASE_ENTER_VALID_DATE_OF_BIRTH:
    'Bitte geben Sie ein gültiges Geburtsdatum ein',
  PLEASE_SELECT_GENDER: 'Bitte wählen Sie ein Geschlecht',
  PASSWORDS_NOT_SAME: 'Passwörter stimmen nicht überein',
  EMAIL_ALREADY_USED: 'Diese E-Mail-Adresse wird bereits verwendet',
  START_SURVEY: 'Umfrage starten',
  GIVE_YOUR_OPINION_THERE_ARE_NO_BAD_ANSWERS:
    'Bei dieser Umfrage ist es wichtig, Ihre Meinung abzugeben. Es gibt keine falschen Antworten. Je ehrlicher, desto besser!',
  LOADING_IMAGE_FAILED: 'Das Bild konnte nicht geladen werden.',
  AMOUNT_OF_ANWSERS: 'Anzahl der Antworten',
  PLEASE_ENTER_VALID_POSTAL_CODE:
    'Bitte geben Sie eine gültige Postleitzahl ein.',
  OPEN_QUESTION_REMAINING_CHARACTERS:
    'Zeichen, die noch verwendet werden können',
  OPEN_QUESTION_MIN_CHARACTERS: 'mindest.',
  WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT_ON_DECISIONS_BY_PARTICIPATING_IN_SURVEYS:
    'Möchten Sie mehr Einfluss auf Entscheidungen haben, indem Sie an kurzen Umfragen wie dieser teilnehmen?\n\n+ Wir verlosen einen Gutschein für ein 3-tägiges Familienwochenende im Wert von 199,90 € zu gewinnen.',
  WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT:
    'öchten Sie mehr Einfluss auf Entscheidungen haben, indem Sie an kurzen Umfragen wie dieser teilnehmen?',
  I_AM_NOT_INTERESTED: 'Ich bin nicht interessiert.',
  WELL_KEEP_IN_TOUCH: 'Wir bleiben in Kontakt!',
  YOU_HAVE_ALREADY_EARNED_TOKENS_BY_COMPLETING_SURVEYS:
    'Sie haben bereits {euro} € verdient, indem Sie {surveys} Umfragen abgeschlossen haben. Sind Sie bereit, Ihre Belohnung einzufordern?\nLaden Sie die App herunter, registrieren Sie sich unter {email} und wir überweisen die Token auf Ihr Konto.',
  WE_HAVE_NOTICED_EMAIL_IS_LINKED_TO_MOBILE_ACCOUNT:
    'Wir haben festgestellt, dass {email} mit einem Konto in unserer mobilen App verknüpft ist.\nSie erhalten {tokens} Token für das Ausfüllen dieser Umfrage. ',
  YOU_HAVE_ALREADY_CLAIMED_THE_REWARD_FOR_THIS_SURVEY:
    'Sie haben die Belohnung für diese Umfrage bereits in Anspruch genommen.',
  SEC: 'sek',
  PROFILING_QUESTION_CONSENT_TITLE: 'Wir wollen Ihre Umfragen kürzer machen',
  PROFILING_QUESTION_CONSENT_DESCRIPTION:
    'Dürfen wir Ihre Antwort auf einige der vorherigen Fragen speichern? Dies geschieht völlig anonym und stellt sicher, dass Sie diese spezifischen Fragen in zukünftigen Umfragen nicht mehr sehen werden.',
  PROFILING_QUESTION_YES_CONSENT: 'Ja, ich möchte kürzere Umfragen!',
  PROFILING_QUESTION_NO_CONSENT: 'Nein, das möchte ich lieber nicht.',
  PROFILING_QUESTION_NO_DO_NOT_ASK:
    'Nein, frage nicht noch einmal für diese Fragen.',
  PROFILING_QUESTION_NO_DO_NOT_ASK_EVER:
    'Nein, frage nicht noch einmal für diese Fragen oder für andere Fragen.',
  CONVERSION_THANK_YOU: 'Vielen Dank fürs Teilnehmen.',
  CONVERSION_NO_CONSENT: 'Ihre Antworten werden sicher und anonym verarbeitet.',
  SELECT_ANSWERS: 'Wähle {selectAtLeast} Antworten aus',
  SELECT_AT_LEAST_AND_AT_MOST_ANSWERS:
    'Wähle mindestens {selectAtLeast} und höchstens {selectAtMost} Antworten aus',

  // TODO: Old implementation
  MATRIX: {
    // German takes English translations consistent with survey templates
    I_STRONGLY_DISAGREE: 'Strongly disagree',
    I_DISAGREE: 'Disagree',
    I_AGREE: 'Agree',
    I_STRONGLY_AGREE: 'Strongly agree',
    I_NEITHER_AGREE_NOR_DISAGREE: 'Neither agree nor disagree',
    I_SOMEWHAT_DISAGREE: 'Somewhat disagree',
    I_SOMEWHAT_AGREE: 'Somewhat agree'
  },

  MATRIX_I_STRONGLY_DISAGREE: 'Strongly disagree',
  MATRIX_I_DISAGREE: 'Disagree',
  MATRIX_I_AGREE: 'Agree',
  MATRIX_I_STRONGLY_AGREE: 'Strongly agree',
  MATRIX_I_NEITHER_AGREE_NOR_DISAGREE: 'Neither agree nor disagree',
  MATRIX_I_SOMEWHAT_DISAGREE: 'Somewhat disagree',
  MATRIX_I_SOMEWHAT_AGREE: 'Somewhat agree',

  MATRIX_NOT_IMPORTANT_AT_ALL: 'Not important at all',
  MATRIX_RATHER_NOT_IMPORTANT: 'Rather not important',
  MATRIX_RATHER_IMPORTANT: 'Rather important',
  MATRIX_VERY_IMPORTANT: 'Very important ',

  MATRIX_NOT_DIFFICULT_AT_ALL: 'Not difficult at all',
  MATRIX_RATHER_NOT_DIFFICULT: 'Rather not difficult',
  MATRIX_RATHER_DIFFICULT: 'Rather difficult',
  MATRIX_VERY_DIFFICULT: 'Very difficult',

  MATRIX_NOT_VALUABLE_AT_ALL: 'Not valuable at all',
  MATRIX_RATHER_NOT_VALUABLE: 'Rather not valuable',
  MATRIX_RATHER_VALUABLE: 'Rather valuable',
  MATRIX_VERY_VALUABLE: 'Very valuable  ',

  MATRIX_NOT_UNIQUE_AT_ALL: 'Not unique at all',
  MATRIX_RATHER_NOT_UNIQUE: 'Rather not unique',
  MATRIX_RATHER_UNIQUE: 'Rather unique',
  MATRIX_VERY_UNIQUE: 'Very unique',

  MATRIX_CERTAINLY_NOT_ESSENTIAL: 'Certainly not essential',
  MATRIX_RATHER_NOT_ESSENTIAL: 'Rather not essential',
  MATRIX_RATHER_ESSENTIAL: 'Rather essential',
  MATRIX_CERTAINLY_ESSENTIAL: 'Certainly essential',

  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_EUROS_CONVERSION:
    'Sie haben bereits €{EUROS} verdient, indem Sie {SURVEYS} Umfrage(n) abgeschlossen haben. Sind Sie bereit, Ihre Belohnung einzufordern? Laden Sie die App herunter, registrieren Sie sich mit {EMAIL} und wir überweisen den Betrag auf Ihr Konto.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_TOKENS_CONVERSION:
    'Sie haben bereits {TOKENS} Token verdient, indem Sie {SURVEYS} Umfrage(n) abgeschlossen haben. Sind Sie bereit, Ihre Belohnung einzufordern? Laden Sie die App herunter, registrieren Sie sich mit {EMAIL} und wir überweisen die Token auf Ihr Konto.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_IMPACT_CONVERSION:
    'Sind Sie bereit, Ihre Frustrationen, Ideen oder Erkenntnisse rund um das Thema ... in die Praxis umzusetzen? Laden Sie die App herunter und registrieren Sie sich mit {EMAIL}',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_CUSTOM_CONVERSION: '',

  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_EUROS_CONVERSION:
    'Wir haben festgestellt, dass {EMAIL} mit einem Konto in unserer mobilen App verknüpft ist. Sie erhalten €{EUROS} für das Ausfüllen dieser Umfrage.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_TOKENS_CONVERSION:
    'Wir haben festgestellt, dass {EMAIL} mit einem Konto in unserer mobilen App verknüpft ist. Sie erhalten {TOKENS} Token für das Ausfüllen dieser Umfrage.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_IMPACT_CONVERSION:
    'Sind Sie bereit, Ihre Frustrationen, Ideen oder Erkenntnisse rund um das Thema ... in die Praxis umzusetzen? Laden Sie die App herunter und melden Sie sich mit {EMAIL} an.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_CUSTOM_CONVERSION: '',

  END_OF_THE_SURVEY_THANK_YOU_TO_WEB_CONVERSION: 'Wir bleiben in Kontakt!',

  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_AS: 'Are you',
  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_NO_NEED_DATA: '',
  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_YES: 'Yes, give me a shorter survey!',
  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_NO: 'No, continue as guest'
};
