import React from 'react';

import Popup from '../Popup/Popup';
import ConfirmationPopup from '../../../ConfirmationPopup/ConfirmationPopup';
import QuestionChoicesTagging from './components/QuestionChoicesTagging/QuestionChoicesTagging';
import ResearchCostIncreasedPopup from './components/ResearchCostIncreased/ResearchCostIncreased';
import MatrixOptionsLimitWarning from './components/MatrixOptionsLimitWarning/MatrixOptionsLimitWarning';
import ExtraQuestions from './components/ExtraQuestions/ExtraQuestions';

import { SURVEY_BUILDER_POPUP_TYPES } from '../../helpers/constants';
import MatrixQuestionEditScale from './components/MatrixQuestionEditScale/MatrixQuestionEditScale';

export default ({ api, actions, surveyBuilderPopup }) => {
  if (surveyBuilderPopup.type === SURVEY_BUILDER_POPUP_TYPES.CONFIRMATION) {
    return (
      <Popup
        component={
          <ConfirmationPopup
            onClick={surveyBuilderPopup.onClick}
            onClose={surveyBuilderPopup.onClose}
            onDontShowAnymoreChecked={
              surveyBuilderPopup.onDontShowAnymoreChecked
            }
            title={surveyBuilderPopup.title}
            description={surveyBuilderPopup.description}
            warning={surveyBuilderPopup.warning}
          />
        }
        onClose={() => {}}
      />
    );
  }

  if (
    surveyBuilderPopup.type ===
    SURVEY_BUILDER_POPUP_TYPES.PROFILE_QUESTION_CATEGORY
  ) {
    return (
      <Popup
        component={
          <QuestionChoicesTagging
            api={api}
            actions={actions}
            surveyBuilderPopup={surveyBuilderPopup}
          />
        }
        onClose={() => {}}
      />
    );
  }

  if (
    surveyBuilderPopup.type ===
    SURVEY_BUILDER_POPUP_TYPES.MATRIX_QUESTION_EDIT_SCALE
  ) {
    return (
      <Popup
        component={
          <MatrixQuestionEditScale
            api={api}
            actions={actions}
            surveyBuilderPopup={surveyBuilderPopup}
          />
        }
        onClose={() => {}}
      />
    );
  }

  if (
    surveyBuilderPopup.type ===
    SURVEY_BUILDER_POPUP_TYPES.RESEARCH_COST_INCREASED
  ) {
    return (
      <Popup
        component={
          <ResearchCostIncreasedPopup
            onClick={surveyBuilderPopup.onClick}
            onClose={surveyBuilderPopup.onClose}
          />
        }
        onClose={() => {}}
      />
    );
  }

  if (
    surveyBuilderPopup.type ===
    SURVEY_BUILDER_POPUP_TYPES.MATRIX_OPTIONS_LIMIT_WARNING
  ) {
    return (
      <Popup
        component={
          <MatrixOptionsLimitWarning
            onClick={surveyBuilderPopup.onClick}
            onClose={surveyBuilderPopup.onClose}
          />
        }
        onClose={() => {}}
      />
    );
  }

  if (
    surveyBuilderPopup.type ===
    SURVEY_BUILDER_POPUP_TYPES.EXTRA_QUESTIONS_ABOVE_LICENCE_ADDED
  ) {
    return (
      <Popup
        component={
          <ExtraQuestions
            message="Please note that the number of questions exceeds your fair use policy, which may result in additional charges. For more information, please contact your BUFFL representative."
            onClose={() => actions.toggleSurveyBuilderPopup()}
          />
        }
        onClose={() => {}}
      />
    );
  }

  if (
    surveyBuilderPopup.type ===
    SURVEY_BUILDER_POPUP_TYPES.NO_LICENCE_MORE_THAN_15
  ) {
    return (
      <Popup
        component={
          <ExtraQuestions
            message="The length of your survey has negative impact on the quality of data and is unpleasant for respondents. With BUFFL, we want to encourage short surveys."
            onClose={() => actions.toggleSurveyBuilderPopup()}
          />
        }
        onClose={() => {}}
      />
    );
  }

  if (
    surveyBuilderPopup.type ===
    SURVEY_BUILDER_POPUP_TYPES.SURVEY_UNPUBLISHED_QUESTIONS_ADDED
  ) {
    return (
      <Popup
        component={
          <ExtraQuestions
            message="You have added / removed one or more survey questions. Please note that no budget will be debited or credited for this action."
            onClose={() => actions.toggleSurveyBuilderPopup()}
          />
        }
        onClose={() => {}}
      />
    );
  }

  return null;
};
