import {
  COMMUNITY_ACTION_STEP_NAMES,
  COMMUNITY_EVENT_STEP_NAMES,
  SEND_EMAIL_ACTION_TYPES
} from './constants';

export default communityAutomation => {
  let allowed =
    communityAutomation &&
    communityAutomation.name &&
    communityAutomation.name !== '';

  if (communityAutomation && communityAutomation.steps) {
    communityAutomation.steps.forEach(step => {
      if (allowed) {
        if (!step.stepName) {
          allowed = false;
        } else if (
          step.stepName === COMMUNITY_EVENT_STEP_NAMES.NOT_OPEN_APP_IN_X_DAYS &&
          (!step.amount1 || step.amount1 === '')
        ) {
          allowed = false;
        } else if (
          step.stepName ===
            COMMUNITY_EVENT_STEP_NAMES.USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS &&
          (!step.amount1 ||
            step.amount1 === '' ||
            !step.amount2 ||
            step.amount2 === '')
        ) {
          allowed = false;
        } else if (
          step.stepName === COMMUNITY_EVENT_STEP_NAMES.USER_RESULT_REWARDED &&
          (!step.amount1 || step.amount1 === '')
        ) {
          allowed = false;
        } else if (
          step.stepName ===
            COMMUNITY_EVENT_STEP_NAMES.USER_RECEIVES_BAD_ANSWER_FLAG &&
          (!step.amount1 || step.amount1 === '')
        ) {
          allowed = false;
        } else if (
          step.stepName ===
            COMMUNITY_EVENT_STEP_NAMES.USER_REPUTATION_DECREASES_TO_X &&
          (!(step.amount1 >= 0 && step.amount1 <= 100) || step.amount1 === '')
        ) {
          allowed = false;
        } else if (
          step.stepName ===
            COMMUNITY_EVENT_STEP_NAMES.USER_REPUTATION_INCREASES_TO_X &&
          (!(step.amount1 >= 0 && step.amount1 <= 100) || step.amount1 === '')
        ) {
          allowed = false;
        } else if (
          step.stepName ===
            COMMUNITY_ACTION_STEP_NAMES.SEND_PUSH_NOTIFICATION &&
          (!step.title ||
            step.title === '' ||
            !step.description ||
            step.description === '')
        ) {
          allowed = false;
        } else if (
          step.stepName === COMMUNITY_ACTION_STEP_NAMES.SEND_EMAIL &&
          (!step.title ||
            step.title === '' ||
            !step.option1 ||
            !step.option1.value ||
            step.option1.value === '' ||
            (!step.option2 ||
              !step.option2.value ||
              step.option2.value === '' ||
              (step.option2.value &&
                step.option2.value ===
                  SEND_EMAIL_ACTION_TYPES.OTHER_RECIPIENT &&
                (!step.email || step.email === ''))))
        ) {
          allowed = false;
        } else if (
          step.stepName === COMMUNITY_ACTION_STEP_NAMES.POPUP_MESSAGE &&
          (!step.title ||
            step.title === '' ||
            !step.description ||
            step.description === '' ||
            !step.submitText ||
            step.submitText === '')
        ) {
          allowed = false;
        } else if (
          step.stepName === COMMUNITY_ACTION_STEP_NAMES.GIVE_TOKENS &&
          (!step.title ||
            step.title === '' ||
            !step.amount3 ||
            step.amount3 === '')
        ) {
          allowed = false;
        } else if (
          step.stepName ===
            COMMUNITY_ACTION_STEP_NAMES.DECREASE_PROFILE_REPUTATION_WITH_X &&
          (!(step.amount1 >= 0 && step.amount1 <= 100) || step.amount1 === '')
        ) {
          allowed = false;
        } else if (
          step.stepName ===
            COMMUNITY_ACTION_STEP_NAMES.INCREASE_PROFILE_REPUTATION_WITH_X &&
          (!(step.amount1 >= 0 && step.amount1 <= 100) || step.amount1 === '')
        ) {
          allowed = false;
        }
      }
    });
  }

  return allowed;
};
