import React, { useEffect, useState } from 'react';

import tickIcon from '../../../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../../../assets/img/delete.svg';

import styles from './EditQuestionPopup.module.css';
import ColorPicker from '../../../../../../../../../surveys/components/SurveyBuilderPage/SurveyOverview/components/ColorPicker/ColorPicker';

export default ({
  onClose,
  editPopupQuestionId,
  graph,
  onSaveMergedGraphQuestionClick,
  parseQuestion,
  colorsPallete,
  mergedGraphQuestions
}) => {
  const [internalData, setInternalData] = useState({});

  const questionToEdit =
    graph &&
    graph.questions &&
    graph.questions.length &&
    graph.questions.find(
      q => q._id.toString() === editPopupQuestionId.toString()
    );
  const questionToEditIndex =
    graph &&
    graph.questions &&
    graph.questions.length &&
    graph.questions.findIndex(
      q => q._id.toString() === editPopupQuestionId.toString()
    );

  const mergedQuestionToEdit =
    mergedGraphQuestions &&
    mergedGraphQuestions.length &&
    questionToEdit &&
    questionToEdit.questionId &&
    mergedGraphQuestions.find(
      mGQ => mGQ.id.toString() === questionToEdit.questionId.toString()
    );

  useEffect(() => {
    const mergedGraphQuestionDetails = questionToEdit;

    const internalDataToSet = {};

    const questionColorCode =
      mergedGraphQuestionDetails &&
      mergedGraphQuestionDetails.colorCode &&
      mergedGraphQuestionDetails.colorCode !== ''
        ? mergedGraphQuestionDetails.colorCode
        : null;
    if (questionColorCode) {
      internalDataToSet.colorCode = questionColorCode;
    }

    const questionName =
      mergedGraphQuestionDetails &&
      mergedGraphQuestionDetails.name &&
      mergedGraphQuestionDetails.name !== ''
        ? mergedGraphQuestionDetails.name
        : null;
    if (questionName) {
      internalDataToSet.name = questionName;
    }

    setInternalData(internalDataToSet);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.templateTitle}>
        Edit legend
        <div className={styles.bottomLine} />
      </div>
      <div className={styles.feature}>
        <div className={styles.background} />
        <div className={styles.featureFields}>
          <div className={styles.colorPickersContainer}>
            <div className={styles.colorPicker}>
              <ColorPicker
                colorCode={
                  (internalData && internalData.colorCode) ||
                  colorsPallete[questionToEditIndex]
                }
                onChange={colorCode =>
                  setInternalData({
                    ...internalData,
                    colorCode
                  })
                }
                style={{
                  marginRight: 0,
                  borderColor: '#dfe0e4',
                  boxSizing: 'border-box'
                }}
                fixedPosition
              />
            </div>
          </div>
          <input
            className={styles.description}
            value={(internalData && internalData.name) || ''}
            onChange={e =>
              setInternalData({
                ...internalData,
                name: e.currentTarget.value
              })
            }
            placeholder={
              (internalData && internalData.name) ||
              (mergedQuestionToEdit &&
                parseQuestion(mergedQuestionToEdit.question))
            }
          />
        </div>
      </div>
      <div style={{ paddingTop: '8px' }}>
        <div className={styles.bottomLine} />
      </div>
      <div className={styles.footer}>
        <div className={styles.actions}>
          <img
            className={styles.confirm}
            src={tickIcon}
            alt="Confirm icon"
            onClick={() => onSaveMergedGraphQuestionClick(internalData)}
            role="presentation"
          />
          <img
            className={styles.close}
            src={closeIcon}
            alt="Close icon"
            onClick={() => onClose()}
            role="presentation"
          />
        </div>
      </div>
    </div>
  );
};
