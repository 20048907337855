import React, { useState, useEffect } from 'react';

import uuid from 'uuid/v1';
import AppBar from '../../../base/components/AppBar/AppBar';
import FlowBar from './FlowBar/FlowBar';

import Loader from './Loader/Loader';
import SurveyOverview from './SurveyOverview/SurveyOverview';
import SurveyBuilderContainer from './SurveyBuilder/SurveyBuilderContainer';
import TargetAudienceContainer from './TargetAudience/TargetAudienceContainer';
import TestSurvey from './TestSurvey/TestSurvey';
import PublishSurvey from './PublishSurvey/PublishSurvey';
import Popup from './Popup/Popup';
import SurveyUpdatedPopup from './SurveyUpdatedPopup/SurveyUpdatedPopup';

import BUFFLlogo from '../../../assets/img/f_animated_alpha.gif';

import SurveyBuilderPageContext from './SurveyBuilderPageContext';

import styles from './SurveyBuilderPage.module.css';
import useUserback from '../../../hooks/useUserback';
import useApi from '../../hooks/useApi';

const OVERVIEW_TAB = {
  name: 'overview',
  label: 'Overview'
};
const FLOW_BUILDER_TAB = {
  name: 'flow',
  label: 'Flow Builder'
};
const QUESTION_DESIGN_TAB = {
  name: 'question',
  label: 'Survey Builder'
};
const TARGET_AUDIENCE_TAB = {
  name: 'target',
  label: 'Target Audience'
};
const TEST_SURVEY_TAB = {
  name: 'test',
  label: 'Test Survey'
};
const PUBLISH_SURVEY_TAB = {
  name: 'publish',
  label: 'Publish Survey'
};
const TABS = [
  OVERVIEW_TAB,
  TARGET_AUDIENCE_TAB,
  QUESTION_DESIGN_TAB,
  TEST_SURVEY_TAB,
  PUBLISH_SURVEY_TAB
];

export default ({
  match,
  history,
  isAdmin,
  isProfilingTagsManager,
  isBusinessConfigurationManager,
  isAuthenticated,
  clientEmail,
  onLogout,
  webSocketUrl,
  surveysState,
  emptySurveyState,
  updateDecisionFramework,
  accountManagement,
  setSelectedAccountManagementRecord,
  defaultConstants
}) => {
  if (!isAuthenticated) {
    return null;
  }

  useUserback({
    survey: match.params.id,
    client: clientEmail
  });

  const [tab, setTab] = useState(
    (() => {
      if (match && match.params) {
        if (match.params.tab) {
          switch (match.params.tab) {
            case 'question':
              return QUESTION_DESIGN_TAB;
            case 'target':
              return TARGET_AUDIENCE_TAB;
            case 'test':
              return TEST_SURVEY_TAB;
            case 'publish':
              return PUBLISH_SURVEY_TAB;
            default:
              return OVERVIEW_TAB;
          }
        }
      }

      return OVERVIEW_TAB;
    })()
  );

  const [surveyPageRef, setSurveyPageRef] = useState(null);
  const [webSocketSessionId, setWebSocketSessionId] = useState(null);

  const [
    api,
    survey,
    loading,
    surveyLoading,
    surveyRefetch,
    surveyAlreadyUpdatedSocket
  ] = useApi(match.params.id, webSocketUrl, webSocketSessionId);

  useEffect(() => {
    setWebSocketSessionId(uuid());
  }, []);

  const onGoToPortalPage = async () => {
    if (
      surveysState &&
      surveysState.activeFlow &&
      tab === QUESTION_DESIGN_TAB &&
      !surveysState.activeFlow.flows
    ) {
      await api('updateQuestion', {
        question: surveysState.activeFlow
      });
      if (
        surveysState.activeFlow &&
        surveysState.activeFlow.matrix &&
        surveysState.activeFlow.matrix.decisionFrameworkType
      ) {
        await updateDecisionFramework(api, surveysState.activeFlow);
      }
    }

    emptySurveyState();

    history.push('/');
  };

  const setActiveTab = async activeTab => {
    if (
      surveysState &&
      surveysState.activeFlow &&
      tab === QUESTION_DESIGN_TAB
    ) {
      if (!surveysState.activeFlow.flows) {
        await api('updateQuestion', {
          question: surveysState.activeFlow
        });
        if (
          surveysState.activeFlow &&
          surveysState.activeFlow.matrix &&
          surveysState.activeFlow.matrix.decisionFrameworkType
        ) {
          await updateDecisionFramework(api, surveysState.activeFlow);
        }
      }

      emptySurveyState();
    }

    history.push(`/survey/${survey.id}/edit/${activeTab.name}/`);
    if (activeTab.name !== PUBLISH_SURVEY_TAB.name) {
      await surveyRefetch();
    }
    setTab(activeTab);
  };

  if (surveyAlreadyUpdatedSocket) {
    return <Popup component={<SurveyUpdatedPopup />} />;
  }

  return (
    <SurveyBuilderPageContext.Provider
      value={{
        api,
        survey,
        isAdmin,
        surveyRefetch,
        history,
        accountManagement
      }}
    >
      <AppBar
        clientEmail={clientEmail}
        history={history}
        isAdmin={isAdmin}
        isProfilingTagsManager={isProfilingTagsManager}
        isBusinessConfigurationManager={isBusinessConfigurationManager}
        isAuthenticated={isAuthenticated}
        isFetching={false}
        appBarProperties={{
          routeSubtitle: survey && (survey.projectName || survey.name)
        }}
        onGoToPortalPage={onGoToPortalPage}
        onLogout={onLogout}
        accountManagement={accountManagement}
        setSelectedAccountManagementRecord={setSelectedAccountManagementRecord}
      />
      <div className={styles.flowContainer} ref={r => setSurveyPageRef(r)}>
        <div className={styles.flow}>
          <FlowBar
            flowBarTabs={TABS}
            activeTab={tab}
            setActiveTab={setActiveTab}
          />

          {tab !== FLOW_BUILDER_TAB && <div style={{ height: 128 }} />}

          {loading || (surveysState && surveysState.customLoading) ? (
            <Loader />
          ) : null}
          {surveyLoading || !survey ? (
            <div className={styles.BUFFLLoaderContainer}>
              <img
                src={BUFFLlogo}
                alt="The BUFFL Logo"
                className={styles.BUFFLLoader}
              />
            </div>
          ) : (
            <>
              {tab === OVERVIEW_TAB && (
                <SurveyOverview defaultConstants={defaultConstants} />
              )}
              {tab === QUESTION_DESIGN_TAB && (
                <SurveyBuilderContainer
                  match={match}
                  history={history}
                  surveyPageRef={surveyPageRef}
                  survey={survey}
                  webSocketUrl={webSocketUrl}
                  webSocketSessionId={webSocketSessionId}
                  isAdmin={isAdmin}
                  api={api}
                  isProfilingTagsManager={isProfilingTagsManager}
                  accountManagement={accountManagement}
                />
              )}
              {tab === TARGET_AUDIENCE_TAB && (
                <TargetAudienceContainer
                  api={api}
                  match={match}
                  history={history}
                  TARGET_AUDIENCE_TAB={TARGET_AUDIENCE_TAB}
                  accountManagement={accountManagement}
                  defaultConstants={defaultConstants}
                />
              )}
              {tab === TEST_SURVEY_TAB && <TestSurvey />}
              {tab === PUBLISH_SURVEY_TAB && (
                <PublishSurvey surveyId={survey.id} />
              )}
            </>
          )}
        </div>
      </div>
    </SurveyBuilderPageContext.Provider>
  );
};
