import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import useLazyQuery from '../../../../../../surveys/hooks/useLazyQuery';

import styles from './InsightsPopup.module.css';
import Loader from '../../../../Loader/Loader';
import tickIcon from '../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../assets/img/delete.svg';
import {
  CREATE_INSIGHT,
  UPDATE_INSIGHT
} from '../../../../../../graphql/InsightsData';
import { GET_SURVEY_FRAMEWORKS } from '../../../../../../graphql/Survey';

import FiltersBar from '../../../Blocks/FiltersBar/FiltersBar';
import { insightQuestionTypes } from '../../helpers/constants';

import { getNestedFiltersFromActiveFilters } from '../../../../../../helpers/filtering';
import DataPoint from './components/DataPoint/DataPoint';
import addIcon from '../../../../../../assets/img/add.svg';
import draggingIcon from '../../../../../../assets/img/dragging-icon.svg';

export default ({
  survey,
  internalData,
  setInternalData,
  surveyRefetch,
  activeFilters,
  filtersRelation,
  sortedQuestions,
  showAddInsightsPopup,
  setShowAddInsightsPopup,
  resultBlocks,
  stats,
  parseQuestion,
  getQuestionIcon,
  activeSorting,
  activeFilteringOnSelection,
  activePresentationMode,
  activeChartView,
  surveyGraphs,
  getSurveyGraphs,
  viewToken
}) => {
  const dataPointsContainerRef = useRef();
  const [
    dataPointsContainerScrollingDisabled,
    setDataPointsContainerScrollingDisabled
  ] = useState(false);
  const scrollingDisabledRef = useRef(dataPointsContainerScrollingDisabled);

  const insightsPopupRef = useRef();
  const insightsPopupDraggingIconRef = useRef();

  const [createInsight, { loading: isCreateInsightLoading }] = useMutation(
    CREATE_INSIGHT
  );
  const [updateInsight, { loading: isSaveInsightLoading }] = useMutation(
    UPDATE_INSIGHT
  );
  const searchSurvey = useLazyQuery(GET_SURVEY_FRAMEWORKS, {
    fetchPolicy: 'no-cache'
  });

  const isLoading = isCreateInsightLoading || isSaveInsightLoading;

  useEffect(
    () => {
      const onFeaturesContainerScroll = e => {
        const isDropdownPopup =
          e &&
          e.target &&
          e.target.className &&
          e.target.className.substring(0, 13) &&
          e.target.className.substring(0, 13) === 'DropdownPopup'; // hacky, but I do not see a better way to solve it now

        if (!isDropdownPopup && scrollingDisabledRef.current) {
          e.preventDefault();
        }
      };

      document.addEventListener('wheel', onFeaturesContainerScroll, {
        passive: false
      });
      return () => {
        document.removeEventListener('wheel', onFeaturesContainerScroll, {
          passive: false
        });
      };
    },
    [dataPointsContainerRef]
  );

  useEffect(
    () => {
      if (showAddInsightsPopup && showAddInsightsPopup.baseQuestionId) {
        setInternalData({
          ...internalData,
          filters: {
            activeFilters,
            filterRelation: filtersRelation
          },
          questions: [
            {
              questionId: showAddInsightsPopup.baseQuestionId,
              type: showAddInsightsPopup.baseQuestionType
            }
          ]
        });
      }
    },
    [showAddInsightsPopup]
  );

  let offsetX;
  let offsetY;

  useEffect(
    () => {
      if (
        insightsPopupDraggingIconRef &&
        insightsPopupDraggingIconRef.current &&
        insightsPopupRef &&
        insightsPopupRef.current
      ) {
        insightsPopupDraggingIconRef.current.addEventListener(
          'mousedown',
          e => {
            // Calculate the offset position
            offsetX =
              e.clientX - insightsPopupRef.current.getBoundingClientRect().left;
            offsetY =
              e.clientY - insightsPopupRef.current.getBoundingClientRect().top;

            // Add event listeners to the document for mousemove and mouseup
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
          }
        );

        const mouseMoveHandler = e => {
          // Update the position of the draggable element
          insightsPopupRef.current.style.left = `${e.clientX - offsetX}px`;
          insightsPopupRef.current.style.top = `${e.clientY - offsetY}px`;
          insightsPopupRef.current.style.position = 'absolute'; // Set position to absolute
        };

        const mouseUpHandler = () => {
          // Remove event listeners when mouse is released
          document.removeEventListener('mousemove', mouseMoveHandler);
          document.removeEventListener('mouseup', mouseUpHandler);
        };
      }
    },
    [insightsPopupDraggingIconRef]
  );

  const transformActiveState = (questions, activeState, property = null) =>
    questions.reduce((activeStateArray, q) => {
      if (activeState[q.questionId]) {
        return [
          ...activeStateArray,
          {
            questionId: q.questionId,
            ...(property
              ? { [property]: activeState[q.questionId][property] }
              : { active: activeState[q.questionId].active })
          }
        ];
      }
      return activeStateArray;
    }, []);

  const onConfirmClick = async () => {
    const insightData = { ...internalData };
    insightData.survey = survey.id;

    //  Question filter value needs to be transformed into string from integer
    if (
      insightData &&
      insightData.filters &&
      insightData.filters.activeFilters &&
      insightData.filters.activeFilters.length
    ) {
      insightData.filters.activeFilters = insightData.filters.activeFilters.map(
        aF => {
          const newAF = { ...aF }; // Copy immutably to not alter active filter state

          if (newAF && newAF.valuePath === 'answer') {
            newAF.value = newAF.value.toString();
          }

          return newAF;
        }
      );
    }

    // Add
    if (showAddInsightsPopup && showAddInsightsPopup.baseQuestionId) {
      if (activeSorting && activeSorting.length) {
        insightData.questionSettings = {
          ...(insightData.questionSettings || {}),
          activeSorting
        };
      }

      if (activeFilteringOnSelection) {
        const filteringOnSelection = transformActiveState(
          insightData.questions,
          activeFilteringOnSelection
        );
        if (filteringOnSelection && filteringOnSelection.length) {
          insightData.questionSettings = {
            ...(insightData.questionSettings || {}),
            activeFilteringOnSelection: filteringOnSelection
          };
        }
      }

      if (activePresentationMode) {
        const presentationMode = transformActiveState(
          insightData.questions,
          activePresentationMode
        );
        if (presentationMode && presentationMode.length) {
          insightData.questionSettings = {
            ...(insightData.questionSettings || {}),
            activePresentationMode: presentationMode
          };
        }
      }

      if (activeChartView) {
        const chartView = transformActiveState(
          insightData.questions,
          activeChartView,
          'chartViewName'
        );
        if (chartView && chartView.length) {
          insightData.questionSettings = {
            ...(insightData.questionSettings || {}),
            activeChartView: chartView
          };
        }
      }

      // Check if there are graphs selected
      const insightGraphQuestions =
        insightData &&
        insightData.questions &&
        insightData.questions.filter(
          q => q.type === insightQuestionTypes.TEMPLATE
        );
      if (insightGraphQuestions && insightGraphQuestions.length) {
        const insightGraphIds = insightGraphQuestions.map(
          iGQ => iGQ.questionId
        );
        const selectedSurveyGraphs = surveyGraphs.filter(
          sG => insightGraphIds.indexOf(sG.id) > -1
        );

        if (selectedSurveyGraphs && selectedSurveyGraphs.length) {
          const refetchedSurvey = await searchSurvey({
            variables: {
              id: survey.id,
              ...(viewToken ? { viewToken } : {})
            }
          });

          if (
            refetchedSurvey &&
            refetchedSurvey.data &&
            refetchedSurvey.data.survey &&
            refetchedSurvey.data.survey.survey
          ) {
            const latestSurveyGraphs = getSurveyGraphs(
              refetchedSurvey.data.survey.survey
            );

            const graphFeatures = latestSurveyGraphs.map(sSG => ({
              graphId: sSG.id,
              featuresVisibility:
                sSG && sSG.features
                  ? sSG.features.map(f => ({
                      featureId: f.id,
                      active: f.active
                    }))
                  : [],
              unFilteredFeaturesVisibility:
                sSG && sSG.unFilteredFeatures
                  ? sSG.unFilteredFeatures.map(f => ({
                      featureId: f.id,
                      active: f.active
                    }))
                  : [],
              hiddenQuestionIndexes:
                sSG &&
                sSG.hiddenQuestionIndexes &&
                sSG.hiddenQuestionIndexes.length
                  ? sSG.hiddenQuestionIndexes
                  : [],
              activeLoversHaters:
                showAddInsightsPopup && showAddInsightsPopup.activeLoversHaters
                  ? showAddInsightsPopup.activeLoversHaters
                  : null
            }));

            insightData.questionSettings = {
              ...(insightData.questionSettings || {}),
              graphFeatures
            };
          }
        }
      }

      await createInsight({
        variables: insightData
      });
    }

    // Update
    if (showAddInsightsPopup && showAddInsightsPopup.insightId) {
      insightData.id = insightData._id;

      delete insightData.__typename;
      delete insightData.filters.__typename;
      if (
        insightData.filters &&
        insightData.filters.activeFilters &&
        insightData.filters.activeFilters.length
      ) {
        insightData.filters.activeFilters.forEach(aF => {
          const newAF = aF;
          delete newAF.__typename;
        });
      }
      if (insightData.questions && insightData.questions.length) {
        insightData.questions.forEach(q => {
          const nQ = q;
          delete nQ.__typename;
        });
      }

      // No need to update these
      if (insightData && insightData.questionSettings) {
        delete insightData.questionSettings;
      }

      await updateInsight({
        variables: insightData
      });
    }

    setShowAddInsightsPopup(false);
    setInternalData({});
    surveyRefetch();
  };

  const dropdownOptions = useMemo(
    () => {
      let options = [...(surveyGraphs || [])];

      const processedQuestions = sortedQuestions.reduce((acc, sQ) => {
        const questionText =
          sQ && sQ.type === 'Matrix' && sQ.matrix.xQuestion
            ? `${parseQuestion(sQ.matrix.xQuestion)} ${parseQuestion(
                sQ.matrix.yQuestion
              )}`
            : parseQuestion(sQ.question);

        if (sQ.type !== 'Distributor') {
          return [
            ...acc,
            {
              formattedGlobalIndex: sQ.formattedGlobalIndex,
              id: sQ.id,
              question: questionText,
              icon: getQuestionIcon(sQ),
              optionType: insightQuestionTypes.QUESTION
            }
          ];
        }
        return acc;
      }, []);

      options = [...options, ...processedQuestions];

      // Remove base question as it is already selected // TODO
      // return options.filter(o => baseQuestion && o.id !== baseQuestion.id);
      return options;
    },
    [sortedQuestions, internalData.questions]
  );

  const isValidToComplete = () => {
    if (
      !internalData ||
      !internalData.questions ||
      !internalData.questions.length
    ) {
      return false;
    }

    if (internalData.questions.some(q => !q.questionId)) {
      return false;
    }

    return internalData && internalData.name && internalData.name !== '';
  };

  const setDataPointsScrollingDisabled = value => {
    scrollingDisabledRef.current = value;
    setDataPointsContainerScrollingDisabled(value);
  };

  const scrollDataPointsContainerToTheBottom = () => {
    if (dataPointsContainerRef && dataPointsContainerRef.current) {
      dataPointsContainerRef.current.scrollTop = 10000; // hacky but works
    }
  };

  const onAddDataPointClick = () => {
    setInternalData({
      ...internalData,
      questions: [
        ...internalData.questions,
        {
          questionId: null,
          type: null
        }
      ]
    });

    setTimeout(() => {
      scrollDataPointsContainerToTheBottom();
    }, 100);
  };

  const onRemoveDataPointClick = index => {
    let newQuestions = [...internalData.questions];

    newQuestions = newQuestions.filter((nQ, nQi) => index !== nQi);

    setInternalData({
      ...internalData,
      questions: newQuestions
    });
  };

  return (
    <div className={styles.backgroundContainer}>
      <div
        className={styles.popupContainer}
        role="presentation"
        onClick={e => e.stopPropagation()}
        ref={insightsPopupRef}
      >
        <div
          className={styles.draggingIcon}
          style={{ backgroundImage: `url(${draggingIcon})` }}
          ref={insightsPopupDraggingIconRef}
          draggable="false"
        />
        <div className={styles.popupContent}>
          <div className={styles.title}>
            {internalData && internalData._id ? 'Edit' : 'Add'} insight
          </div>

          <div className={styles.subtitle}>
            What did you want to learn or validate?
          </div>
          <div className={styles.scopingDescription}>
            {(survey &&
              survey.scoping &&
              survey.scoping.assumptionToValidate) ||
              '/'}
          </div>

          <div className={styles.subtitle}>What are your actual insights?</div>
          <div className={styles.fieldsContainer}>
            <input
              onChange={e => {
                setInternalData({ ...internalData, name: e.target.value });
              }}
              value={(internalData && internalData.name) || ''}
              className={styles.inputElement}
              placeholder="Write down 1 insight related to what you wanted to learn in this survey."
            />
          </div>

          {internalData &&
          internalData.filters &&
          internalData.filters.activeFilters &&
          internalData.filters.activeFilters.length ? (
            <div className={styles.filtersContainer}>
              <div className={styles.subtitle}>
                Segment (N = {stats.responses})
              </div>
              <div className={styles.filtersBarWrapper}>
                <FiltersBar
                  activeFilters={
                    (internalData &&
                      internalData.filters &&
                      internalData.filters.activeFilters) ||
                    []
                  }
                  filtersRelation={
                    (internalData &&
                      internalData.filters &&
                      internalData.filters.filterRelation) ||
                    'and'
                  }
                  nestedFilters={getNestedFiltersFromActiveFilters(
                    internalData.filters.activeFilters
                  )}
                  campaign={survey}
                  showFiltersOnly
                  sortedQuestions={sortedQuestions}
                  resultBlocks={resultBlocks}
                />
              </div>
            </div>
          ) : null}

          <div className={styles.subtitle}>
            This insight is based on following data point(s)
          </div>
          <div
            className={styles.dataPointsContainer}
            ref={dataPointsContainerRef}
          >
            <div className={styles.singleDataPointsContainer}>
              {internalData &&
                internalData.questions &&
                internalData.questions.map((question, index) => (
                  <DataPoint
                    question={question}
                    setQuestionData={newQuestionData => {
                      // const newQuestions = [...internalData.questions];

                      const newQuestions = internalData.questions;
                      newQuestions[index] = { ...newQuestionData };

                      setInternalData({
                        ...internalData,
                        questions: newQuestions
                      });
                    }}
                    dropdownOptions={dropdownOptions}
                    setDataPointsScrollingDisabled={
                      setDataPointsScrollingDisabled
                    }
                    onRemoveDataPointClick={onRemoveDataPointClick}
                    index={index}
                  />
                ))}
            </div>
          </div>
          <div
            className={styles.addFeatureButton}
            role="presentation"
            onClick={onAddDataPointClick}
          >
            <img className={styles.addIcon} src={addIcon} alt="Add icon" />
            <span>Add data point</span>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.details} />
          <div className={styles.actions}>
            {!isLoading ? (
              <img
                className={
                  isValidToComplete()
                    ? styles.confirm
                    : `${styles.confirm} ${styles.disabledConfirm}`
                }
                src={tickIcon}
                alt="Confirm icon"
                onClick={() => {
                  if (isValidToComplete()) {
                    onConfirmClick();
                  }
                }}
                role="presentation"
              />
            ) : null}
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <Loader size="small" />
              </div>
            ) : null}
            <img
              className={styles.close}
              src={closeIcon}
              alt="Close icon"
              onClick={() => {
                setShowAddInsightsPopup(false);
                setInternalData({});
              }}
              role="presentation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
