import React from 'react';
import { withRouter } from 'react-router-dom';

import { version } from '../../../../../package.json';
import bufflLogoWhiteSmall from '../../../assets/img/buffl-logo-white-small.png';
import bLogoWhiteSmall from '../../../assets/img/b-logo-white-small.png';

import clientIcon from '../../../assets/img/client_icon.svg';
import whiteArrowDown from '../../../assets/img/white-arrow-down.svg';
import styles from './AppBar.module.css';
import AccountManagementRecordSelect from '../AccountManagementRecordSelect/AccountManagementRecordSelect';
/* global localStorage */

const onSuccess = history => {
  history.push('/');
};

const backend = localStorage.getItem('backend')
  ? localStorage.getItem('backend')
  : '';

const BufflBar = ({
  clientEmail,
  history,
  isAuthenticated,
  onGoToPortalPage,
  onLogout,
  path,
  isFetching,
  appBarProperties,
  onSearchCampaigns,
  builder = false,
  isAdmin,
  isProfilingTagsManager,
  isBusinessConfigurationManager,
  accountManagement,
  setSelectedAccountManagementRecord
}) => {
  const hasActionButtons =
    appBarProperties &&
    appBarProperties.buttons &&
    appBarProperties.buttons.length > 0;

  const logoPart = (
    <div
      className={
        backend ? `${styles.logoPart} ${styles.backend}` : styles.logoPart
      }
    >
      <img
        src={onGoToPortalPage ? bLogoWhiteSmall : bufflLogoWhiteSmall}
        className={styles.bufflLogoWhiteSmall}
        role="presentation"
        onClick={() => {
          if (isAuthenticated) {
            if (history.location.pathname === '/') {
              onSearchCampaigns('');
            } else {
              onGoToPortalPage(onSuccess, history);
            }
          }
          return false;
        }}
        alt="BUFFL logo"
      />
      <span className={styles.bufflVersion}>v{version}</span>
      {backend ? (
        <span className={styles.bufflVersion}> - {backend}</span>
      ) : null}
    </div>
  );

  return (
    <header
      className={`${styles.appBarContainer} ${
        builder ? styles.appBarBuilder : ''
      } ${
        appBarProperties && appBarProperties.absolutePosition
          ? styles.appBarAbsolute
          : ''
      }`}
    >
      <div className={styles.appBarLeft}>
        {path === '/' || !isAuthenticated ? (
          <div
            className={`${
              styles.appBarShortLogoContainer
            } app-bar-short-logo-container`}
          >
            {logoPart}
          </div>
        ) : (
          <div
            className={`${
              styles.appBarShortLogoContainer
            } app-bar-short-logo-container`}
          >
            {logoPart}
            {onGoToPortalPage &&
            !(appBarProperties && appBarProperties.routeTitle) ? (
              <button
                className={`${styles.myCampaignsAction} my-campaigns-action`}
                type="button"
                onClick={() => {
                  onGoToPortalPage(onSuccess, history);
                  return false;
                }}
              >
                &#x3c; My Surveys
              </button>
            ) : null}
            {appBarProperties && appBarProperties.routeTitle && !isFetching
              ? appBarProperties.routeTitle
              : null}
            {appBarProperties &&
            appBarProperties.routeSubtitle &&
            !isFetching ? (
              <span
                className={`${styles.routeSubtitleName} route-subtitle-name ${
                  !hasActionButtons ? 'route-subtitle-name-extended' : ''
                }`}
              >
                / {appBarProperties.routeSubtitle}
              </span>
            ) : null}
          </div>
        )}
      </div>
      <div className={`${styles.appBarRight} app-bar-right`}>
        {accountManagement &&
        accountManagement.multipleAccountManagementRecordsToSelect ? (
          <div
            className={`${
              styles.appBarButtonsContainer
            } app-bar-buttons-container`}
          >
            <AccountManagementRecordSelect
              accountManagement={accountManagement}
              onSelect={setSelectedAccountManagementRecord}
              hideNoValueOption={accountManagement && !isAdmin}
              fixedLeftPadding={200}
              isAdmin={isAdmin}
            />
          </div>
        ) : null}
        {isAuthenticated ? (
          <div className={styles.authContainer}>
            <img src={clientIcon} alt="Client icon" />
            <img src={whiteArrowDown} alt="Arrow down" height="5" />
            <div className={styles.dropdownContent}>
              <div className={styles.clientEmail}>{clientEmail}</div>
              {isAdmin && (
                <div
                  className={styles.clientLogout}
                  onClick={() => history.push('/coupons')}
                  role="presentation"
                >
                  Coupon Management
                </div>
              )}
              {isProfilingTagsManager ? (
                <div
                  className={styles.clientLogout}
                  onClick={() => history.push('/profile-tags-management')}
                  role="presentation"
                >
                  Profile Tags Management
                </div>
              ) : null}
              {isAdmin ? (
                <div
                  className={styles.clientLogout}
                  onClick={() => history.push('/feasibility-checker')}
                  role="presentation"
                >
                  Feasibility Checker
                </div>
              ) : null}
              {isAdmin ? (
                <div
                  className={styles.clientLogout}
                  onClick={() => history.push('/community-dashboard')}
                  role="presentation"
                >
                  Community Dashboard
                </div>
              ) : null}
              {isAdmin ? (
                <div
                  className={styles.clientLogout}
                  onClick={() => history.push('/community-automations')}
                  role="presentation"
                >
                  Community Automations
                </div>
              ) : null}
              {isBusinessConfigurationManager ? (
                <div
                  className={styles.clientLogout}
                  onClick={() => history.push('/business-configuration')}
                  role="presentation"
                >
                  Business Challenge Configuration
                </div>
              ) : null}
              <div
                className={styles.clientLogout}
                onClick={() => history.push('/account-management')}
                role="presentation"
              >
                Account Management
              </div>
              {isAdmin ? (
                <>
                  <div
                    className={styles.clientLogout}
                    onClick={() => history.push('/notifications/push')}
                    role="presentation"
                  >
                    Communication center
                  </div>
                  <div
                    className={styles.clientLogout}
                    onClick={() => history.push('/acquisition-metrics')}
                    role="presentation"
                  >
                    Acquisition Metrics
                  </div>
                </>
              ) : null}
              <div
                className={styles.clientLogout}
                onClick={() => {
                  onLogout();
                  if (history) {
                    history.push('/auth/login');
                  }
                }}
                role="presentation"
              >
                Logout
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </header>
  );
};

export default withRouter(BufflBar);
