import * as actions from './actions';

const loginFormFields = ['global', 'email', 'password', 'invitationToken'];
const registrationFormFields = [
  'global',
  'email',
  'password',
  'password2',
  'betaKey',
  'company',
  'telephone',
  'firstname',
  'lastname',
  'howDidYouLearnAboutBuffl'
];

const initialState = {
  login: {
    fields: loginFormFields.reduce(
      (acc, field) => ({
        ...acc,
        ...{
          [field]: {
            value: '',
            error: ''
          }
        }
      }),
      {}
    )
  },
  register: {
    fields: registrationFormFields.reduce(
      (acc, field) => ({
        ...acc,
        ...{
          [field]: {
            value: '',
            error: ''
          }
        }
      }),
      {}
    )
  },
  isAuthenticationProcessing: null,
  isAuthenticated: null,
  isAdmin: false,
  isProcessing: false,
  isCompleted: false
};

const resetErrors = state => ({
  ...state,
  login: {
    ...state.login,
    fields: Object.keys(state.login.fields).reduce(
      (acc, field) => ({
        ...acc,
        [field]: {
          value: state.login.fields[field].value,
          error: ''
        }
      }),
      {}
    )
  },
  register: {
    ...state.register,
    fields: Object.keys(state.register.fields).reduce(
      (acc, field) => ({
        ...acc,
        [field]: {
          value: state.register.fields[field].value,
          error: ''
        }
      }),
      {}
    )
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_CREDENTIALS:
      return {
        ...state,
        isAuthenticationProcessing: true,
        beforeLogin: action.beforeLogin
      };
    case actions.SET_CREDENTIALS: {
      return {
        ...state,
        isAuthenticationProcessing: false,
        isAuthenticated: action.isAuthenticated,
        isAdmin: action.isAdmin,
        clientEmail: action.clientEmail,
        clientId: action.clientId,
        clientStarredCampaigns: action.clientStarredCampaigns,
        isProfilingTagsManager: action.isProfilingTagsManager,
        isBusinessConfigurationManager: action.isBusinessConfigurationManager,
        accountManagement: action.accountManagement,
        defaultConstants: action.defaultConstants
      };
    }
    case actions.CHANGE_LOGIN_FIELD:
      return {
        ...state,
        login: {
          ...state.login,
          fields: {
            ...state.login.fields,
            [action.field]: {
              value: action.value,
              error: ''
            }
          }
        }
      };

    case actions.LOGIN_REQUESTED:
      return resetErrors({
        ...state,
        login: {
          ...state.login
        },
        isProcessing: true,
        isCompleted: false
      });

    case actions.LOGIN_SUCCEEDED:
      return {
        ...state,
        login: {
          ...state.login
        },
        accountManagement: {
          ...state.accountManagement,
          selectedAccountManagementRecord: null
        },
        isAuthenticated: true,
        isAuthenticationProcessing: null,
        isProcessing: false,
        isCompleted: true
      };

    case actions.LOGIN_FAILED:
      return {
        ...state,
        login: {
          ...state.login,
          errors: action.errors
        },
        isAuthenticated: false,
        isAdmin: false,
        isProcessing: false,
        isCompleted: false
      };

    case actions.LOGOUT:
      return JSON.parse(JSON.stringify(initialState));

    case actions.CHANGE_REGISTRATION_FIELD:
      return {
        ...state,
        register: {
          ...state.register,
          fields: {
            ...state.register.fields,
            [action.field]: {
              value: action.value,
              error: ''
            }
          }
        }
      };

    case actions.REGISTER_REQUESTED:
      return resetErrors({
        ...state,
        register: {
          ...state.register
        },
        isProcessing: true,
        isCompleted: false
      });

    case actions.REGISTER_SUCCEEDED:
      return {
        ...state,
        register: {
          ...state.register
        },
        isProcessing: false,
        isCompleted: true
      };

    case actions.REGISTER_FAILED:
      return {
        ...state,
        register: {
          ...state.register,
          errors: action.errors
        },
        isProcessing: false,
        isCompleted: false
      };

    case actions.SHOW_ERRORS:
      return {
        ...state,
        register: {
          ...state.register,
          errors: [{ detail: action.detail }]
        }
      };

    case actions.SET_CLIENT_STARRED_SURVEY: {
      if (action && action.campaign && action.campaign.id) {
        if (action.star) {
          return {
            ...state,
            clientStarredCampaigns: [
              ...state.clientStarredCampaigns,
              action.campaign.id
            ]
          };
        }
        if (!action.star) {
          const filteredStarredSurveys = state.clientStarredCampaigns.filter(
            s => s !== action.campaign.id
          );

          return {
            ...state,
            clientStarredCampaigns: filteredStarredSurveys
          };
        }
      }

      return {
        ...state
      };
    }

    case actions.SET_SELECTED_ACCOUNT_MANAGEMENT_RECORD: {
      if (action && action.record) {
        return {
          ...state,
          accountManagement: {
            ...state.accountManagement,
            selectedAccountManagementRecord: action.record
          }
        };
      }
      break;
    }

    default:
      return state;
  }
  return null;
};
