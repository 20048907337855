import React, { useState } from 'react';

import AppBar from '../../../../base/components/AppBar/AppBar';
import GoalsTable from './components/ConfigurationTableTypes/GoalsTable';
import ChallengesTable from './components/ConfigurationTableTypes/ChallengesTable';
import Popup from '../../../../campaigns/components/Popup';
import ConfigurationPopup from '../ConfigurationPopup/ConfigurationPopup';

import styles from './ConfigurationListPage.module.css';

const ConfigurationListPage = props => {
  const { recordsPerPage } = props;

  const [errors, setErrors] = useState([]);
  const [activePopup, setActivePopup] = useState(null);

  return (
    <div>
      <AppBar {...props} />
      <div className={styles.container}>
        <div className={styles.title}>Business challenge configuration</div>
        {errors && errors.length ? (
          <div className={styles.errorsContainer}>
            {errors.map(e => (
              <div key={e} className={styles.error}>
                {e}
              </div>
            ))}
          </div>
        ) : null}
        <GoalsTable
          recordsPerPage={recordsPerPage}
          setActivePopup={setActivePopup}
          errors={errors}
          setErrors={setErrors}
        />
        <ChallengesTable
          recordsPerPage={recordsPerPage}
          setActivePopup={setActivePopup}
          errors={errors}
          setErrors={setErrors}
        />
      </div>
      {activePopup ? (
        <Popup
          component={
            <ConfigurationPopup
              parent={null}
              invitationParent={null}
              record={activePopup.record}
              title={activePopup.title}
              description={activePopup.description}
              fields={activePopup.fields}
              onSubmit={(r, previousRecord) =>
                activePopup.onSubmit(r, previousRecord)
              }
              onClose={() => setActivePopup(null)}
              submitLoading={false}
              setActivePopup={setActivePopup}
              validate={r => activePopup.validate(r)}
            />
          }
          customStyles={{ padding: 'none' }}
          onClose={() => {}}
        />
      ) : null}
    </div>
  );
};

export default ConfigurationListPage;
