import React, { useState, useEffect, useRef } from 'react';

import api from '../../../../../../api';

import Dropdown from '../../../SurveyOverview/components/Consultancy/components/Dropdown/Dropdown';

import styles from './SurveySelector.module.css';

export default props => {
  const {
    amSurveyRecord,
    initialSurveys,
    surveyId,
    selectedSurveys,
    setSelectedSurveys
  } = props;
  const [loadingSelectedSurveys, setLoadingSelectedSurveys] = useState(true);
  const [topPosition, setTopPosition] = useState(-1000); // hacky but works
  const [leftPosition, setLeftPosition] = useState(-1000); // hacky but works

  const dropdownRef = useRef(null);

  const fetchSelectedSurveys = async selectedSurveysIds => {
    const campaignsList = await api().getCampaigns(
      amSurveyRecord,
      0,
      1,
      `&campaignFilter[showIds]=${selectedSurveysIds.join(',')}`
    );
    setSelectedSurveys(
      campaignsList.campaigns.map(option => ({
        value: option.id,
        label: option.name
      }))
    );
    setLoadingSelectedSurveys(false);
  };

  useEffect(() => {
    if (initialSurveys && initialSurveys.length) {
      fetchSelectedSurveys(initialSurveys);
    } else {
      setLoadingSelectedSurveys(false);
    }

    if (dropdownRef && dropdownRef.current) {
      const height = dropdownRef.current.offsetHeight;
      const rect = dropdownRef.current.getBoundingClientRect();
      const win = dropdownRef.current.ownerDocument.defaultView;

      setTopPosition(rect.top + win.pageYOffset - window.scrollY + height);
      setLeftPosition(rect.left + win.pageXOffset - window.scrollX);
    }
  }, []);

  const defaultOption = {
    value: '-',
    label: '-'
  };

  return (
    <div className={styles.container}>
      <div className={styles.description}>This survey is a translation of</div>
      <div ref={dropdownRef}>
        <Dropdown
          selectedValue={
            selectedSurveys &&
            selectedSurveys.length &&
            selectedSurveys[0].label
              ? selectedSurveys[0].label
              : null
          }
          loadingSelectedValue={loadingSelectedSurveys}
          onSelectCompleted={(value, label) => {
            if (value === '-') {
              setSelectedSurveys([]);
            } else {
              setSelectedSurveys([{ value, label }]);
            }
          }}
          searchEnabled
          fetchOptions={async (page, search) => {
            // Do not include this survey when searching
            let queryFilter = `&campaignFilter[excludeIds]=${surveyId}`;
            if (search && search.length > 0) {
              queryFilter += `&campaignFilter[campaignNameSearch]=${search}&campaignFilter[onlySearchProjectName]=true`;
            }

            const campaigns = await api().getCampaigns(
              amSurveyRecord,
              0,
              (page + 1) * 10,
              queryFilter
            );
            if (campaigns.campaigns) {
              const options = campaigns.campaigns.map(option => ({
                value: option.id,
                label: option.projectName
              }));
              return [defaultOption, ...options];
            }
            return null;
          }}
          customPopupStyle={{
            position: 'fixed',
            width: '400px',
            marginTop: '2px',
            left: `${leftPosition}`,
            top: `${topPosition}px`
          }}
          disabled={!amSurveyRecord}
          customPlaceholder="Select survey"
        />
      </div>
      {!amSurveyRecord ? (
        <div className={styles.warningText}>
          Please select a customer/initative in order to link this survey.
        </div>
      ) : null}
    </div>
  );
};
