import { connect } from 'react-redux';
import BusinessConfiguration from '../BusinessConfiguration';

import * as actions from '../../../campaigns/actions';
import {
  logout,
  setSelectedAccountManagementRecord
} from '../../../auth/actions';

const mapStateToProps = state => ({
  isAdmin: state.auth.isAdmin,
  isProfilingTagsManager: state.auth.isProfilingTagsManager,
  isBusinessConfigurationManager: state.auth.isBusinessConfigurationManager,
  isAuthenticated: state.auth.isAuthenticated,
  isProcessing: state.campaigns.isProcessing,
  isFetching: state.campaigns.isFetching,
  clientEmail: state.auth.clientEmail,
  accountManagement: state.auth.accountManagement
});

const mapDispatchToProps = dispatch => ({
  onGoToPortalPage: (onSuccess, history) =>
    dispatch(actions.goToPortalPage(onSuccess, history)),
  onLogout: () => dispatch(logout()),
  setSelectedAccountManagementRecord: record => {
    dispatch(setSelectedAccountManagementRecord(record));
    window.location.href = '/clients';
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessConfiguration);
